import React from "react";
import { Link } from "react-router-dom";

import successIcon from 'assets/DoctorPage/SuccessIcon.png';

const SuccessModalBody = ({action = 'doctorCall'}) => {

    const renderBody = () => {
        if (action === 'doctorCall') {
            return (
                <div className='successModal text-center'>
                    <h3 className='f-s-18 fw-bold mb16'>Ձեր Գրանցումը հաջողությամբ Կատարված է։</h3>
                    <p className='f-s-12'>
                    Մեր մասնգետները կկապվեն ձեզ հետ հայտի վերջնական հաստատման համար։
                    </p>
                    <p className='text-center'>
                    <img src={successIcon} alt='success' className='successIcon' />
                    </p>
                    <p className='text-center'>
                    <Link to='/' className='btn btn-blue mb-2'>Գլխավոր էջ</Link>
                    <Link to='/' className='btn btn-transparent text-decoration-underline f-s-14 text-blue'>Նոր հայտի
                        գրանցում</Link>
                    </p>
                </div>
            )
        }
    }

    return (
       <>{renderBody()}</>
    );
}

export default SuccessModalBody;