import React from 'react';
import {
  Route,
  Routes,
  BrowserRouter as Router
} from 'react-router-dom';

import Lab from 'pages/Lab';
import Home from 'pages/Home';
import Login from 'pages/Login';
import Doctor from 'pages/Doctor';
import OneLab from 'pages/OneLab';
import Header from 'sections/Header';
import Footer from 'sections/Footer';
import Diagnosis from 'pages/Diagnosis';
import LabsByCat from 'pages/LabsByCat';
import AddRequest from 'pages/AddRequest';
import OneMedCard from 'pages/OneMedCard';
import MyCalendar from 'pages/MyCalendar';
import Departments from 'pages/Departments';
import CardServices from 'pages/CardServices';
import MedicalCards from 'pages/MedicalCards';
import Applications from 'pages/Applications';
import OneWorkplace from 'pages/OneWorkplace';
import DoctorAccount from 'pages/DoctorAccount';
import OneDepartment from 'pages/OneDepartment';
import PatientAccount from 'pages/PatientAccount';
import DoctorPatients from 'pages/DoctorPatients';
import PatientAdvices from 'pages/PatientAdvices';
import DocSearch from 'components/Search/DocSearch';
import DoctorTemplates from 'pages/DoctorTemplates';
import AdvicesTemplate from 'pages/AdvicesTemplate';
import DoctorOneGallery from 'pages/DoctorOneGallery';
import PharmsPage from 'components/Pharms/PharmsPage';
import DoctorOnePatient from 'pages/DoctorOnePatient';
import PatientDiagnosis from 'pages/PatientDiagnosis';
import HistoriesTemplate from 'pages/HistoriesTemplate';
import AddAdviceTemplate from 'pages/AddAdviceTemplate';
import AddHistoryTemplate from 'pages/AddHistoryTemplate';
import OnePharmPage from 'components/Pharms/OnePharmPage';
import LabMaterialAndType from 'pages/LabMaterialAndType';
import PatientLabServices from 'pages/PatientLabServices';
import WorkplacesByCategory from 'pages/WorkplacesByCategory';
import AddServicesToMedCard from 'pages/AddServicesToMedCard';
import PatientIndicatorData from 'pages/PatientIndicatorData';
import PharmsByCategory from 'components/Pharms/PharmsByCategory';
import DrugCategoryPage from 'components/Pharms/DrugCategoryPage';
import DepartmentAndWorkplace from 'pages/DepartmentAndWorkplace';
import OneWorkplaceDepartment from 'pages/OneWorkplaceDepartment';
import ChooseLabMaterialAndTypes from 'pages/ChooseLabMaterialAndTypes';
import PatientMedicalHistories from 'pages/PatientMedicalHistories/component';
import PharmsWithDrugsByCategory from 'components/Pharms/PharmsWithDrugsByCategory';

const Layout = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/lab' element={<Lab />} />
        <Route path='/login' element={<Login />} />
        <Route path='/pharm' element={<PharmsPage />} />
        <Route path='/docSearch' element={<DocSearch />} />
        <Route path='/diagnosis' element={<Diagnosis />} />
        <Route path='/addRequest' element={<AddRequest />} />
        <Route path='/oneMedCard' element={<OneMedCard />} />
        <Route path='/myCalendar' element={<MyCalendar />} />
        <Route path='/departments' element={<Departments />} />
        <Route path='/cardServices' element={<CardServices />} />
        <Route path='/medicalCards' element={<MedicalCards />} />
        <Route path='/applications' element={<Applications />} />
        <Route path='/doctorAccount' element={<DoctorAccount />} />
        <Route path='/labs/:categorySlug' element={<LabsByCat />} />
        <Route path='/patientAccount' element={<PatientAccount />} />
        <Route path='/doctor/one/:doctorSlug' element={<Doctor />} />
        <Route path='/lab/one/:workplaceSlug' element={<OneLab />} />
        <Route path='/profile/patients' element={<DoctorPatients />} />
        <Route path='/advicesTemplate' element={<AdvicesTemplate />} />
        <Route path='/pharm/one/:pharmSlug' element={<OnePharmPage />} />
        <Route path='/lab/labMaterial' element={<LabMaterialAndType />} />
        <Route path='/historiesTemplate' element={<HistoriesTemplate />} />
        <Route path='/profile/onePatient' element={<DoctorOnePatient />} />
        <Route path='/profile/myTemplates' element={<DoctorTemplates />} />
        <Route path='/addAdviceTemplate' element={<AddAdviceTemplate />} />
        <Route path='/addHistoryTemplate' element={<AddHistoryTemplate />} />
        <Route path='/pharms/:categorySlug' element={<PharmsByCategory />} />
        <Route path='/pharm/category/:slug' element={<DrugCategoryPage />} />
        <Route path='/department/:departmentSlug' element={<OneDepartment />} />
        <Route path='/addServicesToMedCard' element={<AddServicesToMedCard />} />
        <Route path='/workplace/one/:workplaceSlug' element={<OneWorkplace />} />
        <Route path='/profile/onePatient/advices' element={<PatientAdvices />} />
        <Route path='/profile/onePatient/labServices' element={<PatientLabServices />} />
        <Route path='/choose/labMaterial' element={<ChooseLabMaterialAndTypes />} />
        <Route path='/profile/onePatient/diagnosis' element={<PatientDiagnosis />} />
        <Route path='/workplaces/:categorySlug' element={<WorkplacesByCategory />} />
        <Route path='/oneWorkplaceDepartment' element={<OneWorkplaceDepartment />} />
        <Route path='/profile/onePatient/indicators' element={<PatientIndicatorData />} />
        <Route path='/pharmsWithDrugs/category/:slug' element={<PharmsWithDrugsByCategory />} />
        <Route path='/profile/onePatient/medicalHistories' element={<PatientMedicalHistories />} />
        <Route path='/doctor/oneGallery/:doctorSlug/:gallerySlug' element={<DoctorOneGallery />} />
        <Route path='/department/:departmentSlug/:workplaceSlug' element={<DepartmentAndWorkplace />} />
      </Routes>
      <Footer />
    </Router>
  )
}

export default Layout;
