import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { clearCookies } from 'utils';

import { resetUserDetails } from 'features/userDetails';
import { resetApplications } from 'features/applications';
import { resetSideSettings } from 'features/sideSettings';

import Languages from 'components/languages';

import './component.css';

const BaseDomain = process.env.REACT_APP_BASE_DOMAIN;

const SideSettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { item } = useSelector(state => state.userDetails);
  const { open } = useSelector(state => state.sideSettings);

  const handleCloseSideSettings = () => {
    dispatch(resetSideSettings());
  };

  const handleStopAction = (e) => {
    e.stopPropagation();
  };

  const handleLogout = () => {
    // TODO: implement logout
    dispatch(resetUserDetails());
    dispatch(resetApplications());
    clearCookies();
    navigate('/login');
  };

  useEffect(() => {
    return () => {
      handleCloseSideSettings();
    }
  }, []);

  return (
    <div
      className='side-settings-block'
      style={{
        visibility: open ? 'visible' : 'hidden'
      }}
    >
      <div
        className='side-settings-rel-block'
        onClick={handleCloseSideSettings}
      >
        <div
          className='side-settings-abs-block'
          onClick={handleStopAction}
          style={{
            right: open ? '0px' : '-231px'
          }}
        >
          <div className='side-settings-info-block'>
            <div className='side-settings-info-item'>
              <div className='side-settings-info-item-content'>
                <div className='side-settings-info-item-content-image-block'>
                  {
                    item && item.image ?
                      <img
                        alt={item.slug}
                        src={BaseDomain + item.image}
                        className='side-settings-info-item-content-image'
                      />
                    : ''
                  }
                </div>

                <div className='side-settings-info-item-content-text-block'>
                  <p className='caption1-200'>1234567891</p>
                </div>
              </div>
            </div>

            <div className='side-settings-info-item'>
              <div className='side-settings-info-item-content'>
                <div className='side-settings-info-item-content-image-block'>
                  {
                    item && item.image ?
                      <img
                        alt={item.slug}
                        src={BaseDomain + item.image}
                        className='side-settings-info-item-content-image'
                      />
                    : ''
                  }
                </div>

                <div className='side-settings-info-item-content-text-block'>
                  <p className='caption1-200'>1256580000</p>
                </div>
              </div>
            </div>

            <div className='side-settings-info-item'>
              <div className='side-settings-info-item-content'>
                <div className='side-settings-info-item-content-image-block'>
                  {
                    item && item.image ?
                      <img
                        alt={item.slug}
                        src={BaseDomain + item.image}
                        className='side-settings-info-item-content-image'
                      />
                    : ''
                  }
                </div>

                <div className='side-settings-info-item-content-text-block'>
                  <p className='caption1-200'>7000258563</p>
                </div>
              </div>
            </div>

            <div className='side-settings-info-item'>
              <div className='side-settings-info-item-content'>
                <div className='side-settings-info-item-content-image-block'>
                  {
                    item && item.image ?
                      <img
                        alt={item.slug}
                        src={BaseDomain + item.image}
                        className='side-settings-info-item-content-image'
                      />
                    : ''
                  }
                </div>

                <div className='side-settings-info-item-content-text-block'>
                  <p className='caption1-200'>1152020222</p>
                </div>
              </div>
            </div>

            <div className='side-settings-logout-block'>
              <p
                className='side-settings-logout-text'
                onClick={handleLogout}
              >
                Մուտք Գործել այլ հաշիվ
              </p>
            </div>
          </div>

          <div className='side-settings-policy-languages-block'>
            <ul className='side-settings-policy-menu'>
              <li className='side-settings-policy-menu-item'>
                <Link to='' className='caption2-300 side-settings-policy-menu-item-link'>
                  Գաղտնիության քաղաքականություն
                </Link>
              </li>
              <li className='side-settings-policy-menu-item'>
                <Link to='' className='caption2-300 side-settings-policy-menu-item-link'>
                  Պայմաններ եւ դրույթներ
                </Link>
              </li>
            </ul>

            {/* <div className='side-settings-languages-block'>
              <Languages active='am' withoutArrow />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
};

export default SideSettings
