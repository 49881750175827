import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  item: {}
};

const oneMedCardSlice = createSlice({
  name: 'oneMedCard',
  initialState,
  reducers: {
    setOneMedCard(state, action) {
      state.item = action.payload;
    },
    resetOneMedCard(state) {
      state.item = {}
    }
  }
});

export const { setOneMedCard, resetOneMedCard } = oneMedCardSlice.actions;
export default oneMedCardSlice.reducer;
