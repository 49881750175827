import {Modal} from 'react-bootstrap';
import React, {useState} from 'react';

function MyModal({show, onHide, header = null, body = null, title = null, footer = null, centered = false}) {
    const [showModal, setShowModal] = useState(false);

    const handleClose = () => {
        setShowModal(false);
        onHide();
    }

    const handleShow = () => setShowModal(true);

    return (
        <Modal show={showModal === true ? showModal : show} onHide={handleClose} centered={centered}>
            {header &&
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
            }
            {body &&
                <Modal.Body>
                    {body}
                </Modal.Body>
            }

            {footer &&
                <Modal.Footer>
                </Modal.Footer>
            }
        </Modal>
    );
}

export default MyModal;