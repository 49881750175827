import DoctorsInfo from "components/DoctorsInfo";
import React from "react";
import { useSelector } from "react-redux";


const OneWorkplaceDepartment = () => {
    const { item } = useSelector(state => state.department);
    
    return (
        <div className="row mt-100">
             <h2 className="caption1-400 mt-3 mb-3">{item.titleArm}</h2>
             {item.doctors && item.doctors.length && item.doctors.map((doctor, index) => 
                <div className="col-6">
                    <DoctorsInfo
                         key={`${doctor.slug}_${index}`}
                         doctor={doctor}
                         useAsLink={true}
                    />
                </div>
            )}
        </div>
       
    );
}

export default OneWorkplaceDepartment;