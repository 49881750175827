import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getDoctorTemplates, removeLabServiceFromSchema } from 'services/api';
import { setLabSchemaSlug } from 'features/labSchemaName';

import LabOneService from 'components/LabOneService';

import pen from 'assets/Textarea/text-area-pen.svg';
import remove from 'assets/SearchBar/removeRed.svg';
import plusIcon from '../../assets/Lab/plusIcon.png';

import './component.css';

const LabSchemas = ({ schemas, handleDelete }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [activeSchema, setActiveSchema] = useState(null);
    const [ editMode, setEditMode ] = useState(false);

    // Handle schema item click
    const handleClick = (schema) => {
    
        setActiveSchema(schema);
    };

    const removeLabServiceClick = (service) => {
      let params = {
        'labServiceId' : service.id,
        'labSchemaSlug' : activeSchema ? activeSchema.slug : ''
      };

      removeLabServiceFromSchema(params)
      .then(response => {
        getDoctorTemplates(dispatch);
      })
    }

    const handlePlusClick = () => {
        dispatch(setLabSchemaSlug(activeSchema ? activeSchema.slug : null));
        navigate('/choose/labMaterial');
    }

    useEffect(() => {
        setEditMode(false);
    }, [activeSchema]); 
    

    return (
        <>
            {schemas.map(schema => (
                <div
                    key={schema.id}
                    className={`labSchema mt16 position-relative ${activeSchema && schema.id === activeSchema.id && 'green-border'}`}
                    onClick={() => handleClick(schema)}
                >
                    <p className={`body2-400-title float-left`}>{schema.titleArm}</p>
                    {activeSchema && schema.id === activeSchema.id && (
                        <>
                        {
                            editMode ? 
                            <img
                                src={plusIcon}
                                alt="Plus"
                                className="schemaEditPlus text-area-icon"
                                onClick={handlePlusClick}
                            />
                            :
                            <>
                                <img
                                    src={pen}
                                    alt="Edit"
                                    className="schemaEditPen text-area-icon"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        // Add edit functionality here
                                        setEditMode(true);
                                    }}
                                />
                                <img
                                    src={remove}
                                    alt="Remove"
                                    className="schemaRemove text-area-icon"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    handleDelete(schema);
                                    }}
                                />
                            </>
                        }
                           
                            <br/>
                            {editMode && schema.labServiceTemplates.length > 0 && schema.labServiceTemplates.map((service, index) => {
                            return <LabOneService
                                 service={service.labService}
                                 key={service.labService.id + '-' + index}
                                 onClick={removeLabServiceClick}
                                 onlyDelete
                                 withoutAction
                             />
                            })
                                
                            }
                        </>
                    )}
                </div>
            ))}
        </>
    );
};

// Define PropTypes for schemas
LabSchemas.propTypes = {
    schemas: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            titleArm: PropTypes.string.isRequired
        })
    ).isRequired
};

export default LabSchemas;
