import React from 'react';
import PropTypes from 'prop-types';
import DoctorService from 'components/DoctorService/component';

const DoctorServicesList = ({services}) => {

    const renderServices = () => {
        if (services && services.length > 0) {
          return services.map(service =>
            <DoctorService
              service={service}
              key={service.id}
            />
          );
        }
      };


    return (
        <div className='medical-card-info-services-and-price-block'>
           {renderServices()}
        </div>
    );
}

DoctorServicesList.propTypes = {
    callDetails: PropTypes.object,
  };

export default DoctorServicesList
