import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { addAdviceTemplate, editAdviceTemplate } from 'services/api';

import Button from 'components/Button';
import Textarea from 'components/Textarea';

import './component.css';

const AddAdviceTemplate = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const { items } = useSelector(state => state.doctorAdviceTemplates);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [ text, setText ] = useState('');

  const handleAddAdviceTemplate = () => {
    const params = {
      description: text
    };
    addAdviceTemplate(dispatch, params)
      .then(() => {
        navigate('/profile/MyTemplates');
      })
      .catch(e => {
        console.log(e);
      });
  };

  const handleEditAdviceTemplate = () => {
    const params = {
      adviceTemplateId: +id,
      description: text
    };
    editAdviceTemplate(dispatch, params)
      .then(() => {
        navigate('/profile/MyTemplates');
      })
      .catch(e => {
        console.log(e);
      });
  }

  const handleAddClick = () => {
    if (id) {
      handleEditAdviceTemplate();
    } else {
      handleAddAdviceTemplate();
    }
  };

  const handleSetTextArea = (value) => {
    setText(_ => value);
  };

  useEffect(() => {
    if (id && items && items.length) {
      items.map(item => {
        if (item.id.toString() === id) {
          setText(() => item.description)
        }
      });
    }
  }, [id, items]);

  return (
    <div className='mt-100 add-history-template-block'>
      <div className='histories-template-cards-parent-block'>
        <Textarea
          value={text}
          change={handleSetTextArea}
          placeholder='Մուտքագրեք տեքստը'
        />
      </div>


      <div className='advice-btn-block'>
        <Button
          click={handleAddClick}
          text='Ավելացնել'
          disabled={!text.length}
          bgColor
          className="histories-template-add-btn-block"
        />
      </div>
    </div>
  )
};

export default AddAdviceTemplate
