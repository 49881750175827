import React from 'react';
// import Header from 'sections/Header';
import Banner from 'components/Banner';
import CatsWithPharms from './CatsWithPharms';
import DrugCategories from './DrugCategories';

function PharmsPage(props) {
    return (
        <div>
            {/* <Header /> */}
            <Banner />
            {/* <HomeVisitBanner/> */}
            <DrugCategories link={true} title={`Ընտրել խմբավորումը`}/>
            <CatsWithPharms/>
        </div>
    );
}

export default PharmsPage;
