import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: []
};

const bannerSlice = createSlice({
  name: 'banner',
  initialState,
  reducers: {
    setBanners(state, action) {
      state.items = action.payload
    },
    resetBanners(state) {
      state.items = []
    }
  }
});

export const { setBanners, resetBanners } = bannerSlice.actions;
export default bannerSlice.reducer;
