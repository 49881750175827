import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

const DoctorInfo = ({ doctor }) => {
  const [ heightBtn, setHeightBtn ] = useState('Ավելին');
  const [ heightClass, setHeightClass ] = useState('mh180');

  const handleClick = () => {
    if (heightClass === 'mh180') {
      setHeightClass('');
      setHeightBtn('Փակել');
    } else {
      setHeightClass('mh180');
      setHeightBtn('Ավելին');
    }
  }

  return (
    <Row>
      <Col>
        <div className={`doctorInfoDiv mb10 ${heightClass}`} dangerouslySetInnerHTML={{ __html: doctor.textArm }} />
        <Link className={`more mt-3 text-decoration-underline`} onClick={() => handleClick()}>{heightBtn}</Link>
      </Col>
    </Row>
  );
}

export default DoctorInfo;
