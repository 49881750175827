import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './component.css';

const HistoriesTemplateCard = ({ item, activeCard, getTemplate }) => {
  const [ active, setActive ] = useState(false);

  const handlSetActive = () => {
    if (item.description === active) {
      getTemplate('');
    } else {
      getTemplate(item.description);
    }
  };

  useEffect(() => {
    setActive(_ => activeCard);
  }, [activeCard]);

  return (
    <div
      onClick={handlSetActive}
      className='histories-template-card-block'
      style={{
        borderColor: item.description === active ? '#50D100' : 'transparent'
      }}
    >
      <p className='caption2-200 histories-template-card-text'>
        { item.description }
      </p>
    </div>
  )
};

HistoriesTemplateCard.propTypes = {
  item: PropTypes.object.isRequired,
  getTemplate: PropTypes.func.isRequired
};

export default HistoriesTemplateCard
