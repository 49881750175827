import React from 'react';
import PropTypes from 'prop-types';

const BaseDomain = `${process.env.REACT_APP_BASE_DOMAIN}`;

const Item = ({ banner }) => {

    return (
        <div>
            <img src={BaseDomain + banner.imagePath} className="img-fluid" alt="banner" />
        </div>
    );
}

Item.propTypes = {
    banner: PropTypes.object
}

export default Item;
