import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { resetLabCart } from 'features/labCart';
import { resetLabCartIds, resetLabCartOneService } from 'features/labCartServices';

import LabOneService from 'components/LabOneService';

const LabCartServices = ({
    workplace, 
    showDoctors, 
    onSubmit, 
    withActions = true
}) => {
    const dispatch = useDispatch();
    const { open } = useSelector(state => state.labCart);
    const { services, ids } = useSelector(state => state.labCartServices);


    const handleCloseButton = () => {
        dispatch(resetLabCart());
    }
    
    const onClickService = (service) => {
        let finalServices = services.filter(item => item.id !== service.id);
        let finalIds = finalServices.map(item => item.id)

        dispatch(resetLabCartOneService(finalServices));
        dispatch(resetLabCartIds(finalIds));
      };


    const renderSelectedServices = () => {
        return services.length > 0 && services.map(service =>
            <LabOneService
                service={service}
                key={service.id}
                onClick={onClickService}
                lab={workplace}
                inCart={true}
                selectedServices={ids}
                showDoctors={showDoctors}
            />
        );
    }

    const renderServicesTotal = () => {
        let totalMin = 0;
        let totalMax = 0;

        if (services && services.length > 0) {
            services.forEach(service => {
                totalMin += service.maxAndMin.min;
                totalMax += service.maxAndMin.max;
            })
        }

        return workplace ? totalMin : totalMin + '-' + totalMax;
    }

    return (
        <div className="row w100 side-settings-block"
            style={{
                visibility: open ? 'visible' : 'hidden'
          }}
        >
            <div className='labCartDiv pb-24'>
                {services && services.length > 0 ?
                    <>
                        <div className="selectedServicesDiv">
                            {/* <p className="f-s-12 fw-bold">Հետազոտություններ</p> */}
                            {renderSelectedServices()}
                        </div>
                        <div className="selectedServicesDiv">
                            <p className='f-s-14 fw-bold'>
                                <span>Ընդամենը</span>
                                <span
                                    className="float-right labPrice d-block text-green">{renderServicesTotal()} դր․</span>
                            </p>
                        </div>
                        {withActions &&
                            <div className="col-12">
                                <div className="row h40 mt24">
                                    <div className='text-center col'>
                                        <Link onClick={handleCloseButton}
                                            className="f-s-14 text-decoration-underline text-blue lh40">Չեղարկել</Link>
                                    </div>
                                    <div className='text-center col'>
                                        <button className="btn btn-blue" onClick={onSubmit}>Հաստատել</button>
                                    </div>
                                </div>
                            </div>
                        }
                    </> :
                    <div className="selectedServicesDiv text-center">
                        <p className='f-s-14 fw-bold'>
                            Հետազոտությունների ցանկը դատարկ է։
                        </p>
                        <button
                            className="btn btn-blue w50 mt-3"
                            onClick={handleCloseButton}
                        >
                            Ավելացնել
                        </button>
                    </div>
                }
            </div>
        </div>
    );
}

LabCartServices.propTypes = {
    workplace: PropTypes.object,
    showDoctors: PropTypes.bool,
    onSubmit: PropTypes.func,
    withActions: PropTypes.bool
  };

export default LabCartServices;
