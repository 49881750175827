import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setHeaderTitle } from "features/headerTitle";

import AnamnezBlock from "components/AnamnezData";

const PatientMedicalHistories = () => {
    const dispatch = useDispatch();
    const medicalHistories = useSelector(state => state.patientMedicalHistories.items);
    const [ innerHistory, setInnerHistory ] = useState([]);
    const [ filterHistoriesTabs, setFilterHistoriesTabs ] = useState([]);
    const [ activeHistoriesFilter, setActiveHistoriesFilter ] = useState(
      medicalHistories && medicalHistories[0] ? medicalHistories[0].title : ''
    );

    const handleSetHistoriesActive = (active) => {
      setActiveHistoriesFilter(_ => active);
    };

    useEffect(() => {
      if (medicalHistories && medicalHistories.length) {
        const tabs = medicalHistories.map(item => item.title);
        setFilterHistoriesTabs(_ => {
          return [
            ...tabs
          ]
        });

        const newHistories =  medicalHistories.filter(item => item.title === activeHistoriesFilter);
        if (newHistories && newHistories[0] && newHistories[0].histories && newHistories[0].histories.length) {
          setInnerHistory(_ => {
            return newHistories[0].histories
          });
        } else {
          setInnerHistory(_ => {
            return []
          });
        }
      }
  
    }, [medicalHistories, activeHistoriesFilter]);
     

    useEffect(() => {
        dispatch(setHeaderTitle('Անամնեզներ'));
    }, []);

    return (
        <div className="mt-100">
            <AnamnezBlock
              histories={innerHistory}
              activeFilter={activeHistoriesFilter}
              filterHistories={filterHistoriesTabs}
              setHistoriesActive={handleSetHistoriesActive}
              withDoctorDetails
              withAddButton={false}
              withWorkplaceDetails
            />
        </div>
    );
}

export default PatientMedicalHistories;