import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: []
};

const historiesTemplateSlice = createSlice({
  name: 'historiesTemplate',
  initialState,
  reducers: {
    setHistoriesTemplate(state, action) {
      state.items = action.payload
    },
    resetHistoriesTemplate(state) {
      state.items = []
    }
  }
});

export const { setHistoriesTemplate, resetHistoriesTemplate } = historiesTemplateSlice.actions;
export default historiesTemplateSlice.reducer;
