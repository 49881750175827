import React from 'react';
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { resetHeaderTitle } from 'features/headerTitle';
import { resetLabCart, setLabCart } from 'features/labCart';

import arrow from 'assets/SearchBar/Vector.png';
import search from 'assets/SearchBar/Ellipse.png';
import cartIcon from 'assets/Lab/cartIcon.png';

import './component.css';

const BaseDomain = `${process.env.REACT_APP_BASE_DOMAIN}`;

const HeaderWithoutLogo = ({
  withSearch = false,
  imagePath = null,
  withCart = false
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { title } = useSelector(state => state.headerTitle)
    const { ids } = useSelector(state => state.labCartServices);
    const { open } = useSelector(state => state.labCart);

    const handleBackClick = () => {
        dispatch(resetHeaderTitle(''));
        navigate(-1);
    }

    const handleOpenCart = () => {
        open ? dispatch(resetLabCart()) : dispatch(setLabCart())
      }

    return (
        <header className='headerWithoutLogo col-12'>
          
                <div className='row box-shadow'>
                    <div className='col-2'>
                        <div className='img11'>
                            <img onClick={handleBackClick} src={arrow} className='slack' alt='back'/>
                        </div>
                    </div>
                    <div className='col-8'>
                        <h2 className='headerTitle'>
                            {imagePath !== null ? <img src={BaseDomain + imagePath} alt='clinic'/> : title}
                        </h2>
                    </div>

                    <div className='col-2 text-right'>
                        {withSearch &&
                            <div className='img2'>
                                <img src={search} className='search1' alt='search'/>
                            </div>
                        }
                        {withCart &&
                            <div className='labcart' onClick={handleOpenCart}>
                                <img src={cartIcon} alt='cartIcon'/>
                                <span className='count f-s-12'>{ids ? ids.length : 0}</span>
                            </div>
                        }
                    </div>
                </div>
        
        </header>
    );
}

HeaderWithoutLogo.propTypes = {
    title: PropTypes.string,
    imagePath: PropTypes.string,
    withoutSearch: PropTypes.bool,
    withCart: PropTypes.bool
}

export default HeaderWithoutLogo;
