import React, { useEffect } from 'react';

import NewDepartments from 'components/NewDepartments';
import Banner from 'components/Banner';
import { useDispatch } from 'react-redux';
import { setHeaderTitle } from 'features/headerTitle';


const Departments = () => {
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(setHeaderTitle('Բաժանմունքներ'));
      },[])

    return (
        <>
            <Banner containerClasses='mt-100'/>
            <NewDepartments 
                moreWithNewPage
                link='/department'
                useAsLink
                linkHasSlug
                showAll
            />
        </>
    );
}

export default Departments;