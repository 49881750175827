import React from 'react';
import TitleWithBorder from './titleWithBorder';

function SortRow({title}) {
    return (
        <div className='sortRow col-12'>
           <div>
                <TitleWithBorder title={title} classes='mb8'/>
           </div>
        </div>
    );
}

export default SortRow;