import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  getOneMedCard,
  approveOrDecline,
  createMedicalCard,
  getDoctorProfileCalls
} from 'services/api';

import { handleGetDate } from './../../utils/index';

import { setHeaderTitle } from 'features/headerTitle';
import { setModal, resetModal } from 'features/modal';

import Modal from 'components/Modal';
import Input from 'components/Input';
import FilterTabs from 'components/FilterTabs';
import FilterCard from 'components/FilterCard';
import MyModal from 'components/Global/MyModal';
import SideSettings from 'components/SideSettings';
import CalendarForm from 'components/CalendarForm';
import FilterSearch from 'components/FilterSearch';
import CreateService from 'components/CreateService';
import ApplicationsCard from 'components/ApplicationsCard';

import pdf from 'assets/DoctorPage/pdf.svg';
import lab from 'assets/DoctorPage/lab.svg';
import file from 'assets/DoctorPage/file.svg';
import flask from 'assets/DoctorPage/flask.svg';
import heart from 'assets/DoctorPage/heart.svg';
import request from 'assets/DoctorPage/request.svg';
import calendar from 'assets/DoctorPage/calendar.svg';
import pharmacy from 'assets/DoctorPage/pharmacy.svg';
import corona from 'assets/DoctorPage/corona-virus.svg';
import phonendoscope from 'assets/DoctorPage/phonendoscope.svg';

import './component.css';

const Applications = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { open } = useSelector(state => state.modal);
  const { item } = useSelector(state =>state.userDetails);
  const { items } = useSelector(state => state.applications);
  const appType = useSelector(state => state.applicationType.type);
  const userDetails = useSelector(state => state.userDetails.item);

  const [ type, setType ] = useState('');
  const [ cardData, setCardData ] = useState({});
  const [ filterTabs, setFilterTabs ] = useState([]);
  const [ innerItems, setInnerItems ] = useState([]);
  const [ searchValue, setSearchValue ] = useState('');
  const [ topLevelData, setTopLevelData ] = useState([]);
  const [ activeFilter, setActiveFilter ] = useState('');
  const [ modalContent, setModalContent ] = useState(<></>);
  const [ modalInputText, setModalInputText ] = useState('');
  const [ showTimeModal, setShowTimeModal ] = useState(false);
  const [ activeTopFilter, setActiveTopFilter ] = useState('');
  const [ modalCancelText, setModalCancelText ] = useState('');
  const [ modalSuccessText, setModalSuccessText ] = useState('');
  const [ createServiceName, setCreateServiceName ] = useState('');
  const [ createServiceIcon, setCreateServiceIcon ] = useState(<></>);
  const [ modalInputErrorText, setModalInputErrorText ] = useState('');

  const handleSetActive = (active) => {
    setActiveFilter(_ => active);
  };

  const handleCancel = (type, data) => {
    if (type === 'cancel') {
      const { code } = data;
      const params = {
        code,
        action: 'deny',
        denyText: modalInputText
      }
      approveOrDecline(params)
        .then(() => {
          getDoctorProfileCalls(dispatch)
        })
        .catch(e => {
          if (e.Error.response.status === 400) { 
            const { errors } = e.Error.response.data.data;
            setModalInputErrorText(_ => errors.denyReason);
          }
        });
    }
  };

  const handleApprove = (type, data) => {
    if (type === 'approve') {
      const { code, denyText } = data;
      const params = {
        code,
        action: 'approve',
        denyText
      }
      approveOrDecline(params)
      .then(() => {
        getDoctorProfileCalls(dispatch);
        dispatch(resetModal());
      })
      .catch(e => {
        console.log(e);
      });
    }
  };

  const handleCreateMedicalCard = (type, data) => {
    const params = {
      patientSlug: data.patient.slug,
      callCode: data.code
    }
    createMedicalCard(params)
    .then((res) => {
      getOneMedCard(dispatch, res.code)
      .then(() => {
        navigate('/oneMedCard');
      })
      .catch(e => {
        console.log(e);
      });
    })
    .catch(e => {
      console.log(e);
    });
  };

  const handleOpenCancelModal = (cancelItem, categoryName) => {
    setType(_ => 'cancel');
    setCardData(_ => cancelItem);
    setModalInputErrorText(_ => '');
    setModalContent(_ => modalCancelUI);
    setModalSuccessText(_ => 'Հաստատել');
    setModalCancelText(_ => 'Վերադառնալ');
    dispatch(setModal());
  };

  const handleOpenSuccessModal = (successItem, categoryName) => {
    setCardData(successItem);
    setModalCancelText(_ => 'Չեղարկել');
    setModalSuccessText(_ => 'Հաստատել');
    if (categoryName === 'Սպասման փուլում') {
      setType(_ => 'approve');
      setModalInputErrorText(_ => '');
      setModalContent(_ => modalApproveUI);
      dispatch(setModal());
    } else if (categoryName === 'Հաստատված') {
      setType(_ => 'create card');
      setModalInputErrorText(_ => '');
      setModalContent(_ => modalCreateCardUI);
      dispatch(setModal());
    } else {
      setType(_ => 'create card');
      setModalInputErrorText(_ => '');
      setModalContent(_ => modalCreateCardUI);
      dispatch(setModal());
    }
  };

  const handleChange = (e) => {
    const val = e.target.value; 
    setModalInputText(_ => val);
    setModalInputErrorText(_ => '');
  };

  const handleSetActiveTop = (item) => {
    let newItems = [];
    setActiveTopFilter(() => item.titleArm);

    item.callStatuses.map(innerItem => {
      newItems.push(innerItem);
    });
    const tabs = newItems && newItems.length > 0 && newItems.map(item => item.statusTitle);
    setFilterTabs(_ => tabs);
    setActiveFilter(newItems && newItems[0] && newItems[0].statusTitle);
    setInnerItems(() => newItems);
  };

  const handleAddRequest = () => {
    dispatch(setHeaderTitle('Ավելացնել Հայտ'));
    navigate('/addRequest');
  };

  const handleCallCalendar = () => {
    setShowTimeModal(true);
  };

  const handleSetSearchValue = (val, type) => {
    const value = type === 'calendar' ? handleGetDate(val, true) : val;
    setSearchValue(_ => value);
  };

  const timeModalBody = <div className='myCalendar'>
    <CalendarForm
      onlyDate
      filterDate
      date={new Date()}
      submitBtnTitle={`Դասակարգել`}
      onCancel={() => setShowTimeModal(false)}
      onSubmit={() => setShowTimeModal(false)}
      setDate={(val) => handleSetSearchValue(val, 'calendar')}
    />
  </div>;

  const modalApproveUI = <div className='applications-modal-block'>
    <h5 className='body1-300-title applications-modal-title'>
      Հաստատել հայտը
    </h5>

    <p className='caption2-200 applications-modal-text'>
      Մեր մասնգետները կկապվեն ձեզ հետ հայտի վերջնական հաստատման համար։
    </p>
  </div>;

  const modalCancelUI = <div className='applications-modal-block'>
    <h5 className='body1-300-title applications-modal-title'>
      Չեղարկել հայտը
    </h5>

    <Input
      change={handleChange}
      value={modalInputText}
      errorText={modalInputErrorText}
      label='Նշեք չեղարկման պատճառը'
      placeholder='Նշեք չեղարկման պատճառը'
      error={modalInputErrorText ? true : false}
    />
  </div>;

  const modalCreateCardUI = <div className='applications-modal-block'>
    <h5 className='body1-300-title applications-modal-title'>
      Ստեղծել Քարտ
    </h5>

    <p className='caption2-200 applications-modal-text'>
      Մեր մասնգետները կկապվեն ձեզ հետ։
    </p>
  </div>;

  useEffect(() => {
    if (!open) {
      setModalInputText('');
      setModalContent(<></>);
      setModalInputErrorText('');
    } else {
      if (type === 'cancel') {
        setModalContent(_ => modalCancelUI);
      }
    }
  }, [open, type, modalInputErrorText]);

  useEffect(() => {
    if (userDetails.roles.includes('ROLE_PATIENT')) {
      let newItems = [];
      if (items && items.length) {
        setTopLevelData(() => items);
        setActiveTopFilter(() => items[0].titleArm)
        items.map(item => {
          item.callStatuses.map(innerItem => {
            newItems.push(innerItem);
          })
        });
        const tabs = newItems && newItems.length > 0 && newItems.map(item => item.statusTitle);
        setFilterTabs(_ => tabs);
        setActiveFilter(newItems && newItems[0] && newItems[0].statusTitle);
      }
      setInnerItems(() => newItems);
    } else {
      if (items && items.length) {
        const tabs = items.map(item => item.statusTitle);
        setFilterTabs(_ => tabs);
      } else {
        navigate('/doctorAccount')
      }
      setActiveFilter(items && items[0] && items[0].statusTitle);
      setInnerItems(() => items);
    }
  }, [items]);

  useEffect(() => {
    if (appType === 'request') {
      setCreateServiceIcon(request);
      setCreateServiceName('Հայտեր');
    } else if (appType === 'calendar') {
      setCreateServiceIcon(calendar);
      setCreateServiceName('Օրացույց');
    } else if (appType === 'file') {
      setCreateServiceIcon(file);
      setCreateServiceName('Քարտեր');
    } else if (appType === 'corona') {
      setCreateServiceIcon(corona);
      setCreateServiceName('Հիվանդներ');
    } else if (appType === 'pdf') {
      setCreateServiceIcon(pdf);
      setCreateServiceName('pdf');
    } else if (appType === 'flask') {
      setCreateServiceIcon(flask);
      setCreateServiceName('flask');
    } else if (appType === 'heart') {
      setCreateServiceIcon(heart);
      setCreateServiceName('heart');
    } else if (appType === 'lab') {
      setCreateServiceIcon(lab);
      setCreateServiceName('lab');
    } else if (appType === 'phonendoscope') {
      setCreateServiceIcon(phonendoscope);
      setCreateServiceName('phonendoscope');
    } else if (appType === 'pharmacy') {
      setCreateServiceIcon(pharmacy);
      setCreateServiceName('pharmacy');
    }
  }, [appType]);

  useEffect(() => {
    return () => {
      dispatch(resetModal());
    }
  }, []);
  
  return (
    <div className='applications-block mt-100'>
      <Modal
        data={cardData}
        actionType={type}
        content={modalContent}
        cancelText={modalCancelText}
        successText={modalSuccessText}
        checkInputValue={modalInputText}
        callCancel={type === 'cancel' ? _ => _ : handleCancel}
        callSuccess={
          type === 'approve' ?
          handleApprove : type === 'cancel' ? handleCancel :
          handleCreateMedicalCard
        }
      />

      <MyModal
        centered
        show={showTimeModal}
        body={timeModalBody}
        onHide={() => setShowTimeModal(false)}
      />
      <SideSettings />

      <CreateService
        name={createServiceName}
        icon={createServiceIcon}
        action={handleAddRequest}
      />

      {
        userDetails.roles.includes('ROLE_PATIENT') && topLevelData && topLevelData.length > 0 && <div className='one-med-card-item'>
          <div className='diagnos-card-block'>
            {
              topLevelData.map((item, index) => {
                return <FilterCard
                  item={item}
                  itemHasImage
                  active={activeTopFilter === item.titleArm}
                  key={`${item.text}_${index}`}
                  checkActive={handleSetActiveTop}
                />
              })
            }
          </div>
        </div>
      }

      {
        filterTabs && filterTabs.length > 0 && <FilterTabs
          tabs={filterTabs}
          checkActive={handleSetActive}
          active={activeFilter}
        />
      }

      <div className='applications-search-block'>
        <FilterSearch
          value={searchValue}
          placeholder={'Որոնել'}
          filterCall={(val) => handleSetSearchValue(val, '')}
        />

        <div style={{ width: '35px', textAlign: 'right' }} onClick={handleCallCalendar}>
          <img src={calendar} alt='calendar' />
        </div>
      </div>

      {
        innerItems && innerItems.length > 0 &&
        innerItems.filter(status => status.statusTitle === activeFilter)
        .map((status, index) => {
          return <div key={`${status.statusTitle}_${index}`}>
            {
              Object.entries(status.calls).length > 0 && Object.entries(status.calls).filter((item, index) => {
                let newItem
                if (searchValue === '') {
                  newItem = item
                } else {
                  if (item[0] === searchValue || item[1][0].workplaceTitle.includes(searchValue)) {
                    newItem = item
                  }
                }

                return newItem;
              })
              .map((innerItem, innerIndex) => {
                return <div
                  key={`${innerItem[0]}_${innerIndex}`}
                  className='applications-card-parent-block'
                >
                  <div className='applications-card-date-block'>
                    <p className='applications-card-date-text body2-300-title'>{innerItem[0]}</p>
                  </div>

                  <div className='applications-card-horizontal-scroll-block'>
                    {
                      innerItem[1].map((lastItem, lastIndex) => {
                        return <ApplicationsCard
                          item={lastItem}
                          cancelText='Չեղարկել'
                          categoryName={status.statusTitle}
                          callCancel={handleOpenCancelModal}
                          key={`${lastItem.id}_${lastIndex}`}
                          callSuccess={handleOpenSuccessModal}
                          withoutButtons={
                            status.statusTitle === 'Սպասման փուլում' ||
                            status.statusTitle === 'Հաստատված' ? false : true
                          }
                          successText={status.statusTitle === 'Սպասման փուլում' ? 'Հաստատել' : 'Ստեղծել Քարտ'}
                          patientView={item.roles.includes('ROLE_PATIENT')}
                        />
                      })
                    }
                  </div>
                </div>
              })
            }
          </div>
        })
      }
    </div>
  )
}

export default Applications
