import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: []
};

const doctorPatientsSlice = createSlice({
  name: 'doctorPatients',
  initialState,
  reducers: {
    setDoctorPatients(state, action) {
      state.items = action.payload
    },
    resetDoctorPatients(state) {
      state.items = []
    }
  }
});

export const { setDoctorPatients, resetDoctorPatients } = doctorPatientsSlice.actions;
export default doctorPatientsSlice.reducer;
