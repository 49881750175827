import React from 'react';

import viber from 'assets/Doctors_Social_Block/viber.png';
import twitter from 'assets/Doctors_Social_Block/twitter.png';
import facebook from 'assets/Doctors_Social_Block/facebook.png';
import telegram from 'assets/Doctors_Social_Block/telegram.png';
import whatsapp from 'assets/Doctors_Social_Block/whatsapp.png';
import instagram from 'assets/Doctors_Social_Block/instagram.png';

import './component.css';

const DoctorsSocialBlock = ({ doctor }) => {
  return (
    <div className='container mt-2'>
      <div className='row'>
        <div className='col-sm-12'>
          {doctor.FB &&
            <a href={doctor.FB} target='_blank' rel='noreferrer'>
              <img src={facebook} className='facebook' alt='facebookLogo' />
            </a>
          }

          {doctor.instagram &&
            <a href={doctor.instagram} target='_blank' rel='noreferrer'>
              <img src={instagram} className='instagram' alt='instagramLogo' />
            </a>
          }

          {doctor.twitter &&
            <a href={doctor.twitter} target='_blank' rel='noreferrer'>
              <img src={twitter} className='twitter' alt='twitter' />
            </a>
          }

          {doctor.whatsapp &&
            <a href={`whatsapp://send?abid=${doctor.whatsapp}`}>
              <img src={whatsapp} className='whatsapp' alt='whatsappLogo' />
            </a>
          }

          {doctor.viber &&
            <a href={`viber://contact?number=%2B${doctor.viber}`}>
              <img src={viber} className='viber' alt='viberLogo' />
            </a>
          }

          {doctor.telegram &&
            <a target={`_blank`} href={`https://telegram.me/${doctor.telegram}`}>
              <img src={telegram} className='telegram' alt='telegramLogo' />
            </a>
          }

        </div>
      </div>
      <div className='row'>
        <hr className='hrBorder' />
      </div>
      <div className='row mb-4' style={{ width: '100% !important' }}>
        <div className='col-6 phoneDiv'>
          <svg className='mx-2' xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
            <path d='M10.2973 10.5078L9.92615 11.0645C9.85207 11.1757 9.75409 11.2705 9.62497 11.3046C9.20201 11.4164 8.07912 11.4668 6.36956 9.75726C4.65999 8.0477 4.71045 6.9248 4.82221 6.50184C4.85633 6.37272 4.95115 6.27474 5.06228 6.20065L5.61901 5.8295C6.03352 5.55316 6.14553 4.99312 5.86919 4.57862L4.8177 3.00138C4.5765 2.63958 4.11151 2.50135 3.71185 2.67264L3.23751 2.87592C2.87718 3.03035 2.58325 3.3075 2.40793 3.65814C2.26146 3.95109 2.14793 4.25958 2.13255 4.58675C2.08407 5.61777 2.31015 8.15791 5.13953 10.9873C7.96891 13.8167 10.509 14.0427 11.5401 13.9943C11.8672 13.9789 12.1757 13.8654 12.4687 13.7189C12.8193 13.5436 13.0965 13.2496 13.2509 12.8893L13.4542 12.415C13.6255 12.0153 13.4872 11.5503 13.1254 11.3091L11.5482 10.2576C11.1337 9.98128 10.5736 10.0933 10.2973 10.5078Z' stroke='#218CC0' strokeWidth='1.8' />
          </svg>
          <a
            href={`tel:${doctor.phone}`}
            className='phoneNumberSocialBlock text-decoration-none'
          >
            {doctor.phone}
          </a>
        </div>
        {doctor.phone2 &&
          <div className='col-6 phoneDiv'>
            <svg className='mx-2' xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
              <path d='M10.2973 10.5078L9.92615 11.0645C9.85207 11.1757 9.75409 11.2705 9.62497 11.3046C9.20201 11.4164 8.07912 11.4668 6.36956 9.75726C4.65999 8.0477 4.71045 6.9248 4.82221 6.50184C4.85633 6.37272 4.95115 6.27474 5.06228 6.20065L5.61901 5.8295C6.03352 5.55316 6.14553 4.99312 5.86919 4.57862L4.8177 3.00138C4.5765 2.63958 4.11151 2.50135 3.71185 2.67264L3.23751 2.87592C2.87718 3.03035 2.58325 3.3075 2.40793 3.65814C2.26146 3.95109 2.14793 4.25958 2.13255 4.58675C2.08407 5.61777 2.31015 8.15791 5.13953 10.9873C7.96891 13.8167 10.509 14.0427 11.5401 13.9943C11.8672 13.9789 12.1757 13.8654 12.4687 13.7189C12.8193 13.5436 13.0965 13.2496 13.2509 12.8893L13.4542 12.415C13.6255 12.0153 13.4872 11.5503 13.1254 11.3091L11.5482 10.2576C11.1337 9.98128 10.5736 10.0933 10.2973 10.5078Z' stroke='#218CC0' strokeWidth='1.8' />
            </svg>
            <a
              href={`tel:${doctor.phone2}`}
              className='phoneNumberSocialBlock text-decoration-none'
            >
              {doctor.phone2}
            </a>
          </div>
        }
      </div>
    </div>
  );
}

export default DoctorsSocialBlock;
