import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  item: false
};

const loaderSlice = createSlice({
  name: 'addLoader',
  initialState,
  reducers: {
    setLoader(state) {
      state.item = true;
    },
    resetLoader(state) {
      state.item = false
    }
  }
});

export const { setLoader, resetLoader } = loaderSlice.actions;
export default loaderSlice.reducer;
