import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  type: ''
};

const applicationTypeSlice = createSlice({
  name: 'applicationType',
  initialState,
  reducers: {
    setApplicationTypeSlice(state, action) {
      state.type = action.payload
    },
    resetApplicationTypeSlice(state) {
      state.type = ''
    }
  }
});

export const { setApplicationTypeSlice, resetApplicationTypeSlice } = applicationTypeSlice.actions;
export default applicationTypeSlice.reducer;
