import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getMedicalCards, getPatientAdvices, getPatientDiagnosis, getPatientIndicatorData, getPatientLabServices, getPatientMedicalHistories } from "services/api";
import { setHeaderTitle } from "features/headerTitle";


import PatientExample from "assets/patientExample.png";
import time from 'assets/DoctorPage/time.svg';
import flask from 'assets/DoctorPage/flask.svg';
import request from 'assets/DoctorPage/request.svg';
import corona from 'assets/DoctorPage/corona-virus.svg';
import statistics from 'assets/DoctorPage/statistsics.svg';
import drug from 'assets/DoctorPage/drug.svg';
import advices from 'assets/DoctorPage/advices.svg';


import './component.css'

const DoctorOnePatient = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { item } = useSelector(state => state.doctorPatient);

    const handleGetDoctorService = (type) => {
        switch (type) {
          case 'cards':
            getMedicalCards(dispatch, item.slug)
            .then(() => {
              navigate('/medicalCards');
            })
            .catch(e => {
              console.log(e);
            });
            break;
          case 'diagnosis':
            getPatientDiagnosis(dispatch, item.slug)
            .then(() => {
               navigate('/profile/onePatient/diagnosis');  
            })
            .catch(e => {
              console.log(e);
            });
            break;
            case 'medicalHistories':
              getPatientMedicalHistories(dispatch, item.slug)
              .then(() => {
                 navigate('/profile/onePatient/medicalHistories');  
              })
              .catch(e => {
                console.log(e);
              });
              break;
            case 'indicatorData':
              getPatientIndicatorData(dispatch, item.slug)
              .then(() => {
                  navigate('/profile/onePatient/indicators');  
              })
              .catch(e => {
                console.log(e);
              });
              break;
              case 'labServices':
                getPatientLabServices(dispatch, item.slug)
                .then(() => {
                    navigate('/profile/onePatient/labServices');  
                })
                .catch(e => {
                  console.log(e);
                });
                break;  
            case 'advices':
              getPatientAdvices(dispatch, item.slug)
              .then(() => {
                navigate('/profile/onePatient/advices');
              })
              break;
          default:
            return;  
        }
    };

    useEffect(() => {
      dispatch(setHeaderTitle(`ՀԾՀ ${item && item.ssn}`));
    }, []);

    return (
        <div className="row mt-100">
          <div className="col-12">
            <div className="doctorOnePatient" key={`${item.titleArm}_${item.id}`}>
              <img className="doctorPatientImg d-block" src={PatientExample} alt={item.titleArm} />
              <h1 className="body2-400-title">{item.titleArm}</h1>
            </div>
            <div className="patientCredentials">
              {item.addressArm &&
                <p className='mb-25 mt-25'>
                  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
                    <path fillRule='evenodd' clipRule='evenodd' d='M6.98695 4.93691C6.33653 5.74783 6.00039 6.86838 6.00039 8.03636C6.00039 10.4138 7.09434 12.539 8.24551 14.1087C8.81631 14.887 9.38799 15.5108 9.81607 15.9389C9.88111 16.0039 9.94271 16.0643 10.0004 16.1199C10.0581 16.0643 10.1197 16.0039 10.1847 15.9389C10.6128 15.5108 11.1845 14.887 11.7553 14.1087C12.9065 12.539 14.0004 10.4138 14.0004 8.03636C14.0004 6.86838 13.6642 5.74783 13.0138 4.93691C12.3819 4.14909 11.4138 3.6 10.0004 3.6C8.58695 3.6 7.61885 4.14909 6.98695 4.93691ZM10.0004 17.2C9.49383 17.8192 9.49359 17.819 9.49335 17.8188L9.49135 17.8171L9.48711 17.8137L9.47359 17.8024C9.46231 17.793 9.44663 17.7798 9.42679 17.7628C9.38703 17.7289 9.33079 17.68 9.26023 17.6168C9.11927 17.4905 8.92103 17.3066 8.68471 17.0702C8.21281 16.5983 7.5845 15.913 6.95526 15.0549C5.70644 13.3519 4.40039 10.8953 4.40039 8.03636C4.40039 6.58617 4.81425 5.08853 5.73883 3.93582C6.68193 2.76001 8.1138 2 10.0004 2C11.887 2 13.3189 2.76001 14.2619 3.93582C15.1866 5.08853 15.6004 6.58617 15.6004 8.03636C15.6004 10.8953 14.2943 13.3519 13.0455 15.0549C12.4163 15.913 11.788 16.5983 11.3161 17.0702C11.0798 17.3066 10.8815 17.4905 10.7406 17.6168C10.67 17.68 10.6138 17.7289 10.574 17.7628C10.5542 17.7798 10.5385 17.793 10.5272 17.8024L10.5137 17.8137L10.5094 17.8171L10.508 17.8183C10.5078 17.8186 10.507 17.8192 10.0004 17.2ZM10.0004 17.2L10.507 17.8192C10.2123 18.0602 9.78807 18.0599 9.49335 17.8188L10.0004 17.2Z' fill='#218CC0' />
                    <path fillRule='evenodd' clipRule='evenodd' d='M10.0006 6.8002C9.55875 6.8002 9.20059 7.15837 9.20059 7.6002C9.20059 8.04202 9.55875 8.4002 10.0006 8.4002C10.4424 8.4002 10.8006 8.04202 10.8006 7.6002C10.8006 7.15837 10.4424 6.8002 10.0006 6.8002ZM7.60059 7.6002C7.60059 6.27472 8.67507 5.2002 10.0006 5.2002C11.3261 5.2002 12.4006 6.27472 12.4006 7.6002C12.4006 8.92572 11.3261 10.0002 10.0006 10.0002C8.67507 10.0002 7.60059 8.92572 7.60059 7.6002Z' fill='#218CC0' />
                  </svg>
                  <span className='f-s-11 ml-11 text-black'>{item.addressArm}</span>
                </p>
              }

              <p className="mt-3">
                <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
                  <path d='M13.0634 13.345L12.5685 14.0873C12.4698 14.2355 12.3391 14.3619 12.1669 14.4074C11.603 14.5564 10.1058 14.6237 7.8264 12.3443C5.54698 10.0649 5.61426 8.5677 5.76327 8.00376C5.80877 7.83159 5.93519 7.70095 6.08336 7.60217L6.82568 7.1073C7.37835 6.73885 7.5277 5.99213 7.15925 5.43946L5.75726 3.33647C5.43566 2.85407 4.81568 2.66977 4.28279 2.89815L3.65034 3.1692C3.1699 3.3751 2.77799 3.74463 2.54423 4.21216C2.34893 4.60275 2.19756 5.01408 2.17705 5.4503C2.11242 6.825 2.41386 10.2119 6.18636 13.9844C9.95887 17.7569 13.3457 18.0583 14.7204 17.9937C15.1566 17.9731 15.5679 17.8218 15.9585 17.6265C16.4261 17.3927 16.7956 17.0008 17.0015 16.5204L17.2726 15.8879C17.5009 15.355 17.3166 14.735 16.8342 14.4134L14.7312 13.0115C14.1786 12.643 13.4319 12.7924 13.0634 13.345Z' stroke='#218CC0' strokeWidth='1.8' />
                </svg>
                <span className='f-s-11 ml-11 text-black'>{item.phone}</span>
              </p>
            </div>

            <div className='doctor-info-service-block'>
              <div
                className='doctor-info-service-item'
                onClick={_ => handleGetDoctorService('cards')}
              >
                <div className='doctor-info-service-item-icon-block'>
                  <img src={request} alt='request' />
                </div>

                <div className='text-block'>
                  <p className='body2-300-title text-center'>Քարտեր</p>
                </div>
              </div>

              <div className='doctor-info-service-item' onClick={_ => handleGetDoctorService('diagnosis')}>
                <div className='doctor-info-service-item-icon-block'>
                  <img src={corona} alt='corona' />
                </div>

                <div className='text-block'>
                  <p className='body2-300-title text-center'>Ախտորոշում</p>
                </div>
              </div>

              <div
                className='doctor-info-service-item'
                onClick={_ => handleGetDoctorService('medicalHistories')}
              >
                <div className='doctor-info-service-item-icon-block'>
                  <img src={time} alt='file' />
                </div>

                <div className='text-block'>
                  <p className='body2-300-title text-center'>Անամնեզներ</p>
                </div>
              </div>

              <div className='doctor-info-service-item' onClick={_ => handleGetDoctorService('indicatorData')}>
                <div className='doctor-info-service-item-icon-block'>
                  <img src={statistics} alt='pdf' />
                </div>

                <div className='text-block'>
                  <p className='body2-300-title text-center'>Ցուցանիշներ</p>
                </div>
              </div>

              <div className='doctor-info-service-item' onClick={_ => handleGetDoctorService('labServices')}>
                <div className='doctor-info-service-item-icon-block'>
                  <img src={flask} alt='flask' />
                </div>

                <div className='text-block'>
                  <p className='body2-300-title text-center'>Հետազոտութ.</p>
                </div>
              </div>
              <div className='doctor-info-service-item' onClick={_ => handleGetDoctorService('heart')}>
                <div className='doctor-info-service-item-icon-block'>
                  <img src={drug} alt='heart' />
                </div>

                <div className='text-block'>
                  <p className='body2-300-title text-center'>Նշ. դեղորայք</p>
                </div>
              </div>
              <div className='doctor-info-service-item' onClick={_ => handleGetDoctorService('advices')}>
                <div className='doctor-info-service-item-icon-block'>
                  <img src={advices} alt='lab' />
                </div>

                <div className='text-block'>
                  <p className='body2-300-title text-center'>Խորհուրդներ</p>
                </div>
              </div>

            </div>
          </div>
        </div>
    );
}

export default DoctorOnePatient;