import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import {
  getPharmsCategory,
  getPharmsByCategory
} from 'services/api';

// import Header from 'sections/Header';
import Banner from 'components/Banner';
import WorkplaceCategory from 'components/WorkplaceCategory';
import WorkplaceCard from 'components/WorkplaceCard';

const PharmsByCategory = () => {
  const { categorySlug } = useParams();
  const [ pharms, setPharms ] = useState([]);
  const [ category, setCategory ] = useState({});

  const renderPharms = () => {
    return pharms && pharms.length && pharms.map((pharm, index) =>
      <WorkplaceCard
        key={pharm.id}
        workplace={pharm}
        link='pharm'
        containerClasses={index % 2 === 0 ? 'col-pd-r-7 mb16' : 'col-pd-l-7 mb16'}
      />
    );
  };

  useEffect(() => {
    if (Object.keys(category).length === 0) {
      getPharmsCategory()
      .then(res => {
        setCategory(res);
      })
      .catch(e => {
        console.log(e);
      });
    }
    if (!pharms.length) {
      getPharmsByCategory(categorySlug)
      .then(res => {
        setPharms(res);
      })
      .catch(e => {
        console.log(e);
      });
    }
    return () => {
      setPharms([]);
      setCategory({});
    }
  }, []);
    
  return (
    <>
      {/* <Header/> */}
      <Banner />
      <Row>
        <WorkplaceCategory
          category={category}
          link='pharms'
        />
      </Row>
      <Row>
        {renderPharms()}
      </Row>
    </>
  );
}

export default PharmsByCategory;
