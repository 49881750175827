import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModal(state) {
      state.open = true
    },
    resetModal(state) {
      state.open = false
    }
  }
});

export const { setModal, resetModal } = modalSlice.actions;
export default modalSlice.reducer;
