import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: []
};

const objectiveIndicatorsSlice = createSlice({
  name: 'objectiveIndicators',
  initialState,
  reducers: {
    setObjectiveIndicators(state, action) {
      state.items = action.payload;
    },
    resetObjectiveIndicators(state) {
      state.items = []
    }
  }
});

export const { setObjectiveIndicators, resetObjectiveIndicators } = objectiveIndicatorsSlice.actions;
export default objectiveIndicatorsSlice.reducer;
