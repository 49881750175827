import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: []
};

const medCardsSlice = createSlice({
  name: 'medCards',
  initialState,
  reducers: {
    setMedCards(state, action) {
      state.items = action.payload;
    },
    resetMedCards(state) {
      state.items = []
    }
  }
});

export const { setMedCards, resetMedCards } = medCardsSlice.actions;
export default medCardsSlice.reducer;
