import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  addMedicalHistory,
  addAdviceToMedicalCard,
  getDoctorProfileDiagnosis,
  getMedicalHistoryTemplates,
  getOneMedCard,
  deleteLabServiceFromGroup
} from 'services/api';

import { resetModal, setModal } from 'features/modal';
import { resetTempTemplate } from 'features/tempTemplate';
import { setTempModalTitle, resetTempModalTitle } from 'features/tempModalTitle';
import { setAddDiagnosis,resetAddDiagnosis } from 'features/addDiagnosis';
import {
  setHistoriesTemplateType,
  resetHistoriesTemplateType
} from 'features/historiesTemplateType';
import { setAddMedCardOrDoctor } from 'features/addMedCardOrDoctor';
import { setHeaderTitle } from 'features/headerTitle';

import Modal from 'components/Modal';
import Button from 'components/Button';
import Textarea from 'components/Textarea';
import FilterTabs from 'components/FilterTabs';
import FilterCard from 'components/FilterCard';
import MedicalCard from 'components/MedicalCard';
import AnamnezBlock from 'components/AnamnezData';
import MedCardDiagnos from 'components/MedCardDiagnos';
import ObjectiveIndicators from 'components/ObjectiveIndicators';
import MedCardLabServices from 'components/MedCardLabServices';

import diagnosTime from 'assets/Diagnos/diagnos-time.svg';
import timeDiagram from 'assets/Diagnos/time-diagram.svg';
import diagnosTimeBlue from 'assets/Diagnos/diagnos-time-blue.svg';
import timeDiagramBlue from 'assets/Diagnos//time-diagram-blue.svg';
import flask from 'assets/DoctorPage/flask.svg';
import flaskWhite from 'assets/DoctorPage/flaskWhite.svg';

import './component.css';


const OneMedCard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { open } = useSelector(state => state.modal);
  const { text } = useSelector(state => state.tempTemplate);
  const medCard = useSelector(state => state.oneMedCard.item);
  const { code } = useSelector(state => state.oneMedCard.item);
  const tempTitle = useSelector(state => state.tempTitle.text);
  const { items } = useSelector(state => state.historiesTemplate);
  const { advices } = useSelector(state => state.oneMedCard.item);
  const { item } = useSelector(state => state.historiesTemplateType);
  const { cardDiagnosis } = useSelector(state => state.oneMedCard.item);
  const { historiesByTypes } = useSelector(state => state.oneMedCard.item);
  const user = useSelector(state => state.userDetails.item);

  const [ innerHistory, setInnerHistory ] = useState([]);
  const [ innerAdvices, setInnerAdvices ] = useState([]);
  const [ modalTextArea, setModalTextArea ] = useState('');
  const [ innerDiagnosis, setInnerDiagnosis ] = useState([]);
  const [ anamnezTabs, setAnamnezTabs ] = useState([
    {
      titleArm: 'Անամնեզ',
      icon: diagnosTimeBlue,
      active: true,
      choosenIcon: diagnosTime
    },
    {
      titleArm: 'Օբյեկտիվ ցուց.',
      icon: timeDiagramBlue,
      active: false,
      choosenIcon: timeDiagram
    },
    {
      titleArm: 'Հետազուտություն',
      icon: flask,
      active: false,
      choosenIcon: flaskWhite
    },
    {
      titleArm: 'Խորհուրդ',
      icon: flask,
      active: false,
      choosenIcon: flaskWhite
    }
  ]);
  const [ anamnezFilter, setAnamnezFilter ] = useState('Անամնեզ');
  const [ filterDiagnosisTabs, setFilterDiagnosisTabs ] = useState([]);
  const [ filterHistoriesTabs, setFilterHistoriesTabs ] = useState([]);
  const [ modalTextAreaErrorText, setModalTextAreaErrorText ] = useState('');

  const [ activeDiagnosisFilter, setActiveDiagnosisFilter ] = useState(
    cardDiagnosis && cardDiagnosis[0] ? cardDiagnosis[0].typeTitle : ''
  );

  const [ activeHistoriesFilter, setActiveHistoriesFilter ] = useState(
    historiesByTypes && historiesByTypes[0] ? historiesByTypes[0].title : 'Խորհուրդ'
  );

  const handleSetDiagnosisActive = (active) => {
    setActiveDiagnosisFilter(_ => active);
  };

  const handleSetHistoriesActive = (active) => {
    setActiveHistoriesFilter(_ => active);
  };

  const handleGetDiagnosis = () => {
    getDoctorProfileDiagnosis(dispatch)
    .then(() => {
      const diagnosType = cardDiagnosis.filter(item => item.typeTitle === activeDiagnosisFilter);
      const diagnos = {
        medicalCardCode: code,
        type: diagnosType[0].type,
        diagnosis: []
      };
      dispatch(setAddDiagnosis(diagnos));
      dispatch(setAddMedCardOrDoctor('medCard'));
      navigate('/diagnosis');
    })
    .catch(e => {
      console.log(e);
    });
  };

  const handleOpenModal = () => {
    const title = anamnezFilter === 'Անամնեզ' ? activeHistoriesFilter : 'Խորհուրդ';
    dispatch(setTempModalTitle(title));
    dispatch(setModal());
  };

  const handleChooseTemplate = () => {
    const historyType = historiesByTypes.filter(item => item.title === activeHistoriesFilter);
    dispatch(setHistoriesTemplateType(historyType[0].type));
    getMedicalHistoryTemplates(dispatch)
    .then(() => {
      navigate('/historiesTemplate');
    })
    .catch(e => {
      console.log(e);
    });
  };

  const handleChooseAdviceTemplate = () => {
    navigate('/advicesTemplate');
  };

  const handleAddAdviceToMedCard = () => {
    if (modalTextArea === '') {
      setModalTextAreaErrorText(_ => 'Լրացրեք դաշտը')
    } else {
      const params = {
        medicalCardCode: code,
        description: modalTextArea
      }
      addAdviceToMedicalCard(params)
      .then(() => {
        dispatch(resetModal());
        dispatch(resetTempTemplate());
        dispatch(resetTempModalTitle());
        getOneMedCard(dispatch, medCard.code);
      })
      .catch(e => {
        if (e.Error.response.status === 400) {
          const { description } = e.Error.response.data.data.errors;
          if (description) {
            setModalTextAreaErrorText(_ => description);
          }
        }
      })
    }
  };

  const handleAddHistory = () => {
    if (modalTextArea === '') {
      setModalTextAreaErrorText(_ => 'Լրացրեք դաշտը')
    } else {
      const historyType = historiesByTypes.filter(item => item.title === activeHistoriesFilter);
      const params = {
        medicalCardCode: code,
        type: historyType[0].type,
        description: modalTextArea
      }
      addMedicalHistory(dispatch, params)
      .then(() => {
        dispatch(resetHistoriesTemplateType());
        dispatch(resetTempTemplate());
        dispatch(resetModal());
        getOneMedCard(dispatch, medCard.code);
      })
      .catch(e => {
        if (e.Error.response.status === 400) {
          const { description } = e.Error.response.data.data.errors;
          if (description) {
            setModalTextAreaErrorText(_ => description);
          }
        }
      });
    }
  };

  const handleSetTextArea = (value) => {
    setModalTextArea(_ => value);
    setModalTextAreaErrorText(_ => '');
  };

  const handleCheckActiveAnamnez = (item) => {
    const newAnamnezData = anamnezTabs.map((innerItem) => {
      if (innerItem.titleArm === item.titleArm) {
        innerItem.active = true
      } else {
        innerItem.active = false
      }
      return innerItem
    });
    setAnamnezFilter(_ => item.titleArm);
    setAnamnezTabs(_ => {
      return [
        ...newAnamnezData
      ]
    });
  };

  const onDeleteLabService = (groupId, serviceId) => {
      let params = {
        'labServiceGroupId' : groupId,
        'labServiceId' : serviceId,
      };

      deleteLabServiceFromGroup(params)
        .then(response => {
          getOneMedCard(dispatch, code);
        })
  }

  const modalAddHistoryUI = <div className='one-med-card-modal-content'>
    <h5 className='body2-400-title one-med-card-modal-title'>
      Ավելացնել {tempTitle}
    </h5>

    <div className='one-med-card-modal-textarea-block'>
      <Textarea
        value={modalTextArea}
        change={handleSetTextArea}
        errorMessage={modalTextAreaErrorText}
      />
    </div>
  </div>;

  useEffect(() => {
    dispatch(resetAddDiagnosis());
    if (!code) {
      navigate('/doctorAccount');
    }
  }, []);

  useEffect(() => {
    if (!open) {
      setModalTextArea(_ => '');
      setModalTextAreaErrorText(_ => '');
    }
  }, [open]);

  useEffect(() => {
    if (text) {
      setModalTextArea(_ => text);
      dispatch(setModal());
    }
  }, [text]);

  useEffect(() =>{
    setInnerAdvices(() => [...advices]);
  }, [advices]);

  useEffect(() => {
    if (cardDiagnosis && cardDiagnosis.length) {
      const tabs = cardDiagnosis.map(item => item.typeTitle);
      setFilterDiagnosisTabs(_ => {
        return [
          ...tabs
        ]
      });
    }

    const newDiagnosis = cardDiagnosis && cardDiagnosis.length && cardDiagnosis.filter(item => item.typeTitle === activeDiagnosisFilter);
    if (newDiagnosis && newDiagnosis[0] && newDiagnosis[0].diagnosis && newDiagnosis[0].diagnosis.length) {
      setInnerDiagnosis(_ => {
        return newDiagnosis[0].diagnosis
      });
    } else {
      setInnerDiagnosis(_ => {
        return []
      });
    }
  }, [cardDiagnosis]);

  useEffect(() => {
    if (historiesByTypes && historiesByTypes.length) {
      const tabs = historiesByTypes.map(item => item.title);
      setFilterHistoriesTabs(_ => {
        return [
          ...tabs
        ]
      });
    }

    const newHistories = historiesByTypes && historiesByTypes.length && historiesByTypes.filter(item => item.title === activeHistoriesFilter);
    if (newHistories && newHistories[0] && newHistories[0].histories && newHistories[0].histories.length) {
      setInnerHistory(_ => {
        return newHistories[0].histories
      });
    } else {
      setInnerHistory(_ => {
        return []
      });
    }
  }, [historiesByTypes]);

  useEffect(() => {
    const newDiagnosis = cardDiagnosis && cardDiagnosis.length && cardDiagnosis.filter(item => item.typeTitle === activeDiagnosisFilter);
    if (newDiagnosis && newDiagnosis[0] && newDiagnosis[0].diagnosis && newDiagnosis[0].diagnosis.length) {
      setInnerDiagnosis(_ => {
        return newDiagnosis[0].diagnosis
      });
    } else {
      setInnerDiagnosis(_ => {
        return []
      });
    }
  }, [activeDiagnosisFilter]);

  useEffect(() => {
    const newHistories = historiesByTypes && historiesByTypes.length && historiesByTypes.filter(item => item.title === activeHistoriesFilter);
    if (newHistories && newHistories[0] && newHistories[0].histories && newHistories[0].histories.length) {
      setInnerHistory(_ => {
        return newHistories[0].histories
      });
    } else {
      setInnerHistory(_ => {
        return []
      });
    }
  }, [activeHistoriesFilter]);

  useEffect(() => {
    if (item) {
      const historyFilter = items && items.length && items.filter(innerItem => innerItem.type === item);
      setActiveHistoriesFilter(_ => historyFilter && historyFilter.length && historyFilter[0].title);
    }
  }, [item]);

  useEffect(() => {
    medCard && dispatch(setHeaderTitle(medCard.code));
  }, [])

  return (
    <div className='one-med-card-block'>
      <Modal
        data={{}}
        actionType=''
        checkInputValue=''
        cancelText='Ընտրել Շաբլոն'
        content={modalAddHistoryUI}
        successText='Պահպանել'
        callSuccess={tempTitle === 'Խորհուրդ' ? handleAddAdviceToMedCard : handleAddHistory}
        callCancel={tempTitle === 'Խորհուրդ' ? handleChooseAdviceTemplate : handleChooseTemplate}
      />

      <MedicalCard
        item={medCard}
        withAddService={user && user.roles.includes('ROLE_DOCTOR')}
        inOnePage
      />

      <div className='one-med-card-item'>
        <h3 className='caption1-300 fw-bold'>
          Ախտորոշում {/* section name */}
        </h3>

        {
          filterDiagnosisTabs && filterDiagnosisTabs.length && <FilterTabs
            tabs={filterDiagnosisTabs}
            checkActive={handleSetDiagnosisActive}
          />
        }

        {user && user.roles.includes('ROLE_DOCTOR') && 
             <div className='one-med-card-item-btn-block'>
              <Button
                text='Ավելացնել'
                classUI='caption1-200'
                click={handleGetDiagnosis}
              />
            </div>
        }
     

        {
          innerDiagnosis.length ? <div className='diagnos-card-block'>
            {
              innerDiagnosis.map((item, index) => {
                return <MedCardDiagnos
                  item={item}
                  code={code}
                  key={`${item.id}_${index}`}
                  type={cardDiagnosis.filter(item => item.typeTitle === activeDiagnosisFilter)[0].type}
                />
              })
            }
          </div> : ''
        }
      </div>

      <div className='one-med-card-item'>
        <div className='diagnos-card-block'>
          {
            anamnezTabs && anamnezTabs.length && anamnezTabs.map((item, index) => {
              return <FilterCard
                key={`${item.text}_${index}`}
                item={item}
                checkActive={handleCheckActiveAnamnez}
                active={item.active}
              />
            })
          }
        </div>
      </div>

      {
        (anamnezFilter === 'Անամնեզ' || anamnezFilter === 'Խորհուրդ') &&
          <div className='one-med-card-item'>
            <AnamnezBlock
              withDoctorDetails
              openModal={handleOpenModal}
              withAddButton={user && user.roles.includes('ROLE_DOCTOR')}
              histories={anamnezFilter === 'Անամնեզ' ? innerHistory : innerAdvices}
              activeFilter={anamnezFilter === 'Անամնեզ' ? activeHistoriesFilter : ''}
              filterHistories={anamnezFilter === 'Անամնեզ' ? filterHistoriesTabs : []}
              setHistoriesActive={anamnezFilter === 'Անամնեզ' ? handleSetHistoriesActive : _ => _}
            />
          </div>
      }

      {
        anamnezFilter === 'Օբյեկտիվ ցուց.' && 
        <div className='one-med-card-item'>
          <ObjectiveIndicators />
        </div>
      }

      {
        anamnezFilter === 'Հետազուտություն' &&
        <div className='one-med-card-item'>
           <MedCardLabServices onDeleteService={onDeleteLabService} />
        </div>
      }
    </div>
  )
};

export default OneMedCard
