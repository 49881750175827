import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import LocationIcon from "../../../assets/DoctorPage/DoctorsSinglePage/locationIcon.png";

const AddressRadios = ({workplace = null, workplaceAddress, onChange, onCancel, onSubmit}) => {

    const renderWorkplacesRadios = () => {
        if (workplace && workplace.workplaces && workplace.workplaces.length > 0) {
            return workplace.workplaces.map((oneWorkplace, index) =>
                <div key={oneWorkplace.id} className="mb-4">
                    <Form.Check
                        name='oneWorkplace'
                        type={`radio`}
                        checked={oneWorkplace === workplaceAddress}
                        onChange={() => onChange(oneWorkplace)}
                        id={`default-${oneWorkplace.id}`}
                        label={
                            <>
                                <img src={LocationIcon} className="locationIcon" alt="location"/>
                                <span className='f-s-12'>{oneWorkplace.addressArm}</span>
                            </>

                        }
                    />
                </div>
            );
        }
    }

    return (
        <div className='row'>
            <p className="f-s-16 fw-bold mb22">Ընտրել հասցեն</p>
            {renderWorkplacesRadios()}
            <div className="col-12">
                <div className="row h40 mt24">
                    <Col xs={6} className='text-center'>
                        <Link onClick={onCancel}
                              className="f-s-14 text-decoration-underline text-blue lh40">Չեղարկել</Link>
                    </Col>
                    <Col xs={6} className='text-center'>
                        <button className="btn btn-blue" disabled={!workplaceAddress}
                                onClick={onSubmit}>Հաստատել
                        </button>
                    </Col>
                </div>
            </div>
        </div>
    );
}

export default AddressRadios;
