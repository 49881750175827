import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  item: {}
};

const doctorPatientSlice = createSlice({
  name: 'doctorPatients',
  initialState,
  reducers: {
    setDoctorPatient(state, action) {
      state.item = action.payload
    },
    resetDoctorPatient(state) {
      state.item = {}
    }
  }
});

export const { setDoctorPatient, resetDoctorPatient } = doctorPatientSlice.actions;
export default doctorPatientSlice.reducer;
