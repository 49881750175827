import React, { useState, useEffect } from 'react';
import { Calendar } from 'react-calendar';
import { Col, Row } from 'react-bootstrap';

import { handleGetDate, hasOwnerProperty } from 'utils';

import MyModal from 'components/Global/MyModal';

import prevIcon from 'assets/DoctorPage/DoctorsSinglePage/arrowLeft.png';
import nextIcon from 'assets/DoctorPage/DoctorsSinglePage/arrowRight.png';

import 'components/Global/Calendar/calendar.css';

const CalendarForm = ({
  date,
  time,
  times,
  setDate,
  setTime,
  onCancel,
  onSubmit,
  busyDays,
  onlyDate,
  filterDate,
  serviceIndex,
  freeBusyMode,
  submitBtnTitle = 'Հաստատել'
}) => {
  const [ status, setStatus ] = useState('');
  const [ innerTimes, setInnerTimes ] = useState([]);
  const [ showModal, setShowModal ] = useState(false);
  const [ checkDate, setCheckDate ] = useState(false);
  const [ innerDisableDays, setInnerDisableDays ] = useState([]);

  const isTileDisabled = ({ date }) => {
    if (innerDisableDays && innerDisableDays.length) {
      return innerDisableDays.some(
        (disabledDate) =>
          date.getFullYear() === disabledDate.getFullYear() &&
          date.getMonth() === disabledDate.getMonth() &&
          date.getDate() === disabledDate.getDate()
      );
    }
  };

  const handleSetTime = (time) => {
    if (freeBusyMode) {
      const index = innerTimes.findIndex(item => item.time === time);
      const newTimesSelected = [...innerTimes];
      const newTimes = newTimesSelected && newTimesSelected.length && newTimesSelected.map((timeItem, timeIndex) => {
        if (timeIndex === index) {
          if (!hasOwnerProperty(timeItem, 'selected')) {
            const newObj = { ...timeItem, selected: true }
            timeItem = newObj;
          } else {
            if (timeItem.selected) {
              timeItem.selected = false;
            } else {
              timeItem.selected = true;
            }
          }
        }
        return timeItem
      })
      setInnerTimes(() => {
        return [
          ...newTimes
        ]
      })
    } else {
      setTime(time);
    }
  };

  const renderDoctorTimes = () => {
    return innerTimes && innerTimes.length ? innerTimes.map(one =>
      <div
        onClick={() => handleSetTime(one.time)}
        className={
          `
            col py11-5 f-s-14 fw-bold text-center text-black
            ${one.status === 'busy' ? 'disabledTime' : ''}
            ${one.time === time || one.selected ? 'activeTime' : ''}
          `
        }
        key={one.time}>
        {one.time}
      </div>
    ) : ''
  };

  const handleChange = (date) => {
    setDate(date);
  };

  const handleDateChange = (date) => {
    setCheckDate(true)
    setDate(date);
  };

  const handleSubmit = (status) => {
    if (freeBusyMode) {
      setStatus(() => status);
      setShowModal(() => true);
    } else {
      onSubmit(serviceIndex)
    }
  };

  const handleSendDateAndTimes = () => {
    setShowModal(() => false);
    const selectedDate = handleGetDate(date, true);
      const selectedTimes = innerTimes && innerTimes.length && innerTimes.filter(item => item.selected === true).map(innerItem => innerItem.time);
      const dateAndTimes = {
        date: selectedDate,
        times: selectedTimes,
        status: status
      };
      onSubmit(dateAndTimes);
  };

  const handleCancelTimes = () => {
    setShowModal(() => false);
    const selectedTimes = innerTimes && innerTimes.length && innerTimes.map(item => {
      if (item.selected === true) {
        item.selected = false;
      }
      return item
    });
    setInnerTimes(() => {
      return [...selectedTimes]
    })
  };

  const modalBody = <>
    <Row>
      <Col xs={12} className='text-center' style={{ marginBottom: '40px' }}>
        Հաստատել { status === 'busy' ? 'զբաղված' : 'ազատ'  } ժամերը
      </Col>
      <Col xs={6} className='text-center'>
        <button
          onClick={handleCancelTimes}
          className='btn btn-transparent text-decoration-underline f-s-14 text-blue'
        >
          Չեղարկել
        </button>
      </Col>
      <Col xs={6} className='text-center'>
        <button
          onClick={handleSendDateAndTimes}
          className='btn btn-blue'
        >
          Հաստատել
        </button>
      </Col>
    </Row>
  </>;

  useEffect(() => {
    if (busyDays && busyDays.length) {
      const newBusyDays = [];
      busyDays.map((item) => {
        const dateStr = item.split('/');
        const checkBusyDays = new Date(dateStr[2], parseInt(dateStr[1]) - 1, dateStr[0])
        newBusyDays.push(checkBusyDays);
      })
      setInnerDisableDays(_ => newBusyDays)
    }
  }, [busyDays]);

  useEffect(() => {
    if (times && times.length) {
      setInnerTimes(() => times);
    }
  }, [times]);

  useEffect(() => {
    return () => {
      setCheckDate(false)
    }
  }, [])

  return (
    <div className='myCalendar'>
      <Calendar
        value={date}
        locale={`HY`}
        next2Label={null}
        prev2Label={null}
        tileDisabled={isTileDisabled}
        minDate={filterDate ? '' : new Date()}
        onChange={onlyDate ? handleDateChange : handleChange}
        nextLabel={<img src={nextIcon} className='calendarArrows' alt='next' />}
        prevLabel={<img src={prevIcon} className='calendarArrows' alt='prev' />}
      />
      {
        !onlyDate && <div className='times row'>
          <p className='text-center f-s-16 text-black my32'>
            {
              !freeBusyMode ?
                'Հերթագրման ժամերը' : innerTimes && innerTimes.length ?
                  `Ընտրել ժամերը ամսի ${handleGetDate(date, true).split('/')[0]}-ի համար` : ''
            }
          </p>
          {renderDoctorTimes()}
        </div>
      }
      <Row className='buttonsDiv pb16'>
        {
          !freeBusyMode ?
          <>
            <Col xs={6} className='text-center'>
              <button
                onClick={onCancel}
                className='btn btn-transparent text-decoration-underline f-s-14 text-blue'
              >
                Վերադառնալ
              </button>
            </Col>
            <Col xs={6} className='text-center'>
              <button
                onClick={handleSubmit}
                className='btn btn-blue'
                disabled={onlyDate ? !date || !checkDate : !date || !time}
              >
                {submitBtnTitle}
              </button>
            </Col>
          </> : 
          <>
            <Col xs={6} className='text-center'>
              <button
                onClick={() => handleSubmit('free')}
                className='btn btn-transparent text-decoration-underline f-s-14 text-blue'
              >
                Ազատ եմ
              </button>
            </Col>
            <Col xs={6} className='text-center'>
              <button
                onClick={() => handleSubmit('busy')}
                className='btn btn-transparent text-decoration-underline f-s-14 text-blue'
              >
                Զբաղված եմ
              </button>
            </Col>
          </>
        }
      </Row>
      <MyModal
        centered
        show={showModal}
        body={modalBody}
        onHide={() => setShowModal(false)}
      />
    </div>
  );
}

export default CalendarForm;
