import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getDoctor } from 'services/api';

import NewDepartments from 'components/NewDepartments';

import './component.css';

const CardServices = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const workplace = useSelector(state => state.oneMedCard?.item?.workplaceDetails);

  const handleGetDoctor = (doctor) => {
    getDoctor(dispatch, doctor.slug)
    .then(() => {
      navigate('/addServicesToMedCard')
    })
  }

  return (
    <div className='card-services-block'>
      <NewDepartments onClickDoctor={handleGetDoctor} useDoctorAsLink={false} withDoctorsView workplace={workplace}/>
    </div>
  )
};

export default CardServices
