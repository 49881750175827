import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: []
};

const patientMedicalHistoriesSlice = createSlice({
  name: 'patientMedicalHistories',
  initialState,
  reducers: {
    setPatientMedicalHistories(state, action) {
      state.items = action.payload
    },
    resetPatientMedicalHistories(state) {
      state.items = []
    }
  }
});

export const { setPatientMedicalHistories, resetPatientMedicalHistories } = patientMedicalHistoriesSlice.actions;
export default patientMedicalHistoriesSlice.reducer;

