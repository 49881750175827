import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { getOneMedCard, addIndicatorFormData } from 'services/api';

import Input from 'components/Input';
import Button from 'components/Button';
import Indicator from 'components/Indicator';
import OpenableItem from 'components/DynamicFormsOpenableItem';

import plus from 'assets/Dynamic/plus-icon.svg';
import minus from 'assets/Dynamic/minus-icon.svg';

import './component.css';

const DynamicForms = ({ item, items, fromPatientPage }) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const medCardInfo = useSelector(state => state.oneMedCard.item);
  const patientIndicators = useSelector(state => state.patientIndicatorData.items);

  const [ data, setData ] = useState({});
  const [ formSlug, setFormSlug ] = useState('');
  const [ showForm, setShowForm ] = useState([]);
  const [ innerItem, setInnerItem ] = useState({});
  const [ innerItems, setInnerItems ] = useState([]);
  const [ description, setDescription ] = useState([]);

  const handleOpen = (check, index) => {
    const newShow = showForm;
    const newDesc = description;
    newShow[index].show = !check;
    newDesc[index].desc = '';
    setShowForm(() => {
      return [
        ...newShow
      ]
    });
    setDescription(_ => {
      return [
        ...newDesc
      ]
    });
    if (ref.current) {
      ref.current.scrollLeft = 0;
    }
  };

  const handleChange = (obj) => {
    const { value } = obj.e.target;
    setData(prev => {
      return {
        ...prev,
        [obj.key]: value
      }
    });
    setFormSlug(() => obj.slug);
  };

  const handleChangeDesc = (e, index) => {
    const newDesc = description;
    const { value } = e.target;
    newDesc[index].desc = value;
    setDescription(_ => {
      return [
        ...newDesc
      ]
    });
  };

  const handleSaveForm = (index) => {
    let check = false;
    let paramFields = [];
    if (items && items.length) {
      items.map((innerItem, innerIndex) => {
        index === innerIndex && innerItem.fields.map((iItem) => {
          paramFields.push([iItem.id, data[iItem.titleArm]])
        })
      });
    }

    // eslint-disable-next-line array-callback-return
    paramFields && paramFields.length && paramFields.map((item) => {
      // eslint-disable-next-line array-callback-return
      item.map(innerItem => {
        if (innerItem === '' || innerItem === undefined || innerItem === null) {
          check = true;
        }
      })
    });

    if (!description[index].desc || check === true) {
      return;
    }

    const params = {
      medicalCardCode: medCardInfo.code,
      indicatorFormSlug: formSlug,
      description: description[index].desc,
      data: paramFields
    };

    addIndicatorFormData(params)
    .then(() => {
      setShowForm(_ => []);
      getOneMedCard(dispatch, medCardInfo.code);
    })
    .catch(e => {
      console.log(e);
    });
  };

  const drawCards = (title) => {
    let values = fromPatientPage ? patientIndicators : innerItem.formValues;
    return values && values.length > 0 && values.filter(item => item.objectiveIndicatorForm.titleArm === title)
    .map((item, index) => {
        return <Indicator
          key={`${item.created}_${index}`}
          item={item}
        />
    });
  };

  const handleCreateForm = (formItems, index, slug) => {
    return (
      showForm[index]?.show ? <div className='new-form'>
        {
          formItems.fields && formItems.fields.length && formItems.fields.map((innerItem, innerIndex) => {
            return <OpenableItem
                slug={slug}
                key={`${innerItem.slug}_${innerIndex}`}
                item={innerItem}
                change={handleChange}
              />
          })
        }

        <div className='dynamic-forms-static-item'>
          <Input
            value={description[index].desc}
            change={e => handleChangeDesc(e, index)}
            placeholder='Մուտքագրել գանգատը'
          />
        </div>

        <div className='dynamic-forms-btn-block'>
          <Button
            bgColor
            text='Պահպանել'
            click={() => handleSaveForm(index)}
          />
        </div>
      </div> : ''
    )
  };

  useEffect(() => {
    const setNewShow = [];
    const setNewDesc = [];
    setInnerItems(_ => {
      return [
        ...items
      ]
    });

    // eslint-disable-next-line array-callback-return
    items && items.length && items.map((iItem, iINdex) => {
      setNewShow.push({ show: false, index: iINdex })
      setNewDesc.push({ desc: '', index: iINdex })
      if (iItem.fields && iItem.fields.length) {
        iItem.fields.map((inner) => data[inner.titleArm] = '');
        setData(_ => {
          return {
            ...data
          }
        })
      }
    })

    setShowForm(() => setNewShow)
    setDescription(() => setNewDesc)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    setInnerItem(() => {
      return {
        ...item
      }
    });
  }, [item]);

  return (
    innerItems && innerItems.length > 0 && innerItems.map((innerItem, innerIndex) => {
      return <div
        key={`${innerItem.titleArm}_${innerIndex}`}
        className='dynamic-forms-block'
        style={{
          padding: '8px 16px'
        }}
      >
        <div
          onClick={() => fromPatientPage ? _ => _ : handleOpen(showForm[innerIndex].show, innerIndex)}
          className='dynamic-forms-exist-block'
        >
          <h4 className='body2-400-title dynamic-forms-exist-title'>
            {
              innerItem.titleArm
            }
          </h4>

          {
            !fromPatientPage && <div className='dynamic-forms-exist-icon-block'>
              <img src={!showForm[innerIndex]?.show ? plus : minus} alt='plus minus' />
            </div>
          }
        </div>

        <div
          ref={ref}
          className='dynamic-forms-hidden-block'
        >
          {
            !fromPatientPage && handleCreateForm(innerItem, innerIndex, innerItem.slug)
          }
          {drawCards(innerItem.titleArm)}
        </div>

      </div>
    })
  )
};

DynamicForms.propTypes = {
  item: PropTypes.object
};

export default DynamicForms;
