import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getCookie } from 'utils';
import { login, getUsersDetails } from 'services/api';

import Input from 'components/Input';
import Button from 'components/Button';

import './component.css';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { item } = useSelector(state => state.userDetails);

  const [ error, setError ] = useState(false);
  const [ errorText, setErrorText ] = useState('');
  const [ formData, setFormData ] = useState({
    username: '',
    password: ''
  });

  const handleChange = (e) => {
    setError(false);
    setErrorText('');
    const { name, value } = e.target;
    setFormData(prev => {
      return {
        ...prev,
        [name]: value
      }
    });
  };

  const handleSubmit = () => {
    // TODO: add validation
    login(formData)
    .then(() => {
      getUsersDetails(dispatch)
      .then(res => {
        if (res.includes('ROLE_DOCTOR')) {
          navigate('/doctorAccount');
        } else {
          // TODO: implement pacient account
          navigate('/patientAccount');
        }
      })
      .catch(e => {
        console.log(e, 'login page, get user details');
      });
    }).catch(e => {
      if (e.Error.response.status === 400) {
        const { message } = e.Error.response.data;
        setError(true);
        setErrorText(message);
      }
    });
  };

  useEffect(() => {
    const checkAccess = getCookie('access_token');
    if (checkAccess) {
      if (item && item.roles) {
        if (item.roles.includes('ROLE_DOCTOR')) {
          navigate('/doctorAccount');
        } else {
          // TODO: implement pacient account
          navigate('/patientAccount');
        }
      }
    }
  }, []);

  return (
    <>
      <div className='login-block'>
        <h1 className='body1-300-title text-center'>Մուտք գործել</h1>
        <div className='login-form-block'>
          <div className='login-form-item'>
            <Input
              name='username'
              label='Մուտքանուն'
              value={formData?.name}
              change={handleChange}
              placeholder='Մուտքանուն'
            />
          </div>

          <div className='login-form-item'>
            <Input
              error={error}
              type='password'
              name='password'
              label='Գաղտնաբառ'
              change={handleChange}
              errorText={errorText}
              placeholder='Գաղտնաբառ'
              value={formData?.password}
            />
          </div>

          <div className='forgot-password-block'>
            <p className='forgot-password-text'>Մոռացել եք գաղտնաբառը</p>
            <Link to='' className='forgot-password-link'>Վերականգնել</Link>
          </div>

          <div className='button-block'>
            <Button
              bgColor
              text='Մուտք գործել'
              click={handleSubmit}
            />
          </div>

          <div className='login-announcement-block'>
            <p className='login-announcement-text'>
              Համակարգում գրանցումն իրականացվում է միայն բժշկի կողմից առաջին այցի գրանցումից հետո
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
