import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  itemsPart: []
};

const departmentsSlice = createSlice({
  name: 'departments',
  initialState,
  reducers: {
    setDepartments(state, action) {
      state.items = action.payload;
    },
    setDepartmentsPart(state, action) {
      state.itemsPart = action.payload;
    },
    resetDepartments(state) {
      state.items = [];
      state.itemsPart = [];
    },
  }
});

export const { setDepartments, setDepartmentsPart, resetDepartments } = departmentsSlice.actions;
export default departmentsSlice.reducer;
