import Cookies from 'js-cookie';

export const hasOwnerProperty = (object, property) => {
  return Object.prototype.hasOwnProperty.call(object, property)
};

export function makeid(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const setCookie = (key, value, expirationDays) => {
  Cookies.set(key, value, { expires: expirationDays });
};

export const getCookie = (key) => {
  return Cookies.get(key);
};

export const clearCookies = () => {
  Cookies.remove('ITCSessId');
  Cookies.remove('access_token');
  Cookies.remove('refresh_token');
  Cookies.remove('token_type');
};

export const getHeaders = () => {
  const token = getCookie('access_token');

  return {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  }
};

export const join = (t, a, s) => {
  function format(m) {
    let f = new Intl.DateTimeFormat('en', m);
    return f.format(t);
  }

  return a.map(format).join(s);
};

export const refreshToken = async (url, axiosInstance) => {
  let newParams = new FormData()
  newParams.append('client_id', process.env.REACT_APP_CLIENT_ID)
  newParams.append('client_secret', process.env.REACT_APP_CLIENT_SECRET)
  newParams.append('grant_type', process.env.REACT_APP_GRANT_TYPE_REFRESH)
  newParams.append('refresh_token', Cookies.get('refresh_token'))

  return await axiosInstance(url, {
    method: 'POST',
    data: newParams,
  })
  .then(response => {
    if (response.status === 200) {
      const {
        expires_in,
        token_type,
        access_token,
        refresh_token
      } = response.data;
      setCookie('token_type', token_type, expires_in);
      setCookie('access_token', access_token, expires_in);
      setCookie('refresh_token', refresh_token, expires_in);
      return true;
    }
  })
  .catch((e) => {
    return false;
    // return Promise.reject({
    //   Request: process.env.REACT_APP_BASE_DOMAIN + 'token',
    //   Error: e
    // })
  })
};

export const handleGetDate = (date, check) => {
  const chackDate = new Date(date);
  let day = check ? chackDate.getUTCDate() + 1 : chackDate.getUTCDate();
  const year = chackDate.getUTCFullYear();
  let month = chackDate.getUTCMonth() + 1;
  if (month < 10) {
    month = '0' + month; 
  }

  if (day < 10) {
    day = '0' + day
  }
  const newDate = day + '/' + month + '/' + year;
  return newDate;
};


export const getCarouselResponsive = () => {
  return {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: {max: 4000, min: 3000},
        items: 5
    },
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 3
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 1
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 3
    }
  };
}
