import React, { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import {
  getWorkplacesCategory,
  getWorkplacesByCategory
} from 'services/api';

import Banner from 'components/Banner';
import WorkplaceCard from 'components/WorkplaceCard';
import WorkplaceCategory from 'components/WorkplaceCategory';

const WorkplacesByCategory = () => {
  const { categorySlug } = useParams();
  const [ category, setCategory ] = useState({});
  const [ workplaces, setWorkplaces ] = useState([]);

  const renderWorkplaces = () => {
    return workplaces && workplaces.length > 0  && workplaces.map((workplace, index) =>
      <WorkplaceCard
        key={workplace.id}
        workplace={workplace}
        containerClasses={index % 2 === 0 ? "col-6 col-pd-r-7 mb16" : "col-6 col-pd-l-7 mb16"}
        link={`/workplace/one/${workplace.slug}`}
        withContacts
        titleClasses='caption2-400'
      />
    );
  };

  useEffect(() => {
    if (Object.keys(category).length === 0) {
      getWorkplacesCategory(categorySlug)
      .then(res => {
        setCategory(res);
      })
      .catch(e => {
        console.log(e);
      });
    }
    if (!workplaces.length) {
      getWorkplacesByCategory(categorySlug)
      .then(res => {
        setWorkplaces(res);
      })
      .catch(e => {
        console.log(e);
      });
    }
    return () => {
      setCategory({});
      setWorkplaces([]);
    }
  }, []);

  return (
    <>
      {/* <Header/> */}
      <Banner containerClasses='mt-100' />
      <Row>
        <WorkplaceCategory category={category} />
      </Row>
      <Row>
        {renderWorkplaces()}
      </Row>
    </>
  );
}

export default WorkplacesByCategory;
