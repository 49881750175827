import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setHeaderTitle } from "features/headerTitle";
import { handleGetDate } from "utils";

import FilterTabs from "components/FilterTabs";
import LabOneService from "components/LabOneService";


import LocationIcon from 'assets/DoctorPage/DoctorsSinglePage/locationIcon.png';


const PatientLabServices = () => {
    const dispatch = useDispatch();
    const labServicesGroups = useSelector(state => state.patientLabServices.items);

    const [filter, setFilter] = useState([]);
    const [activeFilter, setActiveFilter] = useState([]);

    const handleActiveFilter = (active) => {
        setActiveFilter(_ => active);
    };


    useEffect(() => {
        const dynamicFilters = labServicesGroups && labServicesGroups.length > 0 && 
        labServicesGroups.map(item => item.titleArm);
          setFilter(() => {
            return [
              ...dynamicFilters
            ]
          });
        setActiveFilter(() => {
          return dynamicFilters[0]
        })
    }, [labServicesGroups]);
  
    useEffect(() => {
        dispatch(setHeaderTitle('Հետազոտություններ'));
    }, []);

    return (
        <div className="mt-100">
             <FilterTabs
                tabs={filter}
                active={activeFilter}
                checkActive={handleActiveFilter}
            />

            {activeFilter === filter[0] && 
              <>
                  {
                    labServicesGroups && labServicesGroups.length > 0 && labServicesGroups.map((status, index) => {
                    
                      return <div className="row mt-1" key={`${status.titleArm}_${index}`}>
                          {
                            status.groups && status.groups.length > 0 && status.groups.map((group, groupIndex) => {
                                  return <div key={`${group.id}_${groupIndex}`} className='col-12'>
                                          <div className="oneGroup px-2">
                                            <p className='text-green caption2-300'>
                                              {handleGetDate(group.created)}
                                            </p>

                                            <p className="my-3">
                                                  <img className='locationIcon float-left' src={LocationIcon} alt='location' />
                                                  <span className='caption2-300'>{group.workplaceDetails.titleArm}</span>
                                              </p>

                                            <p className='caption2-300 fw-bold'>
                                                {group.doctorDetails.titleArm}
                                              </p>
                                              <p
                                                className='caption2-200 med-card-diagnos-block-title my-3'>
                                                {group.doctorDetails.profession.titleArm}
                                              </p>
                                            
                                            {
                                              group.labServices.map((service, innerIndex) => {
                                                return <LabOneService
                                                  lab={null}
                                                  key={`${service.id}_${innerIndex}`}
                                                  service={service.labService}
                                                  onClick={() => false}
                                                  selectedServices={[]}
                                                  withoutAction
                                                  
                                                />
                                              }
                                            )}
                                          </div>
                                        </div>
                                  })
                          }
                            </div>
                    })
                  } 
              </>
            }
        </div>
    );
}

export default PatientLabServices;