import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  text: ''
};

const tempTemplateSlice = createSlice({
  name: 'tempTemplate',
  initialState,
  reducers: {
    setTempTemplate(state, action) {
      state.text = action.payload
    },
    resetTempTemplate(state) {
      state.text = ''
    }
  }
});

export const { setTempTemplate, resetTempTemplate } = tempTemplateSlice.actions;
export default tempTemplateSlice.reducer;
