import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setHeaderTitle } from 'features/headerTitle';

import LabMaterialsAndTypes from 'components/LabMaterialsAndTypes';

const LabMaterialAndType = () => {
 
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setHeaderTitle('Հետազոտություն'));
  }, []);
 
  return (
    <div className='mt-100'>
      <LabMaterialsAndTypes />
    </div>
  );
}

export default LabMaterialAndType;
