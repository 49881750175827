import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getDoctorProfilePatients } from "services/api";

import { setDoctorPatient } from "features/doctorPatient";

import FilterTabs from "components/FilterTabs";
import FilterSearch from "components/FilterSearch";
import CreateService from "components/CreateService";

import PatientExample from "assets/patientExample.png";
import corona from 'assets/DoctorPage/corona-virus.svg';

import './component.css';

const DoctorPatients = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { items } = useSelector(state => state.doctorPatients);

    const [ filterTabs, setFilterTabs ] = useState([]);
    const [ filterItem, setFilterItem ] = useState('');
    const [ activeFilter, setActiveFilter ] = useState(items && items[0] && items[0].statusTitle);

    const handleSetActive = (active) => {
      setActiveFilter(_ => active);
    };

    const handleFilter = (searchText) => {
      setFilterItem(_ => searchText);
    };

    const handlePatientClick = (patient) => {
      dispatch(setDoctorPatient(patient));
      navigate('/profile/onePatient')
    }

    useEffect(() => {
        if (!items.length) {
            getDoctorProfilePatients(dispatch)
            .catch(e => {
              console.log(e);
            });
        } else if (items && items.length) {
          const tabs = items.map(item => item.statusTitle);
          setFilterTabs(_ => tabs);
        } else {
          // navigate('/doctorAccount')
        }
    },[items]);

    return (
        <div className="mt-100">
         <CreateService
            withoutNew
            name='Իմ Հիվանդներ'
            icon={corona}
          />
       
           <FilterSearch
              filterCall={handleFilter}
          />
          {filterTabs && filterTabs.length > 0 && 
              <FilterTabs
                  tabs={filterTabs}
                  checkActive={handleSetActive}
              />
          }

          {
            items && items.length > 0 && items.filter(status => status.statusTitle === activeFilter).map((status, index) => {
              let finalPatients = status.patients;
              
              if (filterItem !== '') {
                finalPatients = status.patients.filter(patient => patient.titleArm.includes(filterItem) || 
                  (patient.ssn && patient.ssn.includes(filterItem))
                )
              }

              return <div className="row" key={`${status.statusTitle}_${index}`}>
                  {
                    finalPatients.map((patient, patientIndex) => {
                          return <div className="col-6" onClick={() => handlePatientClick(patient)}  key={`${patient.titleArm}_${patientIndex}`}>
                                    <div className="doctorPatient">
                                      <img className="doctorPatientImg" src={PatientExample} alt={patient.titleArm} />
                                      <p className="caption1-400">{patient.titleArm}</p>
                                      {patient.ssn && <p className="caption2-300 text-green">Հծհ {patient.ssn}</p>}
                                  </div>
                                </div>
                          })
                  }
                    </div>
            })
          } 
        </div>
    );
}

export default DoctorPatients;
