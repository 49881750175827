import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: []
};

const patientIndicatorData = createSlice({
  name: 'patientIndicatorData',
  initialState,
  reducers: {
    setPatientIndicatorData(state, action) {
      state.items = action.payload
    },
    resetPatientIndicatorData(state) {
      state.items = []
    }
  }
});

export const { setPatientIndicatorData, resetPatientIndicatorData } = patientIndicatorData.actions;
export default patientIndicatorData.reducer;

