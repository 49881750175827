import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setLoader, resetLoader } from 'features/loader';
import { axiosInstance, axiosLogin } from 'services/axios';
import { getCookie, clearCookies, refreshToken } from 'utils';

import Loader from 'components/Loader';

const Loading = () => {
  const refreshAndRetryQueue = [];

  const dispatch = useDispatch();
  const { item } = useSelector(state => state.loader);
  
  useEffect(() => {
    let isRefreshing = false;
    axiosInstance.interceptors.request.use(
      async config => {
        dispatch(setLoader());
        return config;
      },
      error => {
        Promise.reject(error)
      });
    axiosInstance.interceptors.response.use((response) => {
      dispatch(resetLoader());
      return response
    }, async (error) => {
      const originalRequest = error.config;
      if (error.response && error.response.status === 401 && !originalRequest._retry) {
        if (!isRefreshing) {
          isRefreshing = true;
          originalRequest._retry = true;
          try {
            const isSuccess = await refreshToken('token', axiosLogin)
            if (isSuccess) {
              const newAccessToken = getCookie('access_token');

              axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
              originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

              refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
                axiosInstance
                  .request(config)
                  .then((response) => resolve(response))
                  .catch((err) => reject(err));
              });

              refreshAndRetryQueue.length = 0;

              return axiosInstance(originalRequest);
            } else {
              throw new Error();
            }
          } catch (refreshError) {
            refreshAndRetryQueue.forEach(({ reject }) => reject(refreshError));
            refreshAndRetryQueue.length = 0;

            clearCookies();
            dispatch(resetLoader());
            window.location.href = '/login';

            throw refreshError;
          } finally {
            dispatch(resetLoader());
            isRefreshing = false;
          }
        }

        return new Promise((resolve, reject) => {
          refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
        });
      }

      dispatch(resetLoader());
      return Promise.reject(error);
    });
  }, []);

  return (
    item ? <Loader /> : ''
  )
}

export default Loading
