import React, { useState } from 'react';
import Carousel from 'react-multi-carousel-18';
import { Col, Row, Form, InputGroup } from 'react-bootstrap';

import {
  getIllnessesReq,
  getComplaintsReq,
  getWorkplacesBySearch,
  getDepartmentsBySearch,
  getWorkplacesWithDoctorsBySearch,
} from 'services/api';

import SearchBlock from './SearchBlock';
import DepItem from 'components/NewDepartments/DepItem';
import WorkplaceCard from 'components/WorkplaceCard';
import WorkplaceWithDoctors from 'components/WorkplaceWithDoctors';

import bk from 'assets/SearchBar/bk.png';
import search from 'assets/Header/Ellipse.png';
import remove from 'assets/SearchBar/Remove.png';
import doctor from 'assets/SearchBar/doctor.png';
import illness from 'assets/SearchBar/illness.png';
import bkActive from 'assets/SearchBar/bkActive.png';
import complaint from 'assets/SearchBar/complaint.png';
import removeBlack from 'assets/SearchBar/removeBlack.png';
import doctorActive from 'assets/SearchBar/doctorActive.png';
import illnessActive from 'assets/SearchBar/illnessActive.png';
import complaintActive from 'assets/SearchBar/complaintActive.png';

const DocSearch = () => {
  const [ illnesses, setIllnesses ] = useState([]);
  const [ complaints, setComplaints ] = useState([]);
  const [ workplaces, setWorkplaces ] = useState([]);
  const [ departments, setDepartments ] = useState([]);
  const [ searchString, setSearchString ] = useState('');
  const [ searchCategory, setSearchCategory ] = useState('bk');
  const [ showEmptyResult, setShowEmptyResult ] = useState(false);
  const [ selectedIllnesses, setSelectedIllnesses ] = useState([]);
  const [ selectedComplaints, setSelectedComplaints ] = useState([]);
  const [ showAutoEmptyResult, setShowAutoEmptyResult ] = useState(false);
  const [ workplacesWithDoctors, setWorkplacesWithDoctors ] = useState([]);

  const responsive = {
      superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: {max: 4000, min: 3000},
          items: 5
      },
      desktop: {
          breakpoint: {max: 3000, min: 1024},
          items: 3
      },
      tablet: {
          breakpoint: {max: 1024, min: 464},
          items: 3
      },
      mobile: {
          breakpoint: {max: 464, min: 0},
          items: 3
      }
  };

  const handleCategoryChange = (category) => {
      setSearchCategory(category);
      resetResults();
      setSearchString('');
  }

  const resetResults = () => {
      setWorkplaces([]);
      setWorkplacesWithDoctors([]);
      setComplaints([]);
      setSelectedComplaints([]);
      setDepartments([]);
      setIllnesses([]);
      setSelectedIllnesses([]);
      setDepartments([]);
      setShowEmptyResult(false);
      setShowAutoEmptyResult(false);
  }

  const helpText = () => {
      switch(searchCategory) {
          case 'bk': 
              return 'Խնդրում ենք մուտքագրել Բժշկական կենտրոնի անվանումը։';
          case 'doctor': 
              return 'Խնդրում ենք մուտքագրել Բժշկի տվյալները: ';
          case 'complaint': 
              return 'Խնդրում ենք մուտքագրել գանգատը:';
          case 'illness': 
              return 'Խնդրում ենք մուտքագրել հիվանդություն անվանումը';
          default:
              return '';    
      }
  }

  const getWorkplaces = () => {
    getWorkplacesBySearch(searchString)
    .then(res => {
      setWorkplaces(res);
      if (res.length === 0) {
        setShowEmptyResult(true);
      }
    })
    .catch(e => {
      console.log(e);
    });
  }

  const getWorkplacesWithDoctors = () => {
    getWorkplacesWithDoctorsBySearch(searchString)
    .then(res => {
      setWorkplacesWithDoctors(res);
      if (res.length === 0) {
        setShowEmptyResult(true);
      }
    })
    .catch(e => {
      console.log(e);
    })
  }

  const getDepartments = (complaints, illnesses) => {
    let complaintIds = [];
    let illnessesIds = [];
    
    if (complaints) {
      complaints.forEach(complaint => {
        complaintIds.push(complaint.id);
      });
    }
    
    if (illnesses) {
      illnesses.forEach(illness => {
        illnessesIds.push(illness.id);
      });
    }
      
    getDepartmentsBySearch(complaintIds, illnessesIds)
    .then(res => {
      setDepartments(res);
    })
    .catch(e => {
      console.log(e);
    });
  }

  const handleSearchClick = () => {
    if (searchString !== '') {
      switch(searchCategory) {
        case 'bk': 
          getWorkplaces();
          break;
        case 'doctor': 
          getWorkplacesWithDoctors();
          break;
        default:
          return '';
      }
    }
  }

  const renderWorkplaces = () => {
      return workplaces.map((workplace, index) =>
          <WorkplaceCard
              key={workplace.id}
              workplace={workplace}
              classes={index % 2 === 0 ? 'col-pd-r-7 mb16' : 'col-pd-l-7 mb16'}
          />
      )
  }

  const renderWorkplacesWithDoctors = () => {
      return workplacesWithDoctors.map(workplace =>
          <WorkplaceWithDoctors
              key={workplace.id}
              workplace={workplace}
              departmentSlug={null}
          />
      );
  }

  const renderDepartments = () => {
      let mappedDepartments = departments.map((department, index) =>
          <DepItem
              classes={index % 2 === 0 ? 'col-pd-r-7 mb16' : 'col-pd-l-7 mb16'}
              key={department.id}
              department={department}
              inWorkplacePage={false}
              workplaceSlug={null}
          />
      );

      return mappedDepartments;
  }

  const handleRemoveClick = () => {
      setSearchString('');
      resetResults();
  }

  const handleSearchTyping = (value) => {
    setSearchString(value);

    if (searchCategory === 'complaint') {
      if (value.length >= 3) {
        getComplaints(value);
      } else {
        setComplaints([]);
        setShowAutoEmptyResult(false);
      }
    }

    if (searchCategory === 'illness') {
      if (value.length >= 3) {
        getIllnesses(value);
      } else {
        setIllnesses([]);
        setShowAutoEmptyResult(false);
      }
    }
  }

  const getComplaints = (value) => {
    getComplaintsReq(value)
    .then(res => {
      setComplaints(res);
      if (res.length === 0) {
        setShowAutoEmptyResult(true);
      }
    })
    .catch(e => {
      console.log(e);
    });
  }

  const getIllnesses = (value) => {
    getIllnessesReq(value)
    .then(res => {
      setIllnesses(res);

      if (res.length === 0) {
        setShowAutoEmptyResult(true);
      }
    })
    .catch(e => {
      console.log(e);
    });
  }

  const handleComplaintSelect = (complaint) => {
      setSearchString('');
      setComplaints([]);
      let selComplaints = [...selectedComplaints];

      selComplaints.push(complaint);
      setSelectedComplaints(selComplaints);
      getDepartments(selComplaints);
  };

  const handleComplaintRemove = (complaint) => {
      let selComplaints = selectedComplaints.filter(item => item !== complaint);
      setSelectedComplaints(selComplaints);
      getDepartments(selComplaints);
  };

  const renderSelectedComplaints = () => {
      return selectedComplaints.map(complaint => 
          <span key={complaint.id} className='selectedFilters'>
              {complaint.titleArm}
              <img src={removeBlack} className='removeBlack' alt='removeBlack' onClick={() => handleComplaintRemove(complaint)}  />
          </span>
      )
  }  

  const handleIllnessSelect = (illness) => {
      setSearchString('');
      setIllnesses([]);
      let selIllnesses = [...selectedIllnesses];

      selIllnesses.push(illness);
      setSelectedIllnesses(selIllnesses);
      getDepartments(null, selIllnesses);
  };

  const handleIllnessRemove = (illness) => {
      let selIllnesses = selectedIllnesses.filter(item => item !== illness);
      setSelectedIllnesses(selIllnesses);
      getDepartments(selIllnesses);
  };

  const renderSelectedIllnesses = () => {
      return selectedIllnesses.map(illness => 
          <span key={illness.id} className='selectedFilters'>
              {illness.titleArm}
              <img src={removeBlack} className='removeBlack' alt='removeBlack' onClick={() => handleIllnessRemove(illness)}  />
          </span>
      )
  }

  return (
    <Row>
      <Col xs={10} className={`pr0`}>
        <div className='detailsBlock'>
          <InputGroup className='searchInputGroup'>
              <Form.Control value={searchString} placeholder='Որոնել' className='border-none pl0' onChange={(e) => handleSearchTyping(e.target.value)} />
              <img className='removeIcon' src={remove} alt='remove' onClick={() => handleRemoveClick()}/>
          </InputGroup>
          {
            searchCategory === 'complaint' && <ul className='autocompleteUl'>
              {
                complaints.map((item) => (
                  <li key={item.id} onClick={() => handleComplaintSelect(item)}>
                    {item.titleArm}
                  </li>
                ))
              }
              {showAutoEmptyResult && <li>Արդյունքներ չեն հայտնաբերվել</li>}
            </ul>
          }
          {
            searchCategory === 'illness' && <ul className='autocompleteUl'>
              {
                illnesses.map((item) => (
                  <li key={item.id} onClick={() => handleIllnessSelect(item)}>
                    {item.titleArm}
                  </li>
                ))
              }
              {showAutoEmptyResult && <li>Արդյունքներ չեն հայտնաբերվել</li>}
            </ul>
          }
        </div>
      </Col>
      <Col xs={2}>
        <img className='searchIcon mt0' src={search} alt='search' onClick={() => handleSearchClick()}/>
      </Col>
      <Col xs={12} className={`mt36`}>
        <Carousel
          arrows={false}
          showDots={false}
          responsive={responsive}
          className='searchCarousel'
        >
          <SearchBlock
            title={`ԲԿ`}
            onClick={() => handleCategoryChange('bk')}
            img={searchCategory === 'bk' ? bkActive : bk}
            classes={`mb40 ${searchCategory === 'bk' && 'active'}`}
          />
          <SearchBlock
            title={`Բժիշկ`}
            onClick={() => handleCategoryChange('doctor')}
            img={searchCategory === 'doctor' ? doctorActive : doctor}
            classes={`mb40 ${searchCategory === 'doctor' && 'active'}`}
          />
          <SearchBlock
            title={`Գանգատ`}
            onClick={() => handleCategoryChange('complaint')}
            classes={`mb40 ${searchCategory === 'complaint' && 'active'}`}
            img={searchCategory === 'complaint' ? complaintActive : complaint}
          />
          <SearchBlock
            title={`Հիվանդություն`}
            onClick={() => handleCategoryChange('illness')}
            classes={`mb40 ${searchCategory === 'illness' && 'active'}`}
            img={searchCategory === 'illness' ? illnessActive : illness}
          />
        </Carousel>
      </Col>
      <Col xs={12}>
        {
          searchString === '' &&
          selectedComplaints.length === 0 &&
          selectedIllnesses.length === 0 &&
          <p className='f-s-12 text-black'>{helpText()}</p>
        }
      </Col>
      <Col xs={12} className='mb-5'>
        {renderSelectedComplaints()}
        {renderSelectedIllnesses()}
      </Col>
      {renderWorkplaces()}
      {showEmptyResult && 
        <p>Արդյունքներ չեն հայտնաբերվել</p>
      }
      
      <Col xs={12}>
          {renderWorkplacesWithDoctors()}
      </Col>
      {
        departments.length > 0 && <Col xs={12} className='depTitleCol'>
          <h2 className='title'>Բաժանմունքներ</h2>
        </Col>
      }
      {renderDepartments()}
    </Row>
  );
}

export default DocSearch;
