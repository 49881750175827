import React, { useState } from "react";
import PropTypes from 'prop-types';
import Carousel from "react-multi-carousel-18";

import { setLabBookmarked, setWorkplaceBookmarked } from "services/api";
import { getCarouselResponsive } from "utils";

import WorkplaceSocialBlock from "components/WorkplaceSocialBlock";

import './component.css';


const responsive = getCarouselResponsive();

const WorkplaceHeaderInfo = ({workplace, withAddress = true, workplaceType = 'workplace'}) => {
    const BaseDomain = `${process.env.REACT_APP_BASE_DOMAIN}`;
    const [bookmarked, setBookmarked] = useState(false);

    const setBookmarkedFunction = (bookmark) => {
        setBookmarked(bookmark);
    
        if (workplaceType === 'lab') {
          const params = {
            labSlug: workplace.slug,
            bookmarked: bookmark
          };
          setLabBookmarked(params)
          .then(res => {
            // TODO: implement
          })
          .catch(e => {
            console.log(e);
          });
        } else {
          const params = {
            workplaceSlug: workplace.slug,
            bookmarked: bookmark
          };
          setWorkplaceBookmarked(params)
          .then(res => {
            // TODO: implement
          })
          .catch(e => {
            console.log(e);
          });
        }
      }
      
      const handleShare = async () => {
          if (navigator.share) {
          try {
              await navigator.share({
              title: 'Doctor Page',
              text: 'Check out this doctor!',
              url: window.location.href,
              });
              console.log('Successfully shared');
          } catch (error) {
              console.error('Error sharing:', error);
          }
          } else {
          console.warn('Web Share API not supported');
          }
      }

    return (
        <div className='row workplaceHeaderInfo mt-100'>
            <div className={`col-8 offset-2`}>
                <div className='headerInfo'>
                    <Carousel
                        arrows={false}
                        showDots={true}
                        responsive={responsive}
                        renderDotsOutside={true}
                        dotListClass={`bannerDots`}
                    >
                    <div className='bannerImgDiv text-center'>
                        <img src={BaseDomain + workplace.imagePath} className='img-fluid' alt='banner'/>
                    </div>
                    <div className='bannerImgDiv text-center'>
                        <img src={BaseDomain + workplace.imagePath} className='img-fluid' alt='banner'/>
                    </div>
                    <div className='bannerImgDiv text-center'>
                        <img src={BaseDomain + workplace.imagePath} className='img-fluid' alt='banner'/>
                    </div>
                    </Carousel>
                </div>
            </div>
            <div className={`col-2 position-r`}>
                {
                    bookmarked ?
                    <svg className='mt-4' onClick={() => setBookmarkedFunction(false)} xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 16 21' fill='none'>
                        <path d='M1.5 3C1.5 1.89543 2.39543 1 3.5 1H12.5C13.6046 1 14.5 1.89543 14.5 3V18.4535C14.5 19.3623 13.3856 19.7999 12.7672 19.1339L8.73279 14.7892C8.33716 14.3631 7.66284 14.3631 7.26721 14.7892L3.23279 19.1339C2.61435 19.7999 1.5 19.3623 1.5 18.4535V3Z' fill='#218CC0' stroke='#218CC0' strokeWidth='2'/>
                    </svg> :
                    <svg className='mt-4' onClick={() => setBookmarkedFunction(true)} xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                        <path d='M5.4 5C5.4 3.8402 6.3402 2.9 7.5 2.9H16.5C17.6598 2.9 18.6 3.8402 18.6 5V20.5482C18.6 21.0728 17.9741 21.3448 17.5906 20.9868L12.9552 16.6605C12.4174 16.1584 11.5826 16.1584 11.0448 16.6605L6.40939 20.9868C6.02586 21.3448 5.4 21.0728 5.4 20.5482V5Z' stroke='#218CC0' strokeWidth='1.8'/>
                    </svg>
                }
                <svg onClick={() => handleShare()} className='share' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                    <path d='M14.151 6.62129L14.2972 6.53775L14.2748 6.37087C14.2584 6.24968 14.25 6.12588 14.25 6C14.25 4.48122 15.4812 3.25 17 3.25C18.5188 3.25 19.75 4.48122 19.75 6C19.75 7.51878 18.5188 8.75 17 8.75C16.2702 8.75 15.6073 8.46605 15.1148 8.00213L14.9801 7.8752L14.8194 7.96704L8.84896 11.3786L8.70276 11.4622L8.72522 11.6291C8.74155 11.7503 8.75 11.8741 8.75 12C8.75 12.1437 8.73899 12.2848 8.71782 12.4222L8.69441 12.5743L8.8196 12.6637L14.3223 16.5942L14.5368 16.7475L14.6785 16.5251C15.167 15.758 16.0243 15.25 17 15.25C18.5188 15.25 19.75 16.4812 19.75 18C19.75 19.5188 18.5188 20.75 17 20.75C15.6539 20.75 14.5332 19.7826 14.2962 18.505L14.2778 18.4058L14.1957 18.3472L8.14978 14.0287L7.98657 13.9121L7.83738 14.0461C7.35018 14.4839 6.70645 14.75 6 14.75C4.48122 14.75 3.25 13.5188 3.25 12C3.25 10.4812 4.48122 9.25 6 9.25C6.72978 9.25 7.3927 9.53394 7.88519 9.99786L8.01993 10.1248L8.18064 10.0329L14.151 6.62129ZM15.75 18.0039L15.75 18.0039L15.75 18.0106C15.7558 18.6961 16.3131 19.25 17 19.25C17.6904 19.25 18.25 18.6904 18.25 18C18.25 17.3096 17.6904 16.75 17 16.75C16.3129 16.75 15.7551 17.3045 15.75 17.9905L15.7499 17.9905L15.75 17.9971L15.75 18.0039ZM17 7.25C17.6904 7.25 18.25 6.69035 18.25 6C18.25 5.30965 17.6904 4.75 17 4.75C16.3096 4.75 15.75 5.30965 15.75 6C15.75 6.69035 16.3096 7.25 17 7.25ZM6 13.25C6.69035 13.25 7.25 12.6904 7.25 12C7.25 11.3096 6.69035 10.75 6 10.75C5.30965 10.75 4.75 11.3096 4.75 12C4.75 12.6904 5.30965 13.25 6 13.25Z' fill='#218CC0' stroke='#218CC0' strokeWidth='0.5'/>
                </svg>
            </div>
            {
            withAddress && <>
                <div className="col-12">
                    <h1 className='f-s-16 text-center mt-25'>{workplace.titleArm}</h1>
                    <p className='mb-25 mt-25'>
                        {/* <img src={icon} className='iconsWorkPlaceView' alt='addressIcon'/> */}
                        <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
                        <path fillRule='evenodd' clipRule='evenodd' d='M6.98695 4.93691C6.33653 5.74783 6.00039 6.86838 6.00039 8.03636C6.00039 10.4138 7.09434 12.539 8.24551 14.1087C8.81631 14.887 9.38799 15.5108 9.81607 15.9389C9.88111 16.0039 9.94271 16.0643 10.0004 16.1199C10.0581 16.0643 10.1197 16.0039 10.1847 15.9389C10.6128 15.5108 11.1845 14.887 11.7553 14.1087C12.9065 12.539 14.0004 10.4138 14.0004 8.03636C14.0004 6.86838 13.6642 5.74783 13.0138 4.93691C12.3819 4.14909 11.4138 3.6 10.0004 3.6C8.58695 3.6 7.61885 4.14909 6.98695 4.93691ZM10.0004 17.2C9.49383 17.8192 9.49359 17.819 9.49335 17.8188L9.49135 17.8171L9.48711 17.8137L9.47359 17.8024C9.46231 17.793 9.44663 17.7798 9.42679 17.7628C9.38703 17.7289 9.33079 17.68 9.26023 17.6168C9.11927 17.4905 8.92103 17.3066 8.68471 17.0702C8.21281 16.5983 7.5845 15.913 6.95526 15.0549C5.70644 13.3519 4.40039 10.8953 4.40039 8.03636C4.40039 6.58617 4.81425 5.08853 5.73883 3.93582C6.68193 2.76001 8.1138 2 10.0004 2C11.887 2 13.3189 2.76001 14.2619 3.93582C15.1866 5.08853 15.6004 6.58617 15.6004 8.03636C15.6004 10.8953 14.2943 13.3519 13.0455 15.0549C12.4163 15.913 11.788 16.5983 11.3161 17.0702C11.0798 17.3066 10.8815 17.4905 10.7406 17.6168C10.67 17.68 10.6138 17.7289 10.574 17.7628C10.5542 17.7798 10.5385 17.793 10.5272 17.8024L10.5137 17.8137L10.5094 17.8171L10.508 17.8183C10.5078 17.8186 10.507 17.8192 10.0004 17.2ZM10.0004 17.2L10.507 17.8192C10.2123 18.0602 9.78807 18.0599 9.49335 17.8188L10.0004 17.2Z' fill='#218CC0'/>
                        <path fillRule='evenodd' clipRule='evenodd' d='M10.0006 6.8002C9.55875 6.8002 9.20059 7.15837 9.20059 7.6002C9.20059 8.04202 9.55875 8.4002 10.0006 8.4002C10.4424 8.4002 10.8006 8.04202 10.8006 7.6002C10.8006 7.15837 10.4424 6.8002 10.0006 6.8002ZM7.60059 7.6002C7.60059 6.27472 8.67507 5.2002 10.0006 5.2002C11.3261 5.2002 12.4006 6.27472 12.4006 7.6002C12.4006 8.92572 11.3261 10.0002 10.0006 10.0002C8.67507 10.0002 7.60059 8.92572 7.60059 7.6002Z' fill='#218CC0'/>
                        </svg>
                        <span className='f-s-11 ml-11 text-black'>{workplace.addressArm}</span>
                    </p>
        
                    <p>
                        {/* <img src={phone} className='iconsWorkPlaceView' alt='phoneIcon'/> */}
                        <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
                        <path d='M13.0634 13.345L12.5685 14.0873C12.4698 14.2355 12.3391 14.3619 12.1669 14.4074C11.603 14.5564 10.1058 14.6237 7.8264 12.3443C5.54698 10.0649 5.61426 8.5677 5.76327 8.00376C5.80877 7.83159 5.93519 7.70095 6.08336 7.60217L6.82568 7.1073C7.37835 6.73885 7.5277 5.99213 7.15925 5.43946L5.75726 3.33647C5.43566 2.85407 4.81568 2.66977 4.28279 2.89815L3.65034 3.1692C3.1699 3.3751 2.77799 3.74463 2.54423 4.21216C2.34893 4.60275 2.19756 5.01408 2.17705 5.4503C2.11242 6.825 2.41386 10.2119 6.18636 13.9844C9.95887 17.7569 13.3457 18.0583 14.7204 17.9937C15.1566 17.9731 15.5679 17.8218 15.9585 17.6265C16.4261 17.3927 16.7956 17.0008 17.0015 16.5204L17.2726 15.8879C17.5009 15.355 17.3166 14.735 16.8342 14.4134L14.7312 13.0115C14.1786 12.643 13.4319 12.7924 13.0634 13.345Z' stroke='#218CC0' strokeWidth='1.8'/>
                        </svg>
                        <span className='f-s-11 ml-11 text-black'>{workplace.phone}</span>
                    </p>
                </div>
                <WorkplaceSocialBlock workplace={workplace} />
            </>
            }
      </div>
    );
}

WorkplaceHeaderInfo.propTypes = {
    workPlace: PropTypes.object,
    withAddress: PropTypes.bool,
    workplaceType: PropTypes.string
}


export default WorkplaceHeaderInfo;