import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getObjectiveIndicatorForms } from 'services/api';

import FilterTabs from 'components/FilterTabs';
import FilterSearch from 'components/FilterSearch';
import DynamicForms from 'components/DynamicForms';

import './component.css';

const ObjectiveIndicators = ({ fromPatientPage }) => {
  const dispatch = useDispatch();
  const { items } = useSelector(state => state.objectiveIndicators);
  const { indicatorFormValues, indicatorFormValuesByStatuses } = useSelector(state => state.oneMedCard.item);

  const [ filter, setFilter ] = useState([]);
  const [ activeFilter, setActiveFilter ] = useState('');

  const handleActiveFilter = (active) => {
    setActiveFilter(_ => active);
  };

  useEffect(() => {
    if (items && !items.length) {
      getObjectiveIndicatorForms(dispatch);
    }
  }, [items, dispatch]);

  useEffect(() => {
    const dynamicFilters = indicatorFormValuesByStatuses && indicatorFormValuesByStatuses.length > 0 && 
      indicatorFormValuesByStatuses.map(item => item.titleArm);
      dynamicFilters && dynamicFilters.length && setFilter(() => {
        return [
          ...dynamicFilters
        ]
      });
    dynamicFilters && dynamicFilters.length && setActiveFilter(() => {
      return dynamicFilters[0]
    })
  }, [indicatorFormValuesByStatuses]);

  return (
    <div className='objective-indicators-block'>
      {/* TODO: <FilterSearch filterCall={handleFilter} /> */}

      <FilterTabs
        tabs={filter}
        active={activeFilter}
        checkActive={handleActiveFilter}
      />

      {
        indicatorFormValuesByStatuses && indicatorFormValuesByStatuses.length > 0 &&
          indicatorFormValuesByStatuses.filter(item => item.titleArm === activeFilter).map((item, index) => {
            return <DynamicForms
              item={item}
              items={items}
              filterText={activeFilter}
              key={`${item.titleArm}_${index}`}
              fromPatientPage={fromPatientPage}
            />
          })
      }
    </div>
  )
};

export default ObjectiveIndicators
