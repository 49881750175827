import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import DepItem from './DepItem';
import Button from 'components/Button';
import DoctorsInfo from 'components/DoctorsInfo';

import './component.css';
import { getDepartments } from 'services/api';
import { useNavigate } from 'react-router-dom';
import { setHeaderTitle } from 'features/headerTitle';

const NewDepartments = ({ 
  showMainTitle = false,
  useAsLink = false, 
  link = '',
  withDoctorsView = false,
  moreWithNewPage = false, 
  showAll = false, 
  workplace = null,
  onClickDoctor,
  useDoctorAsLink = true
 }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { items, itemsPart } = useSelector(state => state.departments);

  const [ more, setMore ] = useState(false);
  const [ activeList, setActiveList ] = useState([]);
  const [ innerItems, setInnerItems ] = useState([]);

  const handleMore = () => {
    if (moreWithNewPage) {
      dispatch(setHeaderTitle('Բաժանմունքներ'))
      navigate('/departments')
    } else {
        setMore(prev => {
      if (prev) {
        setInnerItems(_ => [...itemsPart]);
      } else {
        setInnerItems(_ => [...items]);
      }
      return !prev
    });
    }
  
  };

  const handleSetActive = (active, key) => {
    if (key === 'Active') {
      setActiveList(prev => {
        return [
          ...prev,
          active
        ]
      })
    } else {
      const newActives = activeList.filter(item => item !== active);
      setActiveList(_ => {
        return [...newActives]
      })
    }
  };

  useEffect(() => {
    if(!workplace) {
       getDepartments(dispatch).then(() => {
      })
      .catch(e => {
        console.log(e);
      });
    }
  }, []);

  
  useEffect(() => {
    if (showAll) {
      setInnerItems(_ => [...items])
    } else {
      setInnerItems(_ => [...itemsPart]);
    }
   
  }, [items, itemsPart]);

  

  return (
    <div className='new-departments-block'>
      {
        showMainTitle && 
        <div className='w-100'>
          <p className='body1-300-title'>Բաժանմունքներ</p>
        </div>      
      }
      {
        innerItems && innerItems.length > 0 && innerItems.map((item, index) => {
          let finalLink = link + '/' + item.slug;

          if (workplace) {
            finalLink += '/' + workplace.slug;
          }

          return (
            <DepItem
              item={item}
              handleSetActive={handleSetActive}
              key={`${item.titleArm}_${index}`}
              useAsLink={useAsLink}
              link={finalLink}
            />
          )
        })
      }

      {!showAll && innerItems.length > 0 && <div className='card-services-more-block'>
        <Button
          click={handleMore}
          text={more ? 'Պակաս' : 'Ավելին'}
        />
      </div>}
      

      
      {withDoctorsView &&
          <div className='card-services-doctors-block'>
          {
            innerItems && innerItems.length && (
              activeList && activeList.length ? innerItems.filter(filterItem => activeList.includes(filterItem.titleArm)).map((item, index) => {
                return (
                  <div className='card-services-doctors-list' key={`${item.titleArm}_${index}`}>
                    <p className='body2-300-title card-services-doctors-list-title'>{item.titleArm}</p>
    
                    <div className='card-services-doctors-list-horizontal-block'>
                      {
                        item.doctors && item.doctors.length && item.doctors.map((doctorItem, doctorIndex) => {
                          return <DoctorsInfo
                            useAsLink={useDoctorAsLink}
                            onClick={onClickDoctor}
                            doctor={doctorItem}
                            servicesLink='/addServicesToMedCard'
                            key={`${doctorItem.slug}_${doctorIndex}`}
                          />
                        }) 
                      }
                    </div>
                  </div>
                )
              })
              : innerItems.map((item, index) => {
                return (
                  <div className='card-services-doctors-list' key={`${item.titleArm}_${index}`}>
                    <p className='body2-300-title card-services-doctors-list-title'>{item.titleArm}</p>

                    <div className='card-services-doctors-list-horizontal-block'>
                      {
                        item.doctors && item.doctors.length && item.doctors.map((doctorItem, doctorIndex) => {
                          return <DoctorsInfo
                            useAsLink={useDoctorAsLink}
                            onClick={onClickDoctor}
                            doctor={doctorItem}
                            servicesLink='/addServicesToMedCard'
                            key={`${doctorItem.slug}_${doctorIndex}`}
                          />
                        }) 
                      }
                    </div>
                  </div>
                )
              })
            )
          }
         </div>
      }
       
    </div>
  )
}

NewDepartments.propTypes = {
  useAsLink: PropTypes.bool,
  link: PropTypes.string,
  linkHasSlug: PropTypes.bool,
  withDoctorsView: PropTypes.bool,
  moreWithNewPage: PropTypes.bool,
  showAll: PropTypes.bool,
  workplace: PropTypes.object,
  useDoctorAsLink: PropTypes.bool,

};

export default NewDepartments
