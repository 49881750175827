import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  item: ''
};

const historiesTemplateTypeSlice = createSlice({
  name: 'historiesTemplateType',
  initialState,
  reducers: {
    setHistoriesTemplateType(state, action) {
      state.item = action.payload
    },
    resetHistoriesTemplateType(state) {
      state.item = ''
    }
  }
});

export const { setHistoriesTemplateType, resetHistoriesTemplateType } = historiesTemplateTypeSlice.actions;
export default historiesTemplateTypeSlice.reducer;
