import React, {useEffect, useState} from 'react';
import { Row } from 'react-bootstrap';

import { getPharmsByCategories } from 'services/api';

import CatsWithWorkplaces from 'components/CatsWithWorkplaces';

const CatsWithPharms = ({
  workplaceType = 'pharm',
  categoryType = 'pharms'
}) => {
  const [ categories, setCategories ] = useState([]);

  useEffect(() => {
    if (!categories.length) {
      getPharmsByCategories()
      .then(res => {
        setCategories(res);
      })
      .catch(e => {
        console.log(e);
      });
    }
    return () => {
      setCategories([]);
    }
  }, []);

  return (
    <Row>
      <CatsWithWorkplaces />
    </Row>
  );
}

export default CatsWithPharms;
