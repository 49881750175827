import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import './component.css';

const FilterTabs = ({
  tabs,
  active,
  checkActive
}) => {
  const ref = useRef(null);
  const [ activeFilter, setActiveFilter ] = useState(tabs && tabs[0] && tabs[0]);

  const handleSetActive = (active) => {
    setActiveFilter(_ => active);
    checkActive(active);
  };

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.click();
    }
  }, [tabs]);

  useEffect(() => {
    if (active) {
      handleSetActive(active);
    }
  }, [active]);


  return (
    <div className='filter-tabs-block'>
      {
        tabs && tabs.length > 0 && tabs.map((item, index) => {
          return <p
            ref={item === 0 ? ref : null}
            key={`${item}_${index}`}
            onClick={_ => handleSetActive(item)}
            className={
              `
              filter-tabs-item
              ${activeFilter === item ?
                'active-filter-tabs caption2-400' :
                'caption2-100'
              }
            `
            }
          >
            { item }
          </p>
        })
      }
    </div>
  )
};

FilterTabs.propTypes = {
  active: PropTypes.string,
  tabs: PropTypes.array.isRequired,
  checkActive: PropTypes.func.isRequired
};

export default FilterTabs
