import React from 'react';
import PropTypes from 'prop-types';

import { handleGetDate } from 'utils';

import Button from 'components/Button';

import cardLocation from 'assets/ApplicationsCard/location.svg';

import './component.css';

const ApplicationsCard = ({
  item,
  cancelText,
  callCancel,
  callSuccess,
  successText,
  patientView,
  categoryName,
  withoutButtons,
}) => {

  const handleCancel = () => {
    callCancel(item, categoryName);
  };

  const handleSuccess = () => {
    callSuccess(item, categoryName);
  };

  const handleGetTime = (date) => {
    const t = new Date(date);
    let hours = t.getUTCHours();
    let minutes = t.getUTCMinutes();

    if (hours < 10) {
      hours = '0' + hours;
    }

    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    const newTime = hours + ':' + minutes;
    return newTime;
  };

  return (
    <div className='applications-card-block'>

      <div className='applications-card-item'>
        <div className='applications-card-item-child'>
          <span className='applications-card-type-text caption3-200'>{/* type */}Տնային այց 21</span>
        </div>

        <div className='applications-card-item-child'>
          <p className='applications-card-pacient-name caption1-300'>
            {/* TODO: implement with i18n */}
            { patientView ? item.doctorDetails.titleArm : item.patient.titleArm }
          </p>
        </div>

        <div className='applications-card-item-child'>
          <div className='applications-card-item-child-location-block'>
            <img src={cardLocation} alt='location' />
          </div>

          <p className='applications-card-item-child-location-text caption2-200'>
            { item.workplaceTitle ? item.workplaceTitle : '' }
          </p>
        </div>
      </div>


      <div className='applications-card-item'>
        <p className='applications-card-item-date-time-text caption2-200'>
          {/* TODO: implement with i18n */}
          Ընտված օրը և Ժամը
        </p>

        <div className='applications-card-item-date-time-block'>
          <div className='applications-card-item-date-time-child'>
            <p className='applications-card-item-date-time-child-text caption2-200'>
              { item.date ? handleGetDate(item.date) : '' }
            </p>
          </div>
          <div className='applications-card-item-date-time-child'>
            <p className='applications-card-item-date-time-child-text caption2-200'>
              { item.date ? handleGetTime(item.date) : '' }
            </p>
          </div>
        </div>
      </div>


      <div className='applications-card-item'>
        <div className='applications-card-item-service-type-price'>
          <p className='applications-card-item-service-type-text caption1-300'>
            {/* TODO: implement with i18n */}
            { item.service.service.titleArm ? item.service.service.titleArm : '' }
          </p>

          <span className='applications-card-item-price-text caption1-300'>
            {
              // TODO: implement with i18n
              item.service.price ? item.service.price + 'դր' : ''
            }
          </span>
        </div>
      </div>


      {
        !withoutButtons && <div className='applications-card-item'>
          <div className='applications-card-buttons-block'>
            {patientView ? item.status === 6 &&
              <>
               <div className='applications-card-buttons-item'>
                  <Button click={handleCancel} text={cancelText} />
                </div>
                <div className='applications-card-buttons-item'>
                  <Button click={handleSuccess} text={successText} bgColor />
                </div>
              </>
            : item.status !== 6 &&
              <>
               <div className='applications-card-buttons-item'>
                  <Button click={handleCancel} text={cancelText} />
                </div>
                <div className='applications-card-buttons-item'>
                  <Button click={handleSuccess} text={successText} bgColor />
                </div>
              </>
           }
          
          </div>
        </div>
      }

    </div>
  )
};

ApplicationsCard.propTypes = {
  item: PropTypes.object,
  cancelText: PropTypes.string,
  callCancel: PropTypes.func,
  callSuccess: PropTypes.func,
  patientView: PropTypes.bool,
  successText: PropTypes.string,
  categoryName: PropTypes.string,
  withoutButtons: PropTypes.bool,
};

export default ApplicationsCard
