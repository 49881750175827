import React from 'react';
// import Header from 'sections/Header';
import Banner from 'components/Banner';
import LabMaterialsAndTypes from 'components/LabMaterialsAndTypes';
import CatsWithWorkplaces from 'components/CatsWithWorkplaces';

import './component.css';

const Lab = (props) => {
    return (
        <>
            <Banner containerClasses='mt-100' />
            <LabMaterialsAndTypes link='/lab/labMaterial'/>
            <CatsWithWorkplaces type='lab'/>
        </>
    );
}

export default Lab;