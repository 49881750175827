import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  item: null,
  type: null,
  fromMainPage: false
};

const labMaterialSlice = createSlice({
  name: 'labMaterial',
  initialState,
  reducers: {
    setLabMaterial(state, action) {
      state.item = action.payload
    },
    resetLabMaterial(state) {
      state.item = null
    },
    setLabMaterialType(state, action) {
        state.type = action.payload
    },
    resetLabMaterialType(state) {
        state.type = null
    },
    setFromMainPage(state, action) {
      state.fromMainPage = action.payload
    }
  }
});

export const { setLabMaterial, resetLabMaterial, setLabMaterialType, resetLabMaterialType, setFromMainPage } = labMaterialSlice.actions;
export default labMaterialSlice.reducer;
