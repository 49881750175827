import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getCookie } from 'utils';
import { setApplicationTypeSlice } from 'features/applicationType';
import {
  getUsersDetails,
  getMedicalCards,
  addHistoryTemplate,
  getDoctorProfileCalls,
  getDoctorProfilePatients
} from 'services/api';

import { setAddMedCardOrDoctor } from 'features/addMedCardOrDoctor';

import SideSettings from 'components/SideSettings';

import pdf from 'assets/DoctorPage/pdf.svg';
import lab from 'assets/DoctorPage/lab.svg';
import file from 'assets/DoctorPage/file.svg';
import time from 'assets/DoctorPage/time.svg';
import flask from 'assets/DoctorPage/flask.svg';
import heart from 'assets/DoctorPage/heart.svg';
import request from 'assets/DoctorPage/request.svg';
import calendar from 'assets/DoctorPage/calendar.svg';
import pharmacy from 'assets/DoctorPage/pharmacy.svg';
import corona from 'assets/DoctorPage/corona-virus.svg';
import phonendoscope from 'assets/DoctorPage/phonendoscope.svg';

import './component.css';

const BaseDomain = process.env.REACT_APP_BASE_DOMAIN;

const DoctorAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { item } = useSelector(state => state.userDetails);

  useEffect(() => {
    const checkAccess = getCookie('access_token');
    if (!checkAccess) {
      navigate('/login');
    }

    if (Object.keys(item).length === 0) {
      getUsersDetails(dispatch)
    }
  }, []);

  const handleGetDoctorService = (type) => {
    dispatch(setApplicationTypeSlice(type));
    if (type === 'request') {
      getDoctorProfileCalls(dispatch)
      .then(() => {
        navigate('/applications');
      })
      .catch(e => {
        console.log(e);
      });
    } else if (type === 'file') {
      getMedicalCards(dispatch)
      .then(() => {
        navigate('/medicalCards');
      })
      .catch(e => {
        console.log(e);
      });
    } else if (type === 'anamnez') {
      addHistoryTemplate()
    } else if (type === 'patients') {
      getDoctorProfilePatients(dispatch)
      .then(() => {
        navigate('/profile/patients');
      })
      .catch(e => {
        console.log(e);
      });
    } else if (type === 'templates') {
       navigate('/profile/myTemplates');
    } else if (type === 'diagnosis') {
      dispatch(setAddMedCardOrDoctor('doctor'));
      navigate('/diagnosis');
    } else if (type === 'calendar') {
      navigate('/myCalendar');
    }
  };

  return (
    <div className='doctor-account-block'>
      <SideSettings />
      <div className='doctor-info-block'>
        <div className='doctor-info-image-block'>
          {
            item && item.image ?
            <img src={BaseDomain + item.image} alt='doctor' className='doctor-info-image' />
            : ''
          }
        </div>

        <div className='text-block'>
          <p className='body2-300-title text-center'>
            { item && item.name ? item.name : '' }
          </p>
        </div>
      </div>

      <div className='doctor-info-service-block'>
        <div
          className='doctor-info-service-item'
          onClick={_ => handleGetDoctorService('request')}
        >
          <div className='doctor-info-service-item-icon-block'>
            <img src={request} alt='request' />
          </div>

          <div className='text-block'>
            <p className='body2-300-title text-center'>ԲԿ-ի հայտեր</p>
          </div>
          </div>
          <div
            className='doctor-info-service-item'
            onClick={_ => handleGetDoctorService('file')}
          >
            <div className='doctor-info-service-item-icon-block'>
              <img src={file} alt='file' />
            </div>

            <div className='text-block'>
              <p className='body2-300-title text-center'>Բժշկ․ Քարտեր</p>
            </div>
          </div>

          <div className='doctor-info-service-item' onClick={_ => handleGetDoctorService('patients')}>
            <div className='doctor-info-service-item-icon-block'>
              <img src={corona} alt='corona' />
            </div>

            <div className='text-block'>
              <p className='body2-300-title text-center'>Իմ հիվանդներ</p>
            </div>
          </div>

          <div
            onClick={_ => handleGetDoctorService('templates')}
            className='doctor-info-service-item'
          >
          {/* addHistoryTemplate */}
            <div className='doctor-info-service-item-icon-block'>
              <img src={time} alt='time' className='doctor-info-analize-icon' />
            </div>

            <div className='text-block'>
              <p className='body2-300-title text-center'>Իմ շաբլոններ</p>
            </div>
          </div>

          <div className='doctor-info-service-item' onClick={_ => handleGetDoctorService('calendar')}>
            <div className='doctor-info-service-item-icon-block'>
              <img src={calendar} alt='calendar' />
            </div>

            <div className='text-block'>
              <p className='body2-300-title text-center'>Իմ Օրացույցը</p>
            </div>
          </div>

          <div className='doctor-info-service-item' onClick={_ => handleGetDoctorService('diagnosis')}>
            <div className='doctor-info-service-item-icon-block'>
              <img src={pdf} alt='pdf' />
            </div>

            <div className='text-block'>
              <p className='body2-300-title text-center'>Ախտ/Ուղեց․</p>
            </div>
          </div>
        </div>
    </div>
  )
}

export default DoctorAccount
