import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: []
};

const doctorServicesSlice = createSlice({
  name: 'doctorServices',
  initialState,
  reducers: {
    setDoctorServices(state, action) {
      state.items = action.payload
    },
    resetDoctorServices(state) {
      state.items = []
    }
  }
});

export const { setDoctorServices, resetDoctorServices } = doctorServicesSlice.actions;
export default doctorServicesSlice.reducer;
