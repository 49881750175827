import React from 'react';
import {Link} from 'react-router-dom';
import "./Search.css";

function SearchBlock({object = null, onClick, link = null, classes = '', img = null, title=''}) {
    const BaseDomain = `${process.env.REACT_APP_BASE_DOMAIN}`;
    let imgSrc

     imgSrc = object && (classes.includes('active') ? BaseDomain + object.activeImagePath : BaseDomain + object.imagePath);
   
    return (
        <div className={`searchBlock ${classes}`} onClick={onClick}>
            {link ?

                <Link to={link} className="link searchLink">
                    <img src={imgSrc} alt="hearth" className="hearth"/>
                    <h4>{object.titleArm}</h4>
                </Link> 
                
                :

                <>
                    {img ? 
                         <>
                         <img src={img} alt="hearth" className="hearth"/>
                         <h4>{title}</h4>
                     </>
                    :
                    <>
                        <img src={imgSrc} alt="hearth" className="hearth"/>
                        <h4>{object.titleArm}</h4>
                    </>
                    
                    }
                    
                </>
            }
        </div>
    );
}

export default SearchBlock;