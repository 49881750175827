import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { setHeaderTitle } from 'features/headerTitle';

import Button from 'components/Button';
import FilterTabs from 'components/FilterTabs';
import HistoryCard from 'components/HistoryCard';

import './component.css';

const AnamnezBlock = ({
  histories,
  openModal,
  activeFilter,
  filterHistories,
  setHistoriesActive,
  withDoctorDetails = false,
  withAddButton = true,
  withWorkplaceDetails = false
}) => {
  const [ innerHistory, setInnerHistory ] = useState([]);
  const [ filterHistoriesTabs, setFilterHistoriesTabs ] = useState([]);
  const [ activeHistoriesFilter, setActiveHistoriesFilter ] = useState('');

  useEffect(() => {
    setInnerHistory(_ => histories);
  }, [histories]);

  useEffect(() => {
    setFilterHistoriesTabs(_ => filterHistories);
  }, [filterHistories]);

  useEffect(() => {
    setActiveHistoriesFilter(_ => activeFilter);
  }, [activeFilter]);

  return (
    <div>
      {
        filterHistoriesTabs && filterHistoriesTabs.length ? <FilterTabs
          tabs={filterHistoriesTabs}
          active={activeHistoriesFilter}
          checkActive={setHistoriesActive}
        /> : ''
      }

      {withAddButton && 
        <div className='one-med-card-item-btn-block'>
          <Button
            text='Ավելացնել'
            classUI='caption1-200'
            click={openModal}
          />
        </div>
      }
      

      {
        innerHistory && innerHistory.length > 0 ? <div className={`${withAddButton && 'diagnos-card-block'}`}>
          {
            innerHistory.map((item, index) => {
              return <HistoryCard
                key={`${item.type}_${index}`}
                item={item}
                withDoctorDetails={withDoctorDetails}
                withWorkplaceDetails={withWorkplaceDetails}
              />
            })
          }
        </div> : ''
      }
    </div>
  )
};

AnamnezBlock.propTypes = {
  openModal: PropTypes.func,
  histories: PropTypes.array,
  withAddButton: PropTypes.bool,
  activeFilter: PropTypes.string,
  withDoctorDetails: PropTypes.bool,
  withWorkplaceDetails: PropTypes.bool,
  filterHistories: PropTypes.array.isRequired,
  setHistoriesActive: PropTypes.func.isRequired,
};

export default AnamnezBlock
