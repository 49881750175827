import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { setModal, resetModal } from 'features/modal';
import { setHeaderTitle } from 'features/headerTitle';
import { setLabMaterialType } from "features/labMaterial";
import { setLabSchemaName } from "features/labSchemaName";
import { setFromDoctorProfile } from "features/fromDoctorProfile";
import { setHistoriesTemplateType } from "features/historiesTemplateType";
import {
  setDoctorAdviceTemplates,
  resetDoctorAdviceTemplates
} from 'features/doctorAdviceTemplates';
import {
  getDoctorTemplates,
  removeAdviceTemplate,
  getDoctorAdviceTemplates,
  removeLabSchemaFromDoctorTemplate,
  removeLabServiceFromDoctorTemplate
} from "services/api";

import Modal from 'components/Modal';
import Input from "components/Input";
import Button from "components/Button";
import AdviceCard from 'components/AdviceCard';
import FilterCard from "components/FilterCard";
import FilterTabs from "components/FilterTabs";
import MyModal from "components/Global/MyModal";
import AnamnezBlock from "components/AnamnezData";
import CreateService from "components/CreateService";
import LabOneService from "components/LabOneService";

import advice from 'assets/Advice/advice.svg';
import labIconBlue from 'assets/Lab/labIconBlue.svg';
import adviceBlue from 'assets/Advice/adviceBlue.svg';
import labIconWhite from 'assets/Lab/labIconWhite.svg';
import diagnosTime from 'assets/Diagnos/diagnos-time.svg';
import diagnosTimeBlue from 'assets/Diagnos/diagnos-time-blue.svg';
import timeDiagramBlue from 'assets/Diagnos/time-diagram-blue.svg';

import './component.css';
import LabSchemas from "components/LabSchemas";

const DoctorTemplates = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { items } = useSelector(state => state.doctorTemplates);
    const doctorAdviceTemplates = useSelector(state => state.doctorAdviceTemplates.items);

    let medicalHistories = items && items.medicalHistories;
    let labServices = items && items.labServices;
    let labSchemas = items && items.labSchemas;
    
    const [ deletedId, setDeletedId ] = useState(null);
    const [ schemaName, setSchemaName ] = useState('');
    const [ innerHistories, setInnerHistories ] = useState([]);
    const [ searchFilter, setSearchFilter ] = useState('Անամնեզ');
    const [ showSchemaModal, setShowSchemaModal ] = useState(false);
    const [ filterHistoriesTabs, setFilterHistoriesTabs ] = useState([]);
    const [ labActiveFilter, setLabActiveFilter ] = useState('Հետազոտվող նյութ');
    const [ labFilterTabs, setLabFilterTabs ] = useState(['Հետազոտվող նյութ', 'Հետազոտության տեսակ', 'Քարտեր' ]);

    const [ activeHistoriesFilter, setActiveHistoriesFilter ] = useState(
      medicalHistories && medicalHistories[0] ? medicalHistories[0]['title'] : 'Գանգատներ'
      );

    const [ searchTabs, setSearchTabs ] = useState([
        {
          titleArm: 'Անամնեզ',
          icon: diagnosTimeBlue,
          choosenIcon: diagnosTime
        },
        {
          titleArm: 'Հետազոտություն',
          icon: labIconBlue,
          choosenIcon: labIconWhite
        },
        {
          titleArm: 'Խորհուրդներ',
          icon: adviceBlue,
          choosenIcon: advice
        }
      ]);


    const handleCheckActiveSearch = (item) => {
      const newAnamnezData = searchTabs.map((innerItem) => {
          if (innerItem.text === item.text) {
            innerItem.active = true
          } else {
            innerItem.active = false
          }
          return innerItem
        });
        setSearchFilter(_ => item.titleArm);
        setSearchTabs(_ => {
          return [
            ...newAnamnezData
          ]
        });
    }   

    const handleBtnClick = () => {
      dispatch(setHeaderTitle('Ավելացնել անամնեզ'));
      navigate('/addHistoryTemplate');
    }

    const handleSetHistoriesActive = (active) => {
        setActiveHistoriesFilter(_ => active);
    }

    const handleLabActiveFilter = (active) => {
      setLabActiveFilter(active);
    }

    const handleAddServiceClick = () => {
      dispatch(setFromDoctorProfile(true));
      dispatch(setLabMaterialType(labActiveFilter))
      navigate('/choose/labMaterial');
    }

    const handleAddAdviceClick = () => {
      dispatch(setHeaderTitle('Ավելացնել Խորհուրդ'));
      navigate('/addAdviceTemplate');
    }

    const handleAddSchemaClick = () => {
      setShowSchemaModal(true);
    }

    const removeLabServiceTemplate = (service) => {
       removeLabServiceFromDoctorTemplate({labServiceId: service.id})
        .then(
            getDoctorTemplates(dispatch)
          .catch(e => {
            console.log(e);
          })
      )
    }

    const handleChangeSchemaTitle = (e) => {
      const { value } = e.target;

      setSchemaName(value);
    };

    const handleAddSchemaName = () => {
      dispatch(setLabSchemaName(schemaName));
      navigate('/choose/labMaterial');
    }

    const deleteLabSchema = (schema) => {
      removeLabSchemaFromDoctorTemplate({schemaSlug: schema.slug})
      .then(
        getDoctorTemplates(dispatch)
      )
    }

    const handleEdit = (id) => {
      dispatch(setHeaderTitle('Փոփոխել Խորհուրդը'));
      navigate(`/addAdviceTemplate?id=${id}`);
    }

    const handleOpenModal = (id) => {
      dispatch(setModal());
      setDeletedId(() => id);
    }

    const handleDelete = () => {
      removeAdviceTemplate({
        adviceTemplateId: deletedId
      })
      .then(() => {
        const newAdvices = doctorAdviceTemplates &&
          doctorAdviceTemplates.length && doctorAdviceTemplates.filter(item => item.id !== deletedId);
        dispatch(resetModal());
        setDeletedId(() => null);
        dispatch(setDoctorAdviceTemplates(newAdvices));
      })
      .catch(e => {
        console.log(e);
      })
    }
 
    useEffect(() => {
      if (medicalHistories && medicalHistories.length) {
        const tabs = medicalHistories.map(item => item.title);
        setFilterHistoriesTabs(_ => {
          return [
            ...tabs
          ]
        });

        const newHistories = medicalHistories.filter(item => item.title === activeHistoriesFilter);
        if (newHistories && newHistories[0] && newHistories[0].histories && newHistories[0].histories.length) {
          setInnerHistories(_ => {
            return newHistories[0].histories
          });
        } else {
          setInnerHistories(_ => {
            return []
          });
        }
      }
    }, [medicalHistories, activeHistoriesFilter]);

    useEffect(() => {
      const historyType = items && medicalHistories && medicalHistories.filter(item => item.title === activeHistoriesFilter);
      if (historyType && historyType[0]){
        dispatch(setHistoriesTemplateType(historyType[0].type));
      }
    }, [medicalHistories, activeHistoriesFilter]);
    
    useEffect(() => {
      if (items && !items.length) {
        getDoctorTemplates(dispatch)
          .then(() => {
            
          })
          .catch(e => {
            console.log(e);
          });
      }

      return () => {
        dispatch(resetDoctorAdviceTemplates());
      }
    }, [])

    const addSchemaModalBody = <div className="row">
      <p className="body1-300-title mb-3">Ստեղծել նոր հետազոտության քարտ</p>
      <Input change={handleChangeSchemaTitle} placeholder="Մուտքագրեք անունը"/>
      <div className="col mt-3">
        <Button text="Վերադառնալ" click={() => setShowSchemaModal(false)} />
      </div>
      <div className="col mt-3">
        <Button classUI="h40" text="Հաստատել" bgColor click={handleAddSchemaName}/>
      </div>
    </div>;
   
    return (
        <div className="mt-100">
            <CreateService
              withoutNew
              name="Իմ շաբլոններ"
              icon={timeDiagramBlue}
            />
            <hr className="color-c4 mb-0"/>

            <div className='templates-card-block mb-4'>
              {
                searchTabs && searchTabs.length && searchTabs.map((item, index) => {
                  return <FilterCard
                    item={item}
                    key={`${item.titleArm}_${index}`}
                    checkActive={handleCheckActiveSearch}
                    active={item.titleArm === searchFilter}
                  />
                })
              }
            </div>

            {
              searchFilter === 'Անամնեզ' &&
                <div className='one-med-card-item'>
                    <AnamnezBlock
                      histories={innerHistories}
                      openModal={handleBtnClick}
                      activeFilter={activeHistoriesFilter}
                      filterHistories={filterHistoriesTabs}
                      setHistoriesActive={handleSetHistoriesActive}
                    />
                </div>
            }

            {
              searchFilter === 'Հետազոտություն' && labFilterTabs && labFilterTabs.length > 0 && 
                <>
                  <FilterTabs
                    tabs={labFilterTabs}
                    checkActive={handleLabActiveFilter}
                    active={labActiveFilter}
                  />

                  <Button 
                      text="Ավելացնել"
                      click={labActiveFilter === 'Քարտեր' ? handleAddSchemaClick : handleAddServiceClick}
                  />
               
                  {
                    labActiveFilter === 'Հետազոտվող նյութ' && labServices && labServices.length > 0 && labServices.filter(service => service.labService.serviceType === 'material').map((service, index) => {
                      return <LabOneService
                          onlyDelete
                          withoutAction
                          service={service.labService}
                          key={service.id + '-' + index}
                          onClick={removeLabServiceTemplate}
                      />
                    })
                  }

                  { 
                    labActiveFilter === 'Հետազոտության տեսակ' && labServices && labServices.length > 0 && labServices.filter(service => service.labService.serviceType === 'type').map((service, index) => {
                      return <LabOneService
                          onlyDelete
                          withoutAction
                          service={service.labService}
                          key={service.id + '-' + index}
                          onClick={removeLabServiceTemplate}
                      />
                    })
                  }

                  { 
                    labActiveFilter === 'Քարտեր' && labSchemas && 
                    <LabSchemas handleDelete={deleteLabSchema} schemas={labSchemas}/>
                  }
                  <MyModal
                    body={addSchemaModalBody}
                    onHide={()=> setShowSchemaModal(false)}
                    show={showSchemaModal}
                    centered
                  />
                </>
            }

            {
              searchFilter === 'Խորհուրդներ' && <>
                <Button
                  text="Ավելացնել"
                  click={handleAddAdviceClick}
                />

                <div className="advice-blocks">
                  {
                    doctorAdviceTemplates && doctorAdviceTemplates.length ? doctorAdviceTemplates.map((item, index) => {
                      return (
                        <AdviceCard
                          id={item.id}
                          handleEdit={handleEdit}
                          openModal={handleOpenModal}
                          description={item.description}
                          key={`${item.description}_${index}`}
                        />
                      )
                    }) : ''
                  }
                </div>
              </>
            }

          <Modal
            cancelText='Չեղարկել'
            callCancel={_ => _}
            callSuccess={handleDelete}
            successText='Հաստատել'
            content={
              <>
                <p style={{ color: 'red' }}>Ցանկանում եք ջնջել խորհուրդը</p>
              </>
            }
          />
        </div>
    );
}

export default DoctorTemplates;
