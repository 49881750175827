import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: []
};

const applicationsSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    setApplications(state, action) {
      state.items = action.payload
    },
    resetApplications(state) {
      state.items = []
    }
  }
});

export const { setApplications, resetApplications } = applicationsSlice.actions;
export default applicationsSlice.reducer;
