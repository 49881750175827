import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false
};

const labCartSlice = createSlice({
  name: 'labCart',
  initialState,
  reducers: {
    setLabCart(state) {
      state.open = true
    },
    resetLabCart(state) {
      state.open = false
    }
  }
});

export const { setLabCart, resetLabCart } = labCartSlice.actions;
export default labCartSlice.reducer;
