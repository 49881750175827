import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { resetModal } from 'features/modal';

import Button from 'components/Button';

import close from 'assets/Modal/close-icon.svg';

import './component.css';

const Modal = ({
  data,
  content,
  actionType,
  cancelText,
  callCancel,
  callSuccess,
  successText,
  checkInputValue
}) => {
  const dispatch = useDispatch();
  const { open } = useSelector(state => state.modal);

  const handleCancel = () => {
    callCancel && callCancel(actionType, data);
    handleCloseModal();
  };

  const handleSuccess = () => {
    callSuccess(actionType, data);
    if (actionType === 'cancel' && checkInputValue !== '') {
      handleCloseModal();
    }
  };

  const handleCloseModal = () => {
    dispatch(resetModal());
  };

  const handleStopAction = (e) => {
    e.stopPropagation();
  }

  return (
    <div
      className='new-modal-block'
      style={{
        display: open ? 'flex' : 'none'
      }}
      onClick={handleCloseModal}
    >
      <div
        className='new-modal-center'
        onClick={handleStopAction}
      >

        <div className='new-modal-header'>
          <div
            className='new-modal-header-icon-block'
            onClick={handleCloseModal}  
          >
            <img src={close} alt='close' />
          </div>
        </div>

        {
          content ? <div className='new-modal-content'>
            { content }
          </div> : ''
        }

        <div className='new-modal-footer'>
          <div className='new-modal-footer-btn-block'>
            <Button
              text={cancelText}
              click={handleCancel}
            />
          </div>

          <div className='new-modal-footer-btn-block'>
            <Button
              bgColor
              text={successText}
              click={handleSuccess}
            />
          </div>
        </div>

      </div>
    </div>
  )
}

Modal.propTypes = {
  data: PropTypes.object,
  content: PropTypes.element,
  callCancel: PropTypes.func,
  callSuccess: PropTypes.func,
  actionType: PropTypes.string,
  cancelText: PropTypes.string,
  successText: PropTypes.string,
  checkInputValue: PropTypes.string
};

export default Modal
