import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  text: ''
};

const tempModalTitleSlice = createSlice({
  name: 'tempTemplate',
  initialState,
  reducers: {
    setTempModalTitle(state, action) {
      state.text = action.payload
    },
    resetTempModalTitle(state) {
      state.text = ''
    }
  }
});

export const { setTempModalTitle, resetTempModalTitle } = tempModalTitleSlice.actions;
export default tempModalTitleSlice.reducer;
