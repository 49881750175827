
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Button from "components/Button";
import FilterTabs from "components/FilterTabs";
import LabOneService from "components/LabOneService";

import { handleGetDate } from "utils";

import './component.css';

const MedCardLabServices = ({onDeleteService}) => {
    const navigate = useNavigate();

    const labServiceMaterials = useSelector(state => state.oneMedCard.item.labMaterialsArray);
    const [filter, setFilter] = useState([]);
    const [activeFilter, setActiveFilter] = useState([]);

    const handleActiveFilter = (active) => {
        setActiveFilter(_ => active);
    };

    const handleAddServiceClick = () => {
        navigate('/choose/labMaterial');
    }

    useEffect(() => {
        const dynamicFilters = labServiceMaterials && labServiceMaterials.length > 0 && 
        labServiceMaterials.map(item => item.titleArm);
          setFilter(() => {
            return [
              ...dynamicFilters
            ]
          });
        setActiveFilter(() => {
          return dynamicFilters[0]
        })
    }, [labServiceMaterials]);

    return (
       <>
             <FilterTabs
                tabs={filter}
                active={activeFilter}
                checkActive={handleActiveFilter}
            />

            {activeFilter === filter[0] && 
              <>
                  <Button 
                      text="Ավելացնել հետազոտություն"
                      click={handleAddServiceClick}
                  />
                  {
                    labServiceMaterials && labServiceMaterials.length > 0 && labServiceMaterials.map((status, index) => {
                    
                      return <div className="row mt-5" key={`${status.titleArm}_${index}`}>
                          {
                            status.groups && status.groups.length > 0 && status.groups.map((group, groupIndex) => {
                                  return <div key={`${group.id}_${groupIndex}`} className='col-12 oneGroup'>
                                          <p className='text-green caption2-300 text-right'>
                                             {handleGetDate(group.created)}
                                          </p>
                                          <p className='caption2-300 fw-bold'>
                                              {group.doctorDetails.titleArm}
                                            </p>
                                            <p
                                              className='caption2-200 med-card-diagnos-block-title my-3'>
                                              {group.doctorDetails.profession.titleArm}
                                            </p>
                                          <h3 className='f-s-14 fw-bold'>Նյութեր</h3>
                                           
                                          {
                                            group.labServices.map((service, innerIndex) => {
                                              return <LabOneService
                                                lab={null}
                                                key={`${service.id}_${innerIndex}`}
                                                service={service.labService}
                                                onClick={() => onDeleteService(group.id, service.labService.id)}
                                                selectedServices={[]}
                                                withoutAction
                                                onlyDelete
                                              />
                                            }
                                          )}
                                        </div>
                                  })
                          }
                            </div>
                    })
                  } 
              </>
            }




       </>
    );
}

export default MedCardLabServices;