import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false
};

const sideSettingsSlice = createSlice({
  name: 'sideSettings',
  initialState,
  reducers: {
    setSideSettings(state) {
      state.open = true
    },
    resetSideSettings(state) {
      state.open = false
    }
  }
});

export const { setSideSettings, resetSideSettings } = sideSettingsSlice.actions;
export default sideSettingsSlice.reducer;
