
import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { getCategory, getLabsByCategory } from 'services/api';

// import Header from 'sections/Header';
import Banner from 'components/Banner';
import WorkplaceCategory from 'components/WorkplaceCategory';
import WorkplaceCard from 'components/WorkplaceCard';

const LabsByCat = () => {
  const { categorySlug } = useParams();
  const [ category, setCategory ] = useState({});
  const [ workplaces, setWorkplaces ] = useState([]);

  const renderWorkplaces = () => {
    return workplaces && workplaces.length > 0 && workplaces.map((workplace, index) =>
      <WorkplaceCard
        key={workplace.id}
        link={`/lab/one/${workplace.slug}`}
        workplace={workplace}
        containerClasses={index % 2 === 0 ? "col-6 col-pd-r-7 mb16" : "col-6 col-pd-l-7 mb16"}
      />
    );
  };

  useEffect(() => {
    if (!category.length) {
      getCategory(categorySlug)
      .then(res => {
        setCategory(_ => res);
      })
      .catch(e => {
        console.log(e);
      });
    }
    if (!workplaces.length) {
      getLabsByCategory(categorySlug)
      .then(res => {
        setWorkplaces(_ => res);
      })
      .catch(e => {
        console.log(e);
      });
    }
    return () => {
      setCategory({});
      setWorkplaces([]);
    }
  }, []);

  return (
    <>
      <Banner containerClasses='mt-100' />
      <Row>
        <WorkplaceCategory category={category}/>
      </Row>
      <Row>
        {renderWorkplaces()}
      </Row>
    </>
  );
}

export default LabsByCat;
