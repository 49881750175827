import React from 'react';
import filterIcon from 'assets/Filter/menu.png';
import './../global.css'

const FilterButton = ({onClick}) => {
  return (
    <div className="row">
      <div className='col-12'>
        <div className='filterButtonDiv' onClick={() => onClick()}>
          <p className='f-s-14 text-black filterText'> Դասակարգել
            <span className='d-block filterimageSpan'>
              <img src={filterIcon} className='filter-image' alt="filterIcon" />   
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default FilterButton;
