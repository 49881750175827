import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  item: {
    doctor: {},
    bookmark: null,
    serviceId: null
  }
};

const doctorSlice = createSlice({
  name: 'doctor',
  initialState,
  reducers: {
    setDoctor(state, action) {
      state.item = action.payload
    },
    resetDoctor(state) {
      state.item = {
        doctor: {},
        bookmark: null,
        serviceId: null
      }
    }
  }
});

export const { setDoctor, resetDoctor } = doctorSlice.actions;
export default doctorSlice.reducer;
