import React, { useEffect } from 'react';

import { setHeaderTitle } from 'features/headerTitle';
import { useDispatch } from 'react-redux';

import ObjectiveIndicators from 'components/ObjectiveIndicators';

const PatientIndicatorData = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setHeaderTitle('Ցուցանիշներ'));
    }, []);

    return (
        <div className='mt-100 objective-indicators-block'>
            <ObjectiveIndicators fromPatientPage />
        </div>
    );
}

export default PatientIndicatorData;
