import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: []
};

const patientDiagnosisSlice = createSlice({
  name: 'patientDiagnosis',
  initialState,
  reducers: {
    setPatientDiagnosis(state, action) {
      state.items = action.payload
    },
    resetPatientDiagnosis(state) {
      state.items = []
    }
  }
});

export const { setPatientDiagnosis, resetPatientDiagnosis } = patientDiagnosisSlice.actions;
export default patientDiagnosisSlice.reducer;

