import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Input from 'components/Input';
import Select from 'components/Select';

import './component.css';

const OpenableItem = ({ item, slug, change }) => {
  const [ innerItem, setInnerItem ] = useState({});

  const handleChange = (e, title) => {
    change({
      e,
      slug,
      key: title
    });
  };

  useEffect(() => {
    setInnerItem(() => {
      return {
        ...item
      }
    });
  }, [item]);

  return (
    <div className='openable-item-block'>
      {
        innerItem.type === 'input' ?
          <Input
            type='dynamic'
            placeholder={innerItem.titleArm}
            unit={innerItem.measurementUnit}
            change={(e) => handleChange(e, innerItem.titleArm)}
          />
          : <Select
            placeholder={innerItem.titleArm}
            options={innerItem.choicesArray}
            change={(e) => handleChange(e, innerItem.titleArm)}
          />
      }
    </div>
  )
};

OpenableItem.propTypes = {
  item: PropTypes.object,
  change: PropTypes.func
};

export default OpenableItem
