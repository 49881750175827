import React from 'react';
import { Row } from 'react-bootstrap';

import './component.css';

const DoctorService = ({ service }) => {
  let doctorService = service.doctorService?.service ? service.doctorService?.service : service.service;
  let price = service.doctorService?.service ? service.doctorService?.price : service.price;

  return (
    <Row className='mb-2'>
      <div className='col-9'>
        <p className='caption2-100'>{doctorService.titleArm}</p>
      </div>
      <div className='col-3'>
        <p className='text-end caption2-200 servicePrice'>{price} դր․</p>
      </div>
    </Row>
  );
}

export default DoctorService;
