import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: []
};

const doctorAdviceTemplatesSlice = createSlice({
  name: 'doctorAdviceTemplates',
  initialState,
  reducers: {
    setDoctorAdviceTemplates(state, action) {
      state.items = action.payload
    },
    resetDoctorAdviceTemplates(state) {
      state.items = []
    }
  }
});

export const { setDoctorAdviceTemplates, resetDoctorAdviceTemplates } = doctorAdviceTemplatesSlice.actions;
export default doctorAdviceTemplatesSlice.reducer;
