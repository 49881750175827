import React, { useState } from 'react';

import plus from 'assets/AddServicesToMedCard/plus.svg';
import minus from 'assets/AddServicesToMedCard/minus.svg';

import './component.css';

const AddServices = ({ item, checkIds }) => {
  const [active, setActive] = useState(false);

  const handleCheckActive = () => {
    setActive(_ => !active);
    checkIds(item.service.id);
  };

  return (
    <div className='add-services-item'>
      <div className='add-services-item-name caption2-200'>
        <p>{ item.service.titleArm }</p>
      </div>
      <div className='add-services-item-price caption2-200'>
        <p>{ item.price } դր</p>
        <div onClick={_ => handleCheckActive()}>
          {!active ? <img src={plus} alt='plus' /> : <img src={minus} alt='minus' />}
        </div>
      </div>
    </div>
  )
}

export default AddServices