import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { 
  getOneMedCard,
  addDoctorServiceToCard
} from 'services/api';

import Button from 'components/Button';
import AddServices from 'components/AddServices';
import FilterSearch from 'components/FilterSearch';

import './component.css';

const AddServicesToMedCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { item } = useSelector(state => state.oneMedCard);
  const { doctor } = useSelector(state => state.doctor.item);

  const [ ids, setIds ] = useState([]);
  const [ services, setServices ] = useState([]);
  const [ disabled, setDisabled ] = useState(true);
  const [ filterItem, setFilterItem ] = useState('');

  const handleCheckIds = (id) => {
    if (ids.includes(id)) {
      const newIds = ids.filter(item => item !== id);
      setIds(_ => newIds);
    } else {
      setIds(prev => {
        return [
          ...prev,
          id
        ]
      });
    }
  };

  const handleFilter = (searchText) => {
    setFilterItem(_ => searchText);
  };

  const handleSaveServices = () => {
    const params = {
      medicalCardCode: item.code,
      serviceIds: ids
    }

    addDoctorServiceToCard(params)
    .then(() => {
      getOneMedCard(dispatch, item.code)
        .then(() => {
          navigate('/oneMedCard');
        })
        .catch(e => {
          console.log(e);
        })
    })
    .catch(e => {
      console.log(e);
    })
  };

  useEffect(() => {
    doctor && doctor.services && doctor.services.length && setServices(_ => doctor.services);
  }, [doctor]);

  useEffect(() => {
    if (ids.length === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [ids.length]);

  return (
    <>
      <div className='add-services-search-place'>
        <FilterSearch
          filterCall={handleFilter}
        />
      </div>
      <div className='add-services-parent-block'>
        {
          filterItem !== '' ?
            services && services.length && services.filter(item => item.service.titleArm.includes(filterItem)).map((item, index) => {
              return <AddServices key={`${item.id}_${index}`} item={item} checkIds={handleCheckIds} />
            })
            : services && services.length && services.map((item, index) => {
              return <AddServices key={`${item.id}_${index}`} item={item} checkIds={handleCheckIds} />
            })
        }
      </div>
      <div className='add-services-btn-place'>
        <div className='add-services-btn-block'>
          <Button text='Հաստատել' bgColor click={handleSaveServices} disabled={disabled} />
        </div>
      </div>
    </>
  )
}

export default AddServicesToMedCard
