import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: []
};

const patientAdvicesSlice = createSlice({
  name: 'patientAdvices',
  initialState,
  reducers: {
    setPatientAdvices(state, action) {
      state.items = action.payload
    },
    resetPatientAdvices(state) {
      state.items = []
    }
  }
});

export const { setPatientAdvices, resetPatientAdvices } = patientAdvicesSlice.actions;
export default patientAdvicesSlice.reducer;

