import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  item: [],
};

const departmentSlice = createSlice({
  name: 'department',
  initialState,
  reducers: {
    setDepartment(state, action) {
      state.item = action.payload;
    },
   
    resetDepartment(state) {
      state.item = [];
    }
  }
});

export const { setDepartment, resetDepartment } = departmentSlice.actions;
export default departmentSlice.reducer;
