import React, { useState } from 'react';
import FilterButton from '../Global/Filter/FilterButton';
import DrugCategories from './DrugCategories';
import MyModal from '../Global/MyModal';
import { useParams } from 'react-router-dom';
import FilterBody from './FilterBody';
import HeaderWithoutLogo from 'sections/HeaderWithoutLogo';

function DrugCategoryPage(props) {
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [selectedConnections, setSelectedConnections] = useState({});   
    let {slug} = useParams();

    const handleFilterModal = () => {
        setShowFilterModal(! showFilterModal);
    }

    const handleSelectedConnections = (fromChildSelectedConnections) => {
        setSelectedConnections(fromChildSelectedConnections);
        setShowFilterModal(false);
    }

    let filterBody = <FilterBody setParentConnections={handleSelectedConnections} />;
    return (
        <>
        <HeaderWithoutLogo title='Դասակարգել'/>
            <FilterButton onClick={() => handleFilterModal()}  />
            <DrugCategories title={`Ընտրել խմբավորումը`} defaultSlug={slug} selectedConnections={selectedConnections}/>
            <MyModal
                show={showFilterModal}
                onHide={() => setShowFilterModal(false)}
                body={filterBody}
                centered
            />
        </>
    );
}

export default DrugCategoryPage;
