import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getDoctor, setDoctorBookmarked } from 'services/api';

import './component.css';

const BaseDomain = process.env.REACT_APP_BASE_DOMAIN;

const DoctorsInfo = ({
  doctor,
  useAsLink = true,
  servicesLink = '',
  onClick
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const choosenDoctor = useSelector(state => state.doctor.item);
  const [ bookmarked, setBookmarked ] = useState(null);

  const setBookmarkedFunction = (bookmark) => {
    setBookmarked(bookmark);
    setDoctorBookmarked(doctor.slug, bookmark);
  };

  useEffect(() => {
    setBookmarked(doctor.bookmarked);
  }, [doctor]);

  const handleCheck = (doctor) => {
    onClick(doctor);
  }

  const handleClick = (doctor) => {
    navigate(`/doctor/one/${doctor.slug}`);
  }

  return (
    <div 
      className='card-services-list-item'  
      onClick={() => useAsLink ? handleClick(doctor) : handleCheck(doctor)}>
      <div className='card-services-list-item-img-block'>
        <img className='card-services-list-item-img' src={BaseDomain + doctor.imagePath} alt={doctor.titleArm} />
      </div>

      <p className='card-services-list-item-doc-info caption1-300'>
        {doctor.titleArm}
      </p>

      <p className='card-services-list-item-info caption3-200'>
        Անվճար խորհրդատվություն
      </p>
    </div>
  );
}

DoctorsInfo.propTypes = {
  doctor: PropTypes.object,
  servicesLink: PropTypes.string,
  useAsLink: PropTypes.bool,
  onClick: PropTypes.func,
};

export default DoctorsInfo;
