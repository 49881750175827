import React from 'react';
import PropTypes from 'prop-types'

import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { setSideSettings, resetSideSettings } from 'features/sideSettings';

import logo from 'assets/Header/Logo.png';

import settings from 'assets/Header/settings.svg';
import notification from 'assets/Header/notification.svg';
import HeaderWithoutLogo from 'sections/HeaderWithoutLogo';

import './component.css';

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const { open } = useSelector(state => state.sideSettings);
  const pathsWithoutLogo = [
    '/department/',
    '/departments',
    '/lab/labMaterial',
    '/profile/onePatient',
    '/profile/onePatient/diagnosis',
    '/profile/onePatient/medicalHistories',
    '/profile/onePatient/indicators',
    '/profile/onePatient/advices',
    '/profile/onePatient/labServices',
    '/oneMedCard',
    '/choose/labMaterial',
    '/addHistoryTemplate',
    '/addAdviceTemplate',
    '/addRequest'
  ];

  const pathsWithLogo = [
    '/medicalCard',
    '/applications',
    '/doctorAccount',
    '/patientAccount'
  ];


  const onClickSearch = () => {
    navigate('/docSearch');
  };

  const handleOpenSideSettings = () => {
    open ? dispatch(resetSideSettings()) : dispatch(setSideSettings())
  };

  const withCartPaths = [
    '/lab/labMaterial'
  ];

  return (
    <Row>
         {
          pathsWithoutLogo.includes(pathname) || pathname.includes('department/') ||
           pathname.includes('doctor/oneGallery') || pathname.includes('lab/one') || pathname.includes('workplace/one') ? 
          <HeaderWithoutLogo
            withCart={withCartPaths.includes(pathname) ||  pathname.includes('lab/one') || pathname.includes('workplace/one')}
          /> :
        <Col className='headerWithLogo'>
          <Row>
            <Col>
              <Link to={'/'}>
                <img className='logoImg' src={logo} alt='logo' />
              </Link>
            </Col>
            <Col className={`text-end`}>
              {/* <img className='searchIcon' src={search} alt='search' onClick={() => onClickSearch()}/> */}
              <div className='header-icon-btn-place'>
                <div className='header-icon-btn-block'>
                  {
                    pathsWithLogo.includes(pathname) ?
                      <>
                        <button className='header-icon-btn'>
                          <img src={notification} alt='notification' />
                        </button>
                        <button className='header-icon-btn' onClick={handleOpenSideSettings}>
                          <img src={settings} alt='settings' />
                        </button>
                      </> : <>
                        <svg className='searchIcon' onClick={() => onClickSearch()} xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                          <path d='M22.2119 22L16.0822 15.8702M16.0822 15.8702C17.5525 14.3999 18.4619 12.3687 18.4619 10.125C18.4619 5.63769 14.8242 2 10.3369 2C5.8496 2 2.21191 5.63769 2.21191 10.125C2.21191 14.6123 5.8496 18.25 10.3369 18.25C12.5806 18.25 14.6118 17.3406 16.0822 15.8702Z' stroke='#218CC0' strokeWidth='1.8' strokeLinecap='round' strokeLinejoin='round' />
                        </svg>
                        <svg className='phoneIcon' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                          <path d='M15.8288 16.1803L15.2102 17.1082C15.0867 17.2934 14.9234 17.4514 14.7082 17.5083C14.0033 17.6946 12.1318 17.7787 9.28251 14.9294C6.43324 12.0801 6.51734 10.2086 6.7036 9.50372C6.76047 9.28851 6.9185 9.12521 7.10371 9.00174L8.03161 8.38314C8.72245 7.92258 8.90913 6.98919 8.44857 6.29834L6.69608 3.66961C6.29408 3.06661 5.51911 2.83624 4.85299 3.12171L4.06244 3.46052C3.46189 3.7179 2.972 4.17982 2.6798 4.76422C2.43568 5.25247 2.24646 5.76662 2.22083 6.3119C2.14004 8.03027 2.51683 12.2638 7.23247 16.9795C11.9481 21.6951 16.1816 22.0719 17.9 21.9911C18.4453 21.9654 18.9594 21.7762 19.4477 21.5321C20.0321 21.2399 20.494 20.75 20.7514 20.1495L21.0902 19.3589C21.3757 18.6928 21.1453 17.9178 20.5423 17.5158L17.9136 15.7633C17.2227 15.3028 16.2893 15.4895 15.8288 16.1803Z' stroke='#218CC0' strokeWidth='1.8' />
                        </svg>
                      </>
                  }
                </div>
              </div>
            </Col>
          </Row>
        </Col>

                }
    </Row>
  );
}

Header.propTypes = {
  medCardCode: PropTypes.string
};

export default Header;
