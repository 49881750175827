import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { getDrugsFilterConnetions } from 'services/api';

import FilterList from 'components/Global/Filter/FilterList';

import filterIcon from 'assets/Filter/menu.png';

const FilterBody = ({
  setParentConnections,
  mainSelectedConnections
}) => {
  const [ connections, setConncetions ] = useState([]);
  const [ selectedConnections, setSelectedConnections ] = useState({});   
  const sortList = [
    'Այբենական Ա-Ֆ',
    'Այբենական Ֆ-Ա',
    'Գինը՝ ըստ աճման',
    'Գինը՝ ըստ նվազման',
    'Զեղչված դեղորայք'
  ];

  const onFilterInputSearch = (name, value) => {
    if (name && value) {
      getDrugsFilterConnetions({[name]: value})
      .then(res => {
        setConncetions(res);
      })
      .catch(e => {
        console.log(e);
      });
    } else {
      getDrugsFilterConnetions()
      .then(res => {
        setConncetions(res);
      })
      .catch(e => {
        console.log(e);
      });
    }
  }

  const renderConnections = () => {
      return connections.map((item, index) => {
          return  <FilterList 
              key={`${item}_${index}`}
              title={item.title} 
              list={item.list} 
              onSearchClick={onFilterInputSearch} 
              slug={item.slug}
              onFilterChange={onFilterChange}
              selectedConnections={mainSelectedConnections}
          />
      })
  }

  const onFilterChange = (e) => {
      const mainSlug = e.target.name;
      const isExist = e.target.checked;
      const currentSlug = e.target.getAttribute('slug');

      if (selectedConnections.hasOwnProperty(`${mainSlug}`)) {
          setSelectedConnections(prev => {
              if (isExist) {
                  return {
                      ...prev,
                      [`${mainSlug}`]: [...prev[mainSlug], currentSlug]
                  }
              } else {
                  return {
                      ...prev,
                      [`${mainSlug}`]: prev[mainSlug]?.filter(i => i !== currentSlug)
                  }
              } 
          });
      } else {
          setSelectedConnections(prev => {
              return {
                  ...prev,
                  [`${mainSlug}`]: [currentSlug]
              }
          });
      }
  }

  const submitFilter = () => {
      setParentConnections(selectedConnections);
  }

  useEffect(() => {
    if (!connections.length) {
      getDrugsFilterConnetions()
      .then(res => {
        setConncetions(res);
      })
      .catch(e => {
        console.log(e);
      });
    }
    return () => {
      setConncetions([]);
    }
  }, []);
    
  return (
    <Row>
      <Col xs={12} className='px15'>
        <p className='f-s-14 text-grey filterText'>
          Դասակարգել
          <span className='d-block filterimageSpan'>
            <img src={filterIcon} className='filter-image' alt="filterIcon" />   
          </span>
        </p>
      </Col>
      <FilterList 
        list={sortList} 
        type={`static`} 
        name={`sortSlug`}
        withoutSearch={true}
        title={`Դասակարգել`}
        onFilterChange={onFilterChange}
        onSearchClick={onFilterInputSearch}
      />
      {renderConnections()}
      <Col xs={6} className='text-center mt-3'>
        <button
          className="btn btn-transparent text-decoration-underline f-s-14 text-blue"
        >
          Մաքրել
        </button>
      </Col>
      <Col xs={6} className='text-center mt-3'>
        <button className="btn btn-blue" onClick={() => submitFilter()}>
          Դասակարգել
        </button>
      </Col>
    </Row>
  );
}

export default FilterBody;
