import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Carousel from 'react-multi-carousel-18';
import DrugInfo from './DrugInfo';

function Drugs({drugs}) {
    const renderDoctors = () => {
        return drugs.map(drug => <DrugInfo key={drug.id} drug={drug.drug ? drug.drug : drug} withMr={true}/>)
    }

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: {max: 4000, min: 3000},
            items: 5
        },
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 3
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 2
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 2
        }
    };

    return (
        <Row>
            <Col className='doctorsCarouselCol'>
                <Carousel
                    responsive={responsive}
                    showDots={false}
                    arrows={false}
                >
                    {renderDoctors()}
                </Carousel>
            </Col>

        </Row>
    );
}

export default Drugs;