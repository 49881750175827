
import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { Dna } from "react-loader-spinner";
import { useParams } from 'react-router-dom';

import DoctorsInfo from 'components/DoctorsInfo';

import { getWorkplace, getDoctors } from 'services/api';
import { setHeaderTitle } from 'features/headerTitle';

const DepartmentAndWorkplace = () => {
  const dispatch = useDispatch();
  const [ doctors, setDoctors ] = useState([]);
  const [ loader, setLoader ] = useState(false);
  const [ workplace, setWorkplace ] = useState([]);
  const { departmentSlug, workplaceSlug } = useParams();
  const department = useSelector(state => state.department.item);

  const renderDoctors = () => {
    return doctors.map((doctor, index) => (
      <Col key={doctor.id} xs={6} className={index % 2 === 0 ? "col-pd-r-7" : "col-pd-l-7"}>
        <DoctorsInfo doctor={doctor}/>
      </Col>)
    );
  }

  useEffect(() => {
    getWorkplace(dispatch, workplaceSlug).then(res => {
      setWorkplace(res);
    });
    setLoader(true);
    getDoctors(dispatch, departmentSlug, workplaceSlug).then(res => {
      setLoader(false);
      setDoctors(res);
    });
  }, []);

  useEffect(() => {
    dispatch(setHeaderTitle(workplace.titleArm));
  }, [workplace])

  return (
    <>
      {loader && 
        <Dna
          visible={true}
          height="80"
          width="80"
          ariaLabel="dna-loading"
          wrapperStyle={{}}
          wrapperClass="dna-wrapper"
        />
      }
      <Row className="mt-100">
       {department && <h2 className='body1-300-title mb-4'>{department.titleArm}</h2>} 
        {renderDoctors()} 
      </Row>
    </>
  );
}

export default DepartmentAndWorkplace;
