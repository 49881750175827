import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: []
};

const doctorsTimesSlice = createSlice({
  name: 'doctor',
  initialState,
  reducers: {
    setDoctorsTimes(state, action) {
      state.items = action.payload
    },
    resetDoctorsTimes(state) {
      state.items = []
    }
  }
});

export const { setDoctorsTimes, resetDoctorsTimes } = doctorsTimesSlice.actions;
export default doctorsTimesSlice.reducer;
