import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  title: ''
};

const workPlacesSlice = createSlice({
  name: 'workplaces',
  initialState,
  reducers: {
    setWorkPlaces(state, action) {
      state.items = action.payload.data;
      state.title = action.payload.title;
    },
    resetWorkPlaces(state) {
      state.items = [];
      state.title = '';
    },
  }
});

export const { setWorkPlaces, resetWorkPlaces } = workPlacesSlice.actions;
export default workPlacesSlice.reducer;
