import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import pen from 'assets/Textarea/text-area-pen.svg';

import './component.css';

const Textarea = ({
  value,
  label,
  change,
  placeholder,
  errorMessage
}) => {
  const ref = useRef(null);
  const [ innerValue, setInnerValue ] = useState('');

  const handleChange = (e) => {
    const { value } = e.target;
    change(value);
  };

  const handleFocus = () => {
    if (ref && ref.current) {
      ref.current.focus();
    }
  };

  useEffect(() => {
    if (!change) {
      alert('Please add change prop on Textarea component');
    }
  }, [change]);

  useEffect(() => {
    if (value === undefined) {
      alert('Please add value prop on Textarea component');
    }

    setInnerValue(_ => value);
  }, [value]);

  return (
    <div className='text-area-block'>
      {
        label && <label htmlFor={label} className='text-area-label'>{ label }</label>
      }
      <div
        className='text-area-child-block'
        style={{
          height: label ? 'calc(100% - 30px)' : '100%'
        }}
      >
        <textarea
          ref={ref}
          value={innerValue}
          onChange={handleChange}
          id={label ? label : ''}
          placeholder={placeholder}
          className='caption2-200 text-area'
        ></textarea>
        <div
          className='text-area-icon-block'
          onClick={handleFocus}
        >
          <img src={pen} alt='pen' className='text-area-icon' />
        </div>
      </div>
      {
        errorMessage ? <div className='text-area-error-message-block'>
          <p className='caption2-200 text-area-error-message'>{ errorMessage }</p>
        </div> : ''
      }
    </div>
  )
};

Textarea.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  change: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default Textarea
