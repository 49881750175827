import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fromDoctorProfile: false
};

const fromDoctorProfile = createSlice({
  name: 'fromDoctorProfile',
  initialState,
  reducers: {
    setFromDoctorProfile(state) {
      state.fromDoctorProfile = true
    },
    resetFromDoctorProfile(state) {
      state.fromDoctorProfile = false
    }
  }
});

export const { setFromDoctorProfile, resetFromDoctorProfile } = fromDoctorProfile.actions;
export default fromDoctorProfile.reducer;
