// Banners
const banners = 'banners';                                                                 // get endpoint


// Complaints
const complaints = 'complaints';                                                          // get endpoint


// Default
const complaintsUploadExcel = 'complaints/upload-excel';                                   // post endpoint
const addCollaborator = 'addCollaborator';                                                 // post endpoint
const departmentsUploadExcel = 'departments/upload-excel';                                 // post endpoint
const doctorsUploadExcel = 'doctors/upload-excel';                                         // post endpoint
const doctorsUploadDoctorsLabs = 'doctors/upload-doctorsLabs';                             // post endpoint
const doctorServicesUploadExcel = 'doctorServices/upload-excel';                           // post endpoint
const doctorServicesUploadExcelAddServices = 'doctorServices/upload-excel-add-services';   // post endpoint
const illnessesUploadExcel = 'illnesses/upload-excel';                                     // post endpoint
const labsUploadLabs = 'labs/upload-labs';                                                 // post endpoint
const labsUploadLabsWorkplaces = 'labs/upload-labsWorkplaces';                             // post endpoint
const labsUploadLabsDepartments = 'labs/upload-labsDepartments';                           // post endpoint
const labsSetLabBookmarked = 'labs/setLabBookmarked';                                      // post endpoint
const labMaterialsOneMaterial = 'labMaterials/oneMaterial'; // need param ->  /{slug}         get endpoint
const labMaterialsUploadMaterials = 'labMaterials/upload-materials';                       // post endpoint
const labMaterialsUploadTypes = 'labMaterials/upload-types';                               // post endpoint
const labMaterialsUploadServicesMaterials = 'labMaterials/upload-servicesMaterials';       // post endpoint
const labMaterialsUploadServicesTypes = 'labMaterials/upload-servicesTypes';               // post endpoint
const professionsUploadExcel = 'professions/upload-excel';                                 // post endpoint
const workplacesUploadExcel = 'workplaces/upload-excel';                                   // post endpoint
const workplacesSetWorkplaceBookmarked = 'workplaces/setWorkplaceBookmarked';              // post endpoint
const docJson = 'doc.json';                                                                // get endpoint


// Departments
const departments = 'departments';                                                         // get endpoint
const departmentsBySearch = 'departments/bySearch';                                        // get endpoint


// Doctors
const doctorCallCreate = 'doctorCall/create';                                              // post endpoint
const doctors = 'doctors';                                                                 // get endpoint
const doctorsOne = 'doctors/one';                                                          // get endpoint
const doctorsOneGallery = 'doctors/oneGallery';                                            // get endpoint
const doctorsTimes = 'doctors/times';                                                      // post endpoint
const doctorsSetDoctorBookmarked = 'doctors/setDoctorBookmarked';                          // post endpoint
const doctorsGetDoctorsForLabService = 'doctors/getDoctorsForLabService';                  // post endpoint


// Doctor profile
const doctorProfileCalls = 'doctorProfile/calls';                                          // get endpoint
const doctorPatients = 'doctorProfile/patients';                                           // get endpoint
const doctorProfileApproveOrDecline = 'doctorProfile/approveOrDecline';                    // post endpoint
const doctorProfileSetCalendar = 'doctorProfile/setCalendar';                              // post endpoiny
const doctorProfileDiagnosis = 'doctorProfile/diagnosis';                                  // get endpoint
const doctorProfileTemplates = 'doctorProfile/templates';                                  // get endpoint
const doctorProfilemedicalHistoryTemplates = 'doctorProfile/medicalHistoryTemplates';      // get endpoint
const doctorProfileAddDiagnosisTemplates = 'doctorProfile/addDiagnosisTemplates';          // get endpoint
const doctorProfileAddHistoryTemplate = 'doctorProfile/addHistoryTemplate';                // post endpoint
const doctorProfileGetAdviceTemplates = 'doctorProfile/adviceTemplates';                   // get endpoint
const doctorProfileAddAdviceTemplates = 'doctorProfile/addAdviceTemplate';                 // post endpoint
const doctorProfileEditAdviceTemplates = 'doctorProfile/editAdviceTemplate';               // put endpoint
const doctorProfileRemoveAdviceTemplates = 'doctorProfile/removeAdviceTemplate';           // delete endpoint
const doctorProfileAddAdviceToMedicalCard = 'doctorProfile/addAdviceToMedicalCard';        // post endpoint



// Drugs
const drugsDrugCategories = 'drugs/drugCategories';                                        // get endpoint
const drugsChildrenByCategory = 'drugs/childrenByCategory';                                // get endpoint
const drugsFilterConnections = 'drugs/filterConnections';                                  // get endpoint
const pharmsPharmsWithDrugs = 'pharms/pharmsWithDrugs';                                    // get endpoint


// Ilnesses
const illnesses = 'illnesses';                                                             // get endpoint


// Labs
const labCallCreate = 'labCall/create';                                                    // post endpoint
const labCallPrices = 'labCall/prices';                                                    // get endpoint
const labsWithCategories = 'labs/withCategories';                                          // get endpoint
const labsByCategory = 'labs/byCategory';                                                  // get endpoint
const labsCategory = 'labs/category';                                                      // get endpoint
const labsOne = 'labs/one';                                                                // get endpoint
const labsWithCategoriesByCart = 'labs/withCategoriesByCart';                              // get endpoint
const labMaterialsMaterialOrType = 'labMaterials/materialOrType';                          // get endpoint
const labMaterialsMaterialsAndTypes = 'labMaterials/materialAndTypes';                     // get endpoint
const labMaterialsMaterialChildren = 'labMaterials/materialChildren';                      // get endpoint


// Patient Profile
const patientCalls = 'patientProfile/calls';                                               // get endpoint
const patientDiagnosis = 'patientProfile/diagnosis';                                       // get endpoint
const patientMedicalHistories = 'patientProfile/medicalHistories';                         // get endpoint
const patientIndicatorData = 'patientProfile/indicatorFormData';                           // get endpoint
const patientAdvices = 'patientProfile/advices';                                           // get endpoint
const patientLabServices = 'patientProfile/labServices';                                   // get endpoint


// Medical Card
const doctorProfileOneMedicalCard = 'doctorProfile/oneMedicalCard';                       // get endpoint
const doctorProfileCreateMedicalCard = 'doctorProfile/createMedicalCard';                 // post endpoint
const doctorProfileAddDiagnosis = 'doctorProfile/addDiagnosis';                           // post endpoint
const doctorProfileRemoveDiagnosis = 'doctorProfile/removeDiagnosis';                     // delete endpoint
const doctorProfileAddMedicalHistory = 'doctorProfile/addMedicalHistory';                 // post endpoint
const doctorProfileMedicalCards = 'doctorProfile/medicalCards';                           // get endpoint
const doctorProfileObjectiveIndicatorForms = 'doctorProfile/objectiveIndicatorForms';     // get endpoint
const doctorProfileAddIndicatorFormData = 'doctorProfile/addIndicatorFormData';           // post endpoint
const doctorProfileAddDoctorServiceToCard = 'doctorProfile/addDoctorServiceToCard';       // post endpoint
const doctorProfileAddLabServiceToCard = 'doctorProfile/addLabServicesToCard';            // post endpoint
const doctorProfileDeleteLabServiceFromGroup = 'doctorProfile/deleteLabServiceFromGroup';              // post endpoint
const doctorProfileAddLabServiceToDoctor = 'doctorProfile/addLabServicesToDoctor';        // post endpoint
const doctorProfileRemoveLabServiceToDoctor = 'doctorProfile/removeLabServiceFromDoctor'; // post endpoint
const doctorProfileAddLabSchemaToDoctor = 'doctorProfile/addLabSchemaToDoctor';           // post endpoint
const doctorProfileAddLabServiceToSchema = 'doctorProfile/addLabServiceToSchema';         // post endpoint
const doctorProfileRemoveLabSchemaFromDoctor = 'doctorProfile/deleteLabSchema';           // post endpoint
const doctorProfileDeleteLabServiceFromSchema = 'doctorProfile/deleteLabServiceFromSchema';
const patientProfileMedicalCards = 'patientProfile/medicalCards';                         // get endpoint


// Pharms
const pharmsWithCategories = 'pharms/withCategories';                                      // get endpoint
const pharmsByCategory = 'pharms/byCategory';                                              // get endpoint
const pharmsOne = 'pharms/one';                                                            // get endpoint
const pharmsCategory = 'pharms/category';                                                  // get endpoint


// Workplaces
const workplacesWithCategories = 'workplaces/withCategories';                              // get endpoint
const workplacesByCategory = 'workplaces/byCategory';                                      // get endpoint
const workplacesOne = 'workplaces/one'; // need param ->  /{slug}                             get endpoint
const workplacesByDepartment = 'workplaces/byDepartment'; // need param ->  /{departmentSlug} get endpoint
const workplacesCategory = 'workplaces/category'; // need param ->  /{categorySlug}           get endpoint
const workplacesBySearch = 'workplaces/bySearch';                                          // get endpoint
const workplacesWithDoctorsBySearch = 'workplaces/withDoctors/bySearch';                   // get endpoint


// Users
const usersGetDetails = 'users/getDetails';


export {
  banners,

  complaints,

  complaintsUploadExcel,
  addCollaborator,
  departmentsUploadExcel,
  doctorsUploadExcel,
  doctorsUploadDoctorsLabs,
  doctorServicesUploadExcel,
  doctorServicesUploadExcelAddServices,
  illnessesUploadExcel,
  labsUploadLabs,
  labsUploadLabsWorkplaces,
  labsUploadLabsDepartments,
  labsSetLabBookmarked,
  labMaterialsOneMaterial,
  labMaterialsUploadMaterials,
  labMaterialsUploadTypes,
  labMaterialsUploadServicesMaterials,
  labMaterialsUploadServicesTypes,
  professionsUploadExcel,
  workplacesUploadExcel,
  workplacesSetWorkplaceBookmarked,
  docJson,

  departments,
  departmentsBySearch,

  doctorCallCreate,
  doctors,
  doctorsOne,
  doctorsOneGallery,
  doctorsTimes,
  doctorsSetDoctorBookmarked,
  doctorsGetDoctorsForLabService,

  doctorProfileCalls,
  doctorPatients,
  doctorProfileApproveOrDecline,
  doctorProfileSetCalendar,
  doctorProfileDiagnosis,
  doctorProfilemedicalHistoryTemplates,
  doctorProfileTemplates,
  doctorProfileAddDiagnosisTemplates,
  doctorProfileAddHistoryTemplate,
  doctorProfileMedicalCards,
  doctorProfileGetAdviceTemplates,
  doctorProfileAddAdviceTemplates,
  doctorProfileEditAdviceTemplates,
  doctorProfileRemoveAdviceTemplates,
  doctorProfileAddAdviceToMedicalCard,
  
  drugsDrugCategories,
  drugsChildrenByCategory,
  drugsFilterConnections,
  pharmsPharmsWithDrugs,
  
  illnesses,
  
  labCallCreate,
  labCallPrices,
  labsWithCategories,
  labsByCategory,
  labsCategory,
  labsOne,
  labsWithCategoriesByCart,
  labMaterialsMaterialOrType,
  labMaterialsMaterialsAndTypes,
  labMaterialsMaterialChildren,

  patientCalls,
  patientDiagnosis,
  patientMedicalHistories,
  patientIndicatorData,
  patientAdvices,
  patientLabServices,

  doctorProfileOneMedicalCard,
  doctorProfileCreateMedicalCard,
  doctorProfileAddDiagnosis,
  doctorProfileRemoveDiagnosis,
  doctorProfileAddMedicalHistory,
  doctorProfileObjectiveIndicatorForms,
  doctorProfileAddIndicatorFormData,
  doctorProfileAddDoctorServiceToCard,
  doctorProfileAddLabServiceToCard,
  doctorProfileDeleteLabServiceFromGroup,
  doctorProfileAddLabServiceToDoctor,
  doctorProfileRemoveLabServiceToDoctor,
  doctorProfileAddLabSchemaToDoctor,
  doctorProfileAddLabServiceToSchema,
  doctorProfileRemoveLabSchemaFromDoctor,
  doctorProfileDeleteLabServiceFromSchema,
  patientProfileMedicalCards,
  
  pharmsWithCategories,
  pharmsByCategory,
  pharmsOne,
  
  pharmsCategory,
  workplacesWithCategories,
  workplacesByCategory,
  workplacesOne,
  workplacesByDepartment,
  workplacesCategory,
  workplacesBySearch,
  workplacesWithDoctorsBySearch,

  usersGetDetails
}
