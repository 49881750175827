import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fromRedux: false,
  categories: []
};

const labMaterialsAndTypesSlice = createSlice({
  name: 'labMaterialsAndTypes',
  initialState,
  reducers: {
    setLabMaterialsAndTypes(state, action) {
      state.categories = action.payload
    },
    resetLabMaterialsAndTypes(state) {
      state.categories = []
    }
  }
});

export const { setLabMaterialsAndTypes, resetLabMaterialsAndTypes } = labMaterialsAndTypesSlice.actions;
export default labMaterialsAndTypesSlice.reducer;
