import React from 'react';
import PropTypes from 'prop-types';

import { handleGetDate } from 'utils';

import './component.css';

import LocationIcon from 'assets/DoctorPage/DoctorsSinglePage/locationIcon.png';


const HistoryCard = ({ item, withDoctorDetails = false, withWorkplaceDetails = false }) => {
  return (
    <div className='history-card-block mb-16'>
     {withDoctorDetails && 
        <>
          <p className='caption2-200 history-card-date'>
            { handleGetDate(item.created) }
          </p>
          {withWorkplaceDetails && 
            <p>
                <img className='locationIcon float-left' src={LocationIcon} alt='location' />
                <span className='caption2-300'>{item.workplaceDetails.titleArm}</span>
            </p>
          }
           <p className='caption2-300 history-card-name'>

           { item.doctorDetails.titleArm }
            </p>

          <p className='caption3-200 history-card-profession'>
            { item.doctorDetails.profession.titleArm }
          </p>
        </>
      }
     
      <p className='caption3-100 history-card-description float-left'>
        { item.description }
      </p>
    </div>
  )
};

HistoryCard.propTypes = {
  item: PropTypes.object
};

export default HistoryCard
