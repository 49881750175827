import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fromP: false
};

const fromPatientSlice = createSlice({
  name: 'fromPatient',
  initialState,
  reducers: {
    setFromPatient(state) {
      state.fromP = true
    },
    resetFromPatient(state) {
      state.fromP = false
    }
  }
});

export const { setFromPatient, resetFromPatient } = fromPatientSlice.actions;
export default fromPatientSlice.reducer;
