import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  item: JSON.parse(localStorage.getItem('userDetails'))
};

const userDetailsSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserDetails(state, action) {
      state.item = action.payload
    },
    resetUserDetails(state) {
      state.item = {}
    }
  }
});

export const { setUserDetails, resetUserDetails } = userDetailsSlice.actions;
export default userDetailsSlice.reducer;
