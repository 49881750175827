import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setDepartment } from 'features/department';

import './component.css';

const DepItem = ({ item, handleSetActive, useAsLink = false, link = '/' }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [ active, setActive ] = useState(false);

  const handleCheck = () => {
    setActive(prev => {
      if (prev) {
        handleSetActive(item.titleArm, 'notActive');
      } else {
        handleSetActive(item.titleArm, 'Active');
      }
      return !prev;
    });
  };

  const handleClick = (item) => {
    dispatch(setDepartment(item));
    navigate(link);
  };

  useEffect(() => {
    setActive(_ => false);
  }, []);

  return (
    <div
      onClick={_ => useAsLink ? handleClick(item) : handleCheck(item)}
      className='card-service-item'
      style={{
        color: active ? '#fff' : '#152534',
        backgroundColor: active ? '#218cc0' : '#fff'
      }}
    >
      <p className='caption2-200 text-center'>{item.titleArm}</p>
    </div>
  )
}

export default DepItem
