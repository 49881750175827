import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { handleGetDate } from './../../utils';

import MedCard from 'components/MedicalCard';
import FilterTabs from 'components/FilterTabs';
import MyModal from 'components/Global/MyModal';
import SideSettings from 'components/SideSettings';
import FilterSearch from 'components/FilterSearch';
import CalendarForm from 'components/CalendarForm';
import CreateService from 'components/CreateService';

import file from 'assets/DoctorPage/file.svg';
import calendar from 'assets/DoctorPage/calendar.svg';

import './component.css';

const MedicalCards = () => {
  const navigate = useNavigate();
  const { items } = useSelector(state => state.medCards);
  const user = useSelector(state => state.userDetails.item);

  const [ filterTabs, setFilterTabs ] = useState([]);
  const [ filterItem, setFilterItem ] = useState('');
  const [ showTimeModal, setShowTimeModal ] = useState(false);
  const [ activeFilter, setActiveFilter ] = useState(items && items[0] && items[0].statusTitle);

  const handleSetActive = (active) => {
    setActiveFilter(_ => active);
  };

  const handleFilter = (searchText, type) => {
    const value = type === 'calendar' ? handleGetDate(searchText, true) : searchText;
    setFilterItem(_ => value);
  };

  const handleCallCalendar = () => {
    setShowTimeModal(true);
  };

  const timeModalBody = <div className='myCalendar'>
    <CalendarForm
      onlyDate
      filterDate
      date={new Date()}
      submitBtnTitle={`Դասակարգել`}
      onCancel={() => setShowTimeModal(false)}
      onSubmit={() => setShowTimeModal(false)}
      setDate={(val) => handleFilter(val, 'calendar')}
    />
  </div>;

  useEffect(() => {
    if (!items.length) {
      navigate('/doctorAccount');
    }
  }, [items]);

  useEffect(() => {
    if (items && items.length) {
      const tabs = items.map(item => item.statusTitle);
      setFilterTabs(_ => tabs);
    }
  }, [items]);

  return (
    <div className='medical-block mt-100'>
      <MyModal
        centered
        show={showTimeModal}
        body={timeModalBody}
        onHide={() => setShowTimeModal(false)}
      />

      <SideSettings />

      <CreateService
        icon={file}
        name='Բժշկական քարտեր'
      />

      <div className='medical-search-block'>
        <FilterSearch
          value={filterItem}
          placeholder={'Որոնել'}
          filterCall={(val) => handleFilter(val, '')}
        />

        <div style={{ width: '35px', textAlign: 'right' }} onClick={handleCallCalendar}>
          <img src={calendar} alt='calendar' />
        </div>
      </div>

      {
        filterTabs && filterTabs.length > 0 && <FilterTabs
          tabs={filterTabs}
          checkActive={handleSetActive}
        />
      }

      {
        items && items.length > 0 && items.filter(status => status.statusTitle === activeFilter).map((status, index) => {
          let finalMedCards = status.medCards;
          if (filterItem !== '') {
            finalMedCards = finalMedCards.filter(medCard => medCard.patient.titleArm.includes(filterItem) || 
              (medCard.patient.ssn && medCard.patient.ssn.includes(filterItem)) ||
              medCard.code.includes(filterItem) || 
              handleGetDate(medCard.created).includes(filterItem)
            )
          }
          return <div key={`${status.statusTitle}_${index}`}>
              {                
                finalMedCards.map((lastItem, lastIndex) => {
                  return <MedCard
                      item={lastItem}
                      withAction
                      key={`${lastItem.created}_${index}`}
                      withWorkplaceDetails={user && user.roles.includes('ROLE_PATIENT')}
                    />
                  })
               }
          </div>
        })
      } 
    </div>
  )
};

export default MedicalCards
