import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import Button from 'components/Button';
import { useDispatch } from 'react-redux';
import { setDocGallery } from 'features/docGallery';
import { setHeaderTitle } from 'features/headerTitle';

const BaseDomain = `${process.env.REACT_APP_BASE_DOMAIN}`;


const GalleryItem = ({
  item,
  gallery,
  doctorSlug,
  withoutLink = false
}) => {
  const dispatch  = useDispatch();
  const navigate = useNavigate();
  
  const handleMoreClick = () => {
    let link = `/doctor/oneGallery/${doctorSlug}/${gallery.slug}`;
    navigate(link);
  }
    

  return (
    <Row className='mb24' key={item.id}>
      <Col className='pd-l-7'>
        <img className={`img-fluid beforeAndAfterImage`} src={BaseDomain + item.beforeImagePath} alt="before" />
        <p className="beforeAfterP text-center f-s-14 mt8">Առաջ</p>
      </Col>
      <Col className='pd-r-7'>
        <img className={`img-fluid beforeAndAfterImage`} src={BaseDomain + item.afterImagePath} alt="before" />
        <p className="beforeAfterP text-center f-s-14 mt8">Հետո</p>
      </Col>
      {!withoutLink && <Col xs={12} className="mt16 text-center">
        <Button
          text='Ավելին'
          click={handleMoreClick}
        />
      
      </Col>}

    </Row>
  );
};

export default GalleryItem;
