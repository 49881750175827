import React from 'react';
import PropTypes from 'prop-types';

import close from 'assets/Advice/close.svg';
import pen from 'assets/Textarea/text-area-pen.svg';

import './component.css'; 

const AdviceCard = ({
  id,
  openModal,
  handleEdit,
  description,
}) => {
  return (
    <div
      className='advice-card-block'
    >
      <div className='advice-card-block-item'>
        <p className='advice-card-block-item-desc caption3-100'>
          { description }
        </p>
      </div>
      <div
        className='advice-card-block-item'
        onClick={() => handleEdit(id)}
      >
        <img className='advice-card-block-item-img' src={pen} alt='pen' />
      </div>
      <div
        className='advice-card-block-item'
        onClick={() => openModal(id)}
      >
        <img className='advice-card-block-item-img' src={close} alt='close' />
      </div>
    </div>
  )
}

export default AdviceCard

AdviceCard.propTypes = {
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired
};
