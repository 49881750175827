import React from "react";
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";


import './component.css';

const BaseDomain = `${process.env.REACT_APP_BASE_DOMAIN}`;

const WorkplaceCard = ({workplace, link, containerClasses = '', withContacts = false, titleClasses = 'caption2-200' }) => {
    return (
        <div className={`${containerClasses}`}>
            <div className="workplaceView">
                <Link to={link} className="text-decoration-none">
                    <div className=" text-center">
                        <img className={`workplace-card-img`} src={BaseDomain + "/" + workplace.imagePath} alt={workplace.titleArm} />
                        <p className={`mt-3 ${titleClasses}`}>{workplace.titleArm}</p>
                    </div>
                </Link>

                {withContacts && 
                    <>
                        <p className="mt-3">
                            <svg className="mr-4" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.98646 4.93691C6.33604 5.74783 5.9999 6.86838 5.9999 8.03636C5.9999 10.4138 7.09385 12.539 8.24502 14.1087C8.81582 14.887 9.3875 15.5108 9.81558 15.9389C9.88062 16.0039 9.94222 16.0643 9.9999 16.1199C10.0576 16.0643 10.1192 16.0039 10.1842 15.9389C10.6123 15.5108 11.184 14.887 11.7548 14.1087C12.906 12.539 13.9999 10.4138 13.9999 8.03636C13.9999 6.86838 13.6637 5.74783 13.0133 4.93691C12.3814 4.14909 11.4133 3.6 9.9999 3.6C8.58646 3.6 7.61837 4.14909 6.98646 4.93691ZM9.9999 17.2C9.49334 17.8192 9.4931 17.819 9.49286 17.8188L9.49086 17.8171L9.48662 17.8137L9.4731 17.8024C9.46182 17.793 9.44614 17.7798 9.4263 17.7628C9.38654 17.7289 9.3303 17.68 9.25974 17.6168C9.11878 17.4905 8.92054 17.3066 8.68422 17.0702C8.21232 16.5983 7.58401 15.913 6.95477 15.0549C5.70595 13.3519 4.3999 10.8953 4.3999 8.03636C4.3999 6.58617 4.81377 5.08853 5.73834 3.93582C6.68144 2.76001 8.11331 2 9.9999 2C11.8865 2 13.3184 2.76001 14.2614 3.93582C15.1861 5.08853 15.5999 6.58617 15.5999 8.03636C15.5999 10.8953 14.2938 13.3519 13.045 15.0549C12.4158 15.913 11.7875 16.5983 11.3156 17.0702C11.0793 17.3066 10.881 17.4905 10.7401 17.6168C10.6695 17.68 10.6133 17.7289 10.5735 17.7628C10.5537 17.7798 10.538 17.793 10.5267 17.8024L10.5132 17.8137L10.5089 17.8171L10.5075 17.8183C10.5073 17.8186 10.5065 17.8192 9.9999 17.2ZM9.9999 17.2L10.5065 17.8192C10.2118 18.0602 9.78758 18.0599 9.49286 17.8188L9.9999 17.2Z" fill="#218CC0"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99985 6.8002C9.55801 6.8002 9.19985 7.15837 9.19985 7.6002C9.19985 8.04202 9.55801 8.4002 9.99985 8.4002C10.4417 8.4002 10.7999 8.04202 10.7999 7.6002C10.7999 7.15837 10.4417 6.8002 9.99985 6.8002ZM7.59985 7.6002C7.59985 6.27472 8.67433 5.2002 9.99985 5.2002C11.3254 5.2002 12.3999 6.27472 12.3999 7.6002C12.3999 8.92572 11.3254 10.0002 9.99985 10.0002C8.67433 10.0002 7.59985 8.92572 7.59985 7.6002Z" fill="#218CC0"/>
                            </svg>
                            <span className='f-s-11 text-black'>{workplace.addressArm}</span>
                        </p>

                        <p>
                        <svg className="mr-4" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M13.0629 13.345L12.568 14.0873C12.4693 14.2355 12.3386 14.3619 12.1665 14.4074C11.6025 14.5564 10.1053 14.6237 7.82591 12.3443C5.5465 10.0649 5.61377 8.5677 5.76278 8.00376C5.80828 7.83159 5.93471 7.70095 6.08287 7.60217L6.82519 7.1073C7.37786 6.73885 7.52721 5.99213 7.15876 5.43946L5.75677 3.33647C5.43517 2.85407 4.81519 2.66977 4.2823 2.89815L3.64985 3.1692C3.16941 3.3751 2.7775 3.74463 2.54374 4.21216C2.34844 4.60275 2.19707 5.01408 2.17656 5.4503C2.11193 6.825 2.41337 10.2119 6.18588 13.9844C9.95838 17.7569 13.3452 18.0583 14.7199 17.9937C15.1561 17.9731 15.5675 17.8218 15.9581 17.6265C16.4256 17.3927 16.7951 17.0008 17.001 16.5204L17.2721 15.8879C17.5004 15.355 17.3161 14.735 16.8337 14.4134L14.7308 13.0115C14.1781 12.643 13.4314 12.7924 13.0629 13.345Z" stroke="#218CC0" stroke-width="1.8"/>
</svg>
                            <span className='f-s-11 text-black'>{workplace.phone}</span>
                        </p>
                    </>
                }
            </div>
        </div>
    )
}


WorkplaceCard.propTypes = {
    workPlace: PropTypes.object,
    link: PropTypes.string,
    containerClasses: PropTypes.string,
}

export default WorkplaceCard;