import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

import lab from 'assets/Footer/Menu_Icon2.png';
import home from 'assets/Footer/Menu_Icon5.png';
import activeLab from 'assets/Footer/activeLabIcon.png';
import footer_icon3 from 'assets/Footer/Menu_Icon3.png';
import footer_icon4 from 'assets/Footer/Menu_Icon4.png';
import footer_icon5 from 'assets/Footer/Menu_Icon4.1.png';
import activeHome from 'assets/Footer/activeHomeIcon.png';
import activePharm from 'assets/Footer/activePharmIcon.png';

import './component.css';

const Footer = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const { item } = useSelector(state => state.userDetails);

  const paths = [
    '/login',
    '/addRequest',
    '/historiesTemplate',
    '/addHistoryTemplate',
    '/addServicesToMedCard'
  ];

  return (
    paths.includes(pathname) ? '' :
    <nav className='fixed-bottom'>
      <div className='footer'>
        <Row>
          <Col className='text-center'>
            <Link to='/'>
              <img src={pathname === '/' ? activeHome : home} className='icon' alt='home' />
            </Link>
          </Col>
          <Col className='text-center'>
            <Link to='/lab'>
              <img src={pathname === '/lab' ? activeLab : lab} className='icon' alt='lab' />
            </Link>
          </Col>
          <Col className='text-center'>
            <Link to='/pharm'>
              <img src={pathname === '/pharm' ? activePharm : footer_icon3} className='icon' alt='pharm' />
            </Link>
          </Col>
          <Col className='text-center'>
              <Link to={item && item.roles ? item.roles.includes('ROLE_DOCTOR') ? '/doctorAccount' : '/patientAccount' : '/login'}>
              <img
                alt='account'
                className='icon'
                src={
                  pathname === '/doctorAccount' || pathname === '/patientAccount' ?
                  footer_icon5 :
                  footer_icon4
                }
              />
            </Link>
          </Col>
        </Row>
      </div>
    </nav>
  );
}

export default Footer;
