import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: '',
  slug: null
};

const labSchemaNameSlice = createSlice({
  name: 'labSchemaName',
  initialState,
  reducers: {
    setLabSchemaName(state, action) {
      state.name = action.payload
    },
    resetLabSchemaName(state) {
      state.name = ''
    },
    setLabSchemaSlug(state, action) {
      state.slug = action.payload
    },
    resetLabSchemaSlug(state) {
      state.slug = null
    }
  }
});

export const { setLabSchemaName, resetLabSchemaName, setLabSchemaSlug, resetLabSchemaSlug } = labSchemaNameSlice.actions;
export default labSchemaNameSlice.reducer;
