import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import openEye from 'assets/Login/open-eye.svg';
import closeEye from 'assets/Login/close-eye.svg';

import './component.css';

const types = {
  text: 'text',
  dynamic: 'dynamic',
  password: 'password'
}

const Input = ({
  type,
  name,
  unit,
  error,
  label,
  value,
  change,
  errorText,
  placeholder,
  calendarMode,
  calendarCall
}) => {
  const [ opened, setOpened ] = useState(false);
  const [ innerValue, setInnerValue ] = useState('');
  const [ innerType, setInnerType ] = useState(type);
  const [ showLabel, setShowLabel ] = useState(false);
  const [ innerError, setInnerError ] = useState(false);
  const [ innerErrorText, setInnerErrorText ] = useState('');

  const handleOpened = () => {
    setOpened(_ => !opened);
    if (innerType === 'password') {
      setInnerType('text');
    } else {
      setInnerType('password');
    }
  };

  const handleFocus = () => {
    if (innerValue === '' || innerValue === undefined) {
      setShowLabel(false);
    } else {
      setShowLabel(true);
    }
    if (calendarMode && calendarCall) {
      calendarCall();
    }
  };

  const handleBlur = () => {
    setShowLabel(false);
  };

  const handleChange = (e) => {
    const val = e.target.value;
    if (val !== '') {
      setShowLabel(true);
    } else {
      setShowLabel(false);
    }
    change(e);
    setInnerValue(val);
  };

  useEffect(() => {
    setShowLabel(false);
    handleBlur();
  }, []);

  useEffect(() => {
    setInnerValue(_ => value);
    if (calendarMode && change) {
      change(value);
    }
  }, [value]);

  useEffect(() => {
    if (!change) {
      // TODO: change alert to normal project modal
      alert('Please add change prop on Input component');
    }
  }, [change]);

  useEffect(() => {
    if (type) {
      setInnerType(_ => type);
    }
  }, [type]);

  useEffect(() => {
    setInnerError(_ => error);
    setInnerErrorText(_ => errorText);
  }, [error, errorText]);

  return (
    <div className='input-component-block'>
      {
        showLabel && <label htmlFor={name} className='input-label input-fonts'>
          { label }
        </label>
      }
      <div
        className='input-wrap-block'
        style={{
          borderBottom: `0.5px solid ${innerError ? 'var(--colors-3-2)' : 'var(--colors-4-2)'}`
        }}
      >
        <input
          id={name}
          name={name}
          type={innerType}
          autoComplete='off'
          value={innerValue}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          placeholder={placeholder}
          className='input input-fonts'
          style={{
            color: `${innerError ? 'var(--colors-3-2)' : 'var(--colors-4-2)'}`,
            paddingRight: type === 'password' ? '40px' : type === 'dynamic' ? '70px' : '0px'
          }}
        />
        {
          type === 'password' && <div className='eye-block' onClick={handleOpened}>
            {
              opened ?
              <img src={openEye} alt='open eye' /> :
              <img src={closeEye} alt='close eye' />
            }
          </div>
        }

        {
          type === 'dynamic' && <div className='dynamic-unit-block'>
            <span className='caption1-200 dynamic-unit'>{ unit }</span>
          </div>
        }
      </div>
      {
        innerError && innerErrorText && <div className='error-block'>
          <p className='error-text'>{ innerErrorText }</p>
        </div>
      }
    </div>
  )
}

Input.propTypes = {
  error: PropTypes.bool,
  unit: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  errorText: PropTypes.string,
  calendarMode: PropTypes.bool,
  calendarCall: PropTypes.func,
  placeholder: PropTypes.string,
  change: PropTypes.func.isRequired,
  type: PropTypes.oneOf(Object.values(types))
}

Input.defaultProps = {
  type: 'text'
}

export default Input
