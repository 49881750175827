import React from "react";
import PropTypes from 'prop-types';

import './component.css';

import FB from "../../assets/Social/facebook.png";
import Instagram from "../../assets/Social/instagram.png";
import twitter from "../../assets/Social/twitter.png";
import Whatsapp from "../../assets/Social/whatsapp.png";
import Viber from "../../assets/Social/viber.png";
import Telegram from "../../assets/Social/telegram.png";

const WorkplaceSocialBlock = ({workplace}) => {
    return (
        <div className="col">
            <div className={`socialsDiv`}>
                {workplace.FB &&
                    <a href={workplace.FB} target="_blank" rel='noreferrer'>
                        <img src={FB} alt="FB"/>
                    </a>
                }

                {workplace.instagram &&
                    <a href={workplace.instagram} target="_blank" rel='noreferrer'>
                        <img src={Instagram} alt="instagram"/>
                    </a>
                }

                {workplace.twitter &&
                    <a href={workplace.twitter} target="_blank" rel='noreferrer'>
                        <img src={twitter} alt="twitter"/>
                    </a>
                }

                {workplace.whatsapp &&
                    <a href={`whatsapp://send?abid=${workplace.whatsapp}`}>
                        <img src={Whatsapp} alt="whatsapp"/>
                    </a>
                }

                {workplace.viber &&
                    <a href={`viber://contact?number=%2B${workplace.viber}`}>
                        <img src={Viber} alt="viber"/>
                    </a>
                }

                {workplace.telegram &&
                    <a target={`_blank`} href={`https://telegram.me/${workplace.telegram}`}>
                        <img src={Telegram} alt="telegram"/>
                    </a>
                }
            </div>
        </div>
    );
}

WorkplaceSocialBlock.propTypes = {
    workplace: PropTypes.object
};

export default WorkplaceSocialBlock;