import { configureStore } from '@reduxjs/toolkit';

import {
  modalSlice,
  loaderSlice,
  bannerSlice,
  doctorSlice,
  labCartSlice,
  medCardsSlice,
  diagnosisSlice,
  oneMedCardSlice,
  docGallerySlice,
  workPlacesSlice,
  departmentSlice,
  fromPatientSlice,
  userDetailsSlice,
  departmentsSlice,
  headerTitleSlice,
  labMaterialSlice,
  addDiagnosisSlice,
  sideSettingsSlice,
  doctorsTimesSlice,
  applicationsSlice,
  tempTemplateSlice,
  doctorPatientSlice,
  labSchemaNameSlice,
  doctorServicesSlice,
  patientAdvicesSlice,
  tempModalTitleSlice,
  doctorPatientsSlice,
  labCartServicesSlice,
  applicationTypeSlice,
  doctorTemplatesSlice,
  patientDiagnosisSlice,
  historiesTemplateSlice,
  fromDoctorProfileSlice,
  addMedCardOrDoctorSlice,
  patientLabServicesSlice,
  objectiveIndicatorsSlice,
  patientIndicatorDataSlice,
  labMaterialsAndTypesSlice,
  historiesTemplateTypeSlice,
  doctorAdviceTemplatesSlice,
  patientMedicalHistoriesSlice,
} from 'features';

export const store = configureStore({
  reducer: {
    modal: modalSlice,
    loader: loaderSlice,
    banner: bannerSlice,
    doctor: doctorSlice,
    labCart: labCartSlice,
    medCards: medCardsSlice,
    diagnosis: diagnosisSlice,
    oneMedCard: oneMedCardSlice,
    docGallery: docGallerySlice,
    workPlaces: workPlacesSlice,
    department: departmentSlice,
    userDetails: userDetailsSlice,
    departments: departmentsSlice,
    headerTitle: headerTitleSlice,
    fromPatient: fromPatientSlice,
    labMaterial: labMaterialSlice,
    tempTitle: tempModalTitleSlice,
    addDiagnosis: addDiagnosisSlice,
    sideSettings: sideSettingsSlice,
    doctorsTimes: doctorsTimesSlice,
    applications: applicationsSlice,
    tempTemplate: tempTemplateSlice,
    doctorPatient: doctorPatientSlice,
    labSchemaName: labSchemaNameSlice,
    doctorPatients: doctorPatientsSlice,
    doctorServices: doctorServicesSlice,
    patientAdvices: patientAdvicesSlice,
    applicationType: applicationTypeSlice,
    labCartServices: labCartServicesSlice,
    doctorTemplates: doctorTemplatesSlice,
    patientDiagnosis: patientDiagnosisSlice,
    fromDoctorProfile: fromDoctorProfileSlice,
    historiesTemplate: historiesTemplateSlice,
    patientLabServices: patientLabServicesSlice,
    addMedCardOrDoctor: addMedCardOrDoctorSlice,
    objectiveIndicators: objectiveIndicatorsSlice,
    labMaterialsAndTypes: labMaterialsAndTypesSlice,
    patientIndicatorData: patientIndicatorDataSlice,
    historiesTemplateType: historiesTemplateTypeSlice,
    doctorAdviceTemplates: doctorAdviceTemplatesSlice,
    patientMedicalHistories: patientMedicalHistoriesSlice,
  }
})
