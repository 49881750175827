import React from 'react';

import Banner from 'components/Banner';
import CatsWithWorkplaces from 'components/CatsWithWorkplaces';
import NewDepartments from 'components/NewDepartments';

// import HomeVisitBanner from 'components/HomeVisitBanner';

import './component.css';

const Home = () => {
    return (
        <>
            <Banner containerClasses='mt-100' />
            {/* <HomeVisitBanner/> */}
            <NewDepartments 
                showMainTitle
                moreWithNewPage
                link='/department'
                useAsLink
                linkHasSlug
            />
            <CatsWithWorkplaces />
        </>
    );
}

export default Home;
