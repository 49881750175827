import React from "react";
import PropTypes from 'prop-types'
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import './component.css';

const WorkplaceCategory = ({category, link, containerClasses}) => {
    const BaseDomain = `${process.env.REACT_APP_BASE_DOMAIN}`;

    return (
        <Col sm={12} className={containerClasses}>
            <Link to={link} className="text-decoration-none">
                <img className="categoryImage" src={BaseDomain + "/" + category.imagePath} alt={category.titleArm} />
                <h2 className="body1-300-title fw-bold mb-4">{category.titleArm}</h2>
            </Link>
        </Col>
    );
}

WorkplaceCategory.propTypes = {
    category: PropTypes.object,
    link: PropTypes.string,
    containerClasses: PropTypes.string,
};

export default WorkplaceCategory;