import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setHeaderTitle } from "features/headerTitle";

import AnamnezBlock from "components/AnamnezData";

const PatientAdvices = () => {
    const dispatch = useDispatch();
    const advices = useSelector(state => state.patientAdvices.items);
  
    useEffect(() => {
        dispatch(setHeaderTitle('Խորհուրդներ'));
    }, []);

    return (
        <div className="mt-100">
            <AnamnezBlock
              histories={advices}
              activeFilter={null}
              filterHistories={[]}
              withDoctorDetails
              withAddButton={false}
              withWorkplaceDetails
            /> 
        </div>
    );
}

export default PatientAdvices;