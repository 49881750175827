import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  item: {}
};

const addDiagnosisSlice = createSlice({
  name: 'addDiagnosis',
  initialState,
  reducers: {
    setAddDiagnosis(state, action) {
      state.item = action.payload
    },
    resetAddDiagnosis(state) {
      state.item = {}
    }
  }
});

export const { setAddDiagnosis, resetAddDiagnosis } = addDiagnosisSlice.actions;
export default addDiagnosisSlice.reducer;
