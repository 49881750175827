import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: []
};

const doctorTemplatesSlice = createSlice({
  name: 'doctorTemplates',
  initialState,
  reducers: {
    setDoctorTemplates(state, action) {
      state.items = action.payload
    },
    resetDoctorTemplates(state) {
      state.items = []
    }
  }
});

export const { setDoctorTemplates, resetDoctorTemplates } = doctorTemplatesSlice.actions;
export default doctorTemplatesSlice.reducer;
