import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './component.css';

const BaseDomain = `${process.env.REACT_APP_BASE_DOMAIN}`;


const FilterCard = ({
  item,
  itemHasImage = false,
  checkActive,
  active = false
}) => {
  const [ innerItem, setInnerItem ] = useState({});

  const handleCheckActive = () => {
    checkActive(item);
  };

  useEffect(() => {
    setInnerItem(_ => item);
  }, [item]);

  let finalIcon;

  if (itemHasImage) {
    finalIcon = active ? BaseDomain + innerItem.activeImagePath : BaseDomain + innerItem.imagePath;
  } else {
    finalIcon = active ? innerItem.choosenIcon : innerItem.icon;
  }

  return (
    <div
      className='diagnos-card'
      onClick={handleCheckActive}
      style={{
        backgroundColor: active ? '#218CC0' : 'white'
      }}
    >
      <div className='diagnos-card-icon-block'>
        <img src={finalIcon} alt='filterCard' className='diagnos-card-icon' />
      </div>

      <p
        className='caption3-200 diagnos-card-text'
        style={{
          color: active ? 'white' : '#152534'
        }}
      >
        { innerItem?.titleArm }
      </p>
    </div>
  )
};

FilterCard.propTypes = {
  item: PropTypes.object,
  itemHasImage: PropTypes.bool,
  checkActive: PropTypes.func,
  active: PropTypes.bool,
};

export default FilterCard
