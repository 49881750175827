import React from "react";
import {Link} from "react-router-dom";

import "./titleWithBorder.css";

function TitleWithBorder({title, colClass = "col", classes = "", link = null, onclick = null}) {
    return (
        <div className={`${colClass} ${classes}`}>
            {link ?
                <Link to={link} className="titleLink text-decoration-none">
                    <div className="box">
                        <p className="title">{title}</p>
                    </div>
                </Link> :
                <div className="box" onClick={onclick}>
                    <p className="title">{title}</p>
                </div>
            }

        </div>
    );
}

export default TitleWithBorder;
