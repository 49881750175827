import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import './component.css';

const types = {
  button: 'button',
  submit: 'submit'
}

const Button = ({
  type,
  text,
  click,
  classUI,
  bgColor,
  disabled,
}) => {

  const handleClick = () => {
    !disabled && click();
  };

  useEffect(() => {
    if (!click) {
      // TODO: change alert to normal modal
      alert('Please add click prop on Button component');
    }

    if (!text && text !== '') {
      // TODO: change alert to normal modal
      alert('Please add text prop on Button component');
    }
  }, []);

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={handleClick}
      className={`button ${bgColor && 'btn-bg-color'} ${disabled && 'btn-disabled-bg-color'} ${classUI}`}
    >
      { text }
    </button>
  )
}

Button.propTypes = {
  bgColor: PropTypes.bool,
  disabled: PropTypes.bool,
  classUI: PropTypes.string,
  click: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(types))
}

Button.defaultProps = {
  classUI: '',
  type: 'button',
  disabled: false,
}

export default Button
