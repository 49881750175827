import React, { useState, useEffect } from 'react';
import Carousel from 'react-multi-carousel-18';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Row, Form, InputGroup } from 'react-bootstrap';

import {
  getDrugsDrugCategories,
  getDrugsChildrenByCategory
} from 'services/api';

import Drugs from './Drugs';
import SearchBlock from '../Search/SearchBlock';
import TitleWithBorder from '../Global/titleWithBorder';

import search from 'assets/Header/Ellipse.png';
import remove from 'assets/SearchBar/Remove.png';
import removeBlack from 'assets/SearchBar/removeBlack.png';

import 'react-multi-carousel-18/lib/styles.css';

const DrugCategories = ({
  title = null,
  link = false,
  defaultSlug = '',
  selectedConnections = {}
}) => {
  const history = useNavigate();
  const [ children, setChildren ] = useState([]);
  const [ category, setCategory ] = useState(null);
  const [ categories, setCategories ] = useState([]);
  const [ oldCategory, setOldCategory ] = useState(null);
  const [ searchString, setSearchString ] = useState('');

  const handleCategoryClick = (oldCategory, category) => {
      setOldCategory(oldCategory);
      setCategory(category)
  };
  
  const renderCategories = () => {
      if (categories.length > 0) {
          return categories.map(function (oneCategory) {
                  let oneLink = '/pharm/category/' + oneCategory.slug;
                  let activeClass = (category && oneCategory.slug === category.slug) ? 'active' : '';
                  
                  return <SearchBlock
                      classes={`mb40 ${activeClass}`}
                      object={oneCategory}
                      key={oneCategory.id}
                      link={!link ? link : oneLink}
                      defaultSlug={defaultSlug}
                      onClick={() => handleCategoryClick(null,oneCategory)}
                  />
              }
          );
      }
  };
  
  const getChildrenByCategory = (drugName = null) => {
    let params = selectedConnections;
    params.slug = category.slug;
    if (drugName) {
        params.drugName = drugName;
    }
    getDrugsChildrenByCategory(params)
    .then(res => {
      if (res.children) {
        setChildren(res.children)
      } else {
        history(res.history);
      }
    })
    .catch(e => {
      console.log(e);
    })
  };

  const responsive = {
      superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: {max: 4000, min: 3000},
          items: 5
      },
      desktop: {
          breakpoint: {max: 3000, min: 1024},
          items: 3
      },
      tablet: {
          breakpoint: {max: 1024, min: 464},
          items: 3
      },
      mobile: {
          breakpoint: {max: 464, min: 0},
          items: 3
      }
  };

  const renderChildren = () => {
      return children.length > 0 && children.map(function (one, index) {
              let activeClass = '';//(material2 && one.slug === material2.slug) ? 'activeTitle' : '';
              return <TitleWithBorder
                  key={one.id}
                  classes={index % 2 === 0 ? 'col-pd-r-7 mb16 ' + activeClass : 'col-pd-l-7 mb16 ' + activeClass}
                  colClass='col-6'
                  title={one.titleArm}
                  onclick={() => handleCategoryClick(category, one)}
              />
          }
      );
  };

  const renderCatsWithDrugs = () => {
      return  children.length > 0 && children.map(category =>
          <Col xs={12} key={category.id}>
              <h3 className='departmentWithDoctorsTitle'>
                  <Link to={`/pharmsWithDrugs/category/${category.slug}`}>{category.titleArm}</Link>
              </h3>
              <Drugs drugs={category.drugsArray}/>
          </Col>);
  };

  const handleSearchTyping = (value) => {
    setSearchString(value);
  };

  const handleRemoveClick = () => {
    setSearchString('');
    getChildrenByCategory(null);
  };

  const handleSearchClick = () => {
    if (searchString !== '') {
      getChildrenByCategory(searchString);
    }
  };

  useEffect(() => {
    if (!categories.length) {
      getDrugsDrugCategories()
      .then(res => {
        setCategories(res);
      })
      .catch(e => {
        console.log(e);
      });
    }
    return () => {
      setCategory(null);
      setCategories([]);
    }
  }, []);

  useEffect(() => {
    if (category) {
      getChildrenByCategory();
    }
  }, [category, selectedConnections]);

  useEffect(() => {
    let defaultCategory = null;

    if (categories.length) {
      defaultCategory = categories.find(obj => {
        return obj.slug === defaultSlug
      })
    }
    setCategory(defaultCategory)
  }, [categories, defaultSlug]);

  return (
    <>
      {
        categories.length > 0 && <>
          <Row className='mb32 mt32'>
            <Col xs={12}>
                {title && <p className='fw-bold f-s-16 mb-3'>{title}</p>}
                <Carousel
                    responsive={responsive}
                    showDots={false}
                    arrows={false}
                    className='searchCarousel'
                >
                    {categories.length > 0 && renderCategories()}
                </Carousel>
            </Col>
            {
              category && <>
                <Col xs={12}>
                  <h2 className='f-s-18 fw-bold mb24'>Ֆիլտրել խմբավորումը</h2>
                  {
                    category && <div className='mb-3'>
                      <span className='selectedFilters'>
                        {category.titleArm}
                        <img
                          src={removeBlack}
                          alt='removeBlack'
                          className='removeBlack'
                          onClick={() => handleCategoryClick(null, oldCategory)}
                        />
                      </span>
                    </div> 
                  }
                </Col>
                {
                  children.length > 0 && <>
                    <Col xs={10} className={`pr0`}>
                      <div className='detailsBlock'>
                        <InputGroup className='searchInputGroup'>
                          <Form.Control
                            value={searchString}
                            placeholder='Որոնել'
                            className='border-none pl0'
                            onChange={(e) => handleSearchTyping(e.target.value)}
                          />
                          <img
                            src={remove}
                            alt='remove'
                            className='removeIcon'
                            onClick={() => handleRemoveClick()}
                          />
                        </InputGroup>
                      </div>
                    </Col>
                    <Col xs={2}>
                      <img
                        src={search}
                        alt='search'
                        className='searchIcon mt0'
                        onClick={() => handleSearchClick()}
                      />
                    </Col>
                    {renderChildren()}
                  </>
                } 
              </>
            }
          </Row>
          <Row className='mt-32'>
            {category && renderCatsWithDrugs()}
          </Row>
        </>
      }
    </>
  );
};

export default DrugCategories;
