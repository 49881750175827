import axios from 'axios';

export const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  headers: { 'Content-Type': 'application/json' }
});

export const axiosLogin = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_DOMAIN}`,
  headers: { 'Content-Type': 'application/json' }
});
