import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { handleGetDate } from 'utils';
import {
  getTimes,
  getDoctor,
  setDoctorCall,
  setDoctorBookmarked
} from 'services/api';
import { resetDoctorsTimes } from 'features/doctorsTimes';

import FilterTabs from 'components/FilterTabs';
import DoctorInfo from 'components/DoctorInfo';
import MyModal from 'components/Global/MyModal';
import DoctorGallery from 'components/DoctorGallery';
import CreateCallForm from 'components/CreateCallForm';
import DoctorsSocialBlock from 'components/DoctorSocialBlock';
import DoctorServicesList from 'components/DoctorServicesList';
import CalendarForm from 'components/CalendarForm';


import arrow from 'assets/DoctorPage/DoctorsSinglePage/arrow.png';
import vector1 from 'assets/DoctorPage/DoctorsSinglePage/Vector1.png';
import LocationIcon from 'assets/DoctorPage/DoctorsSinglePage/locationIcon.png';

import 'react-calendar/dist/Calendar.css';
import 'components/Global/Calendar/calendar.css';
import './component.css';
import SuccessModalBody from 'components/Global/SuccessModalBody';


const BaseDomain = process.env.REACT_APP_BASE_DOMAIN;

const Doctor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { doctorSlug } = useParams();
  const { items } = useSelector(state => state.doctorsTimes);
  const { doctor, serviceId, bookmark } = useSelector(state => state.doctor.item);

  const [ errors, setErrors ] = useState({});
  const [ date, setDate ] = useState(new Date());
  const [ time, setTime ] = useState('');
  const [ bookmarked, setBookmarked ] = useState(null);
  const [ heightBtn, setHeightBtn ] = useState('Ավելին');
  const [ heightClass, setHeightClass ] = useState('mh180');
  const [ showTimeModal, setShowTimeModal ] = useState(false);
  const [ showFormModal, setShowFormModal ] = useState(false);
  const [ showSuccessModal, setShowSuccessModal ] = useState(false);
  const [ workplacesHeightClass, setWorkplacesHeightClass ] = useState(true);
  const [ filterTabs, setFilterTabs ] = useState(['Ծառայություններ', 'Բժշկի մասին',
     doctor && doctor.galleries &&  doctor.galleries.length && 'Ֆոտոդարան']);
  const [ activeFilter, setActiveFilter ] = useState(filterTabs[0]);

  const [ formData, setFormData ] = useState({
    date,
    time: '',
    email: '',
    phone: '',
    serviceId,
    doctorSlug,
    lastName: '',
    firstName: '',
    workplaceSlug: ''
  });

  const handleChangeForm = (type, data, e) => {
    if (type === 'date') {
      setFormData(prev => {
        return {
          ...prev,
          date: handleGetDate(data)
        }
      });
    } else if (type === 'time') {
      setFormData(prev => {
        return {
          ...prev,
          time: data
        }
      });
    } else {
      setFormData(prev => {
        return {
          ...prev,
          [e.target.name]: e.target.value
        }
      });
    }
  };

  const handleSetActive = (active) => {
    setActiveFilter(_ => active);
  };


  const renderWorkplaces = () => {
    if (doctor.workplaces && doctor.workplaces.length > 0) {
      return doctor.workplaces.map(workplace =>
        <div className='mt-2' key={workplace.id}>
          <img className='locationIcon float-left' src={LocationIcon} alt='location' />
          <p className='f-s-12 fw-500 text-center'>
            <span className='fw-bold mb8'>{workplace.workplace.titleArm}</span> <br />
            {workplace.addressArm}
          </p>
        </div>
      );
    }
  };

  const setOrRemoveWorkplaceClass = (param) => {
    setWorkplacesHeightClass(param);
  };

  const openForm = () => {
    setShowTimeModal(false);
    setShowFormModal(true);
  };

  const submit = () => {
    setDoctorCall(formData)
      .then(() => {
        setShowFormModal(false);
        setShowSuccessModal(true);
      })
      .catch(e => {
        setErrors(e.ShowErrors);
        console.log(e);
      });
  };

  const handleShowMoreClick = () => {
    if (heightClass === 'mh180') {
      setHeightClass('');
      setHeightBtn('Փակել');
    } else {
      setHeightClass('mh180');
      setHeightBtn('Ավելին');
    }
  }

  const setBookmarkedFunction = (bookmark) => {
    setBookmarked(bookmark);
    setDoctorBookmarked(doctorSlug, bookmark);
  }

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Doctor Page',
          text: 'Check out this doctor!',
          url: window.location.href,
        });
        console.log('Successfully shared');
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      console.warn('Web Share API not supported');
    }
  };

  const timeModalBody = <div className='myCalendar'>
    <CalendarForm
       onCancel={() => {
        setShowTimeModal(false);
      }}
      times={items}
      time={time}
      date={date}
      setDate={setDate}
      setTime={setTime}
      onSubmit={openForm}
      submitBtnTitle={`Հերթագրում`}
    />
  </div>;

  const onCancelForm = () => {
    setShowFormModal(false);
    setErrors({});
  }

  const formModalBody = <CreateCallForm
    onCancel={onCancelForm}
    onSubmit={submit}
    handleChangeForm={handleChangeForm}
    errors={errors}
    doctor={doctor}
    formData={formData}
  />

  useEffect(() => {
    getDoctor(dispatch, doctorSlug).then(() => {})
      .catch(e => {
        console.log(e);
      });
    return () => {
      dispatch(resetDoctorsTimes());
    }
  }, []);

  useEffect(() => {
    getTimes(dispatch, formData.doctorSlug, date);
    setFormData(prev => {
      return {
        ...prev,
        date: handleGetDate(date)
      }
    });
  }, [date]);

  useEffect(() => {
    setFormData(prev => {
      return {
        ...prev,
        time: time
      }
    });
  }, [time]);

  useEffect(() => {
    setFormData(prev => {
      return {
        ...prev,
        serviceId: serviceId
      }
    });
  }, [serviceId]);

  useEffect(() => {
    setBookmarked(bookmark);
  }, [bookmark]);

  useEffect(() => {
    if (Object.keys(doctor).length) {
      if (doctor.workplaces.length) {
        if (doctor.workplaces[0].workplace.slug) {
          setFormData(prev => {
            return {
              ...prev,
              workplaceSlug: doctor.workplaces[0].workplace.slug
            }
          });
        }
      }
    }
  }, [doctor]);

  return (
    <>
      {
        Object.keys(doctor).length > 0 &&
        <>
          <Row className='mt-100 mt-100'>
            <Col xs={2}>
              <img src={vector1} alt='' onClick={() => navigate(-1)} className='vector1' />
            </Col>
            <Col xs={8} className='person'>
              <div>
                {doctor.imagePath && <img src={BaseDomain + doctor.imagePath} alt={doctor.titleArm} className='rounded-circle img' />}
              </div>
              <div>
                <p className='personName'>{doctor.titleArm}</p>
              </div>
            </Col>
            <Col xs={2} className='iconsDiv'>
              <svg onClick={() => setShowTimeModal(true)} xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                <path d='M20.5 8H3.5M6 21H18C19.6569 21 21 19.6569 21 18V8C21 6.34315 19.6569 5 18 5H6C4.34315 5 3 6.34315 3 8V18C3 19.6569 4.34315 21 6 21Z' stroke='#218CC0' strokeWidth='1.8' strokeLinecap='round' />
                <path d='M7.5 5V2.75' stroke='#218CC0' strokeWidth='1.8' strokeLinecap='round' strokeLinejoin='round' />
                <path d='M16.5 5V2.75' stroke='#218CC0' strokeWidth='1.8' strokeLinecap='round' strokeLinejoin='round' />
                <path d='M8.25 17.5H6.75M8.25 14.5H6.75M8.25 11.5H6.75M12.75 17.5H11.25M12.75 14.5H11.25M12.75 11.5H11.25M17.25 17.5H15.75M17.25 14.5H15.75M17.25 11.5H15.75' stroke='#218CC0' strokeWidth='1.8' strokeLinecap='round' />
              </svg>
              {
                bookmarked ?
                  <svg onClick={() => setBookmarkedFunction(false)} xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 16 21' fill='none'>
                    <path d='M1.5 3C1.5 1.89543 2.39543 1 3.5 1H12.5C13.6046 1 14.5 1.89543 14.5 3V18.4535C14.5 19.3623 13.3856 19.7999 12.7672 19.1339L8.73279 14.7892C8.33716 14.3631 7.66284 14.3631 7.26721 14.7892L3.23279 19.1339C2.61435 19.7999 1.5 19.3623 1.5 18.4535V3Z' fill='#218CC0' stroke='#218CC0' strokeWidth='2' />
                  </svg> :
                  <svg onClick={() => setBookmarkedFunction(true)} xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                    <path d='M5.4 5C5.4 3.8402 6.3402 2.9 7.5 2.9H16.5C17.6598 2.9 18.6 3.8402 18.6 5V20.5482C18.6 21.0728 17.9741 21.3448 17.5906 20.9868L12.9552 16.6605C12.4174 16.1584 11.5826 16.1584 11.0448 16.6605L6.40939 20.9868C6.02586 21.3448 5.4 21.0728 5.4 20.5482V5Z' stroke='#218CC0' strokeWidth='1.8' />
                  </svg>
              }

              <svg onClick={() => handleShare()} xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                <path d='M14.151 6.62129L14.2972 6.53775L14.2748 6.37087C14.2584 6.24968 14.25 6.12588 14.25 6C14.25 4.48122 15.4812 3.25 17 3.25C18.5188 3.25 19.75 4.48122 19.75 6C19.75 7.51878 18.5188 8.75 17 8.75C16.2702 8.75 15.6073 8.46605 15.1148 8.00213L14.9801 7.8752L14.8194 7.96704L8.84896 11.3786L8.70276 11.4622L8.72522 11.6291C8.74155 11.7503 8.75 11.8741 8.75 12C8.75 12.1437 8.73899 12.2848 8.71782 12.4222L8.69441 12.5743L8.8196 12.6637L14.3223 16.5942L14.5368 16.7475L14.6785 16.5251C15.167 15.758 16.0243 15.25 17 15.25C18.5188 15.25 19.75 16.4812 19.75 18C19.75 19.5188 18.5188 20.75 17 20.75C15.6539 20.75 14.5332 19.7826 14.2962 18.505L14.2778 18.4058L14.1957 18.3472L8.14978 14.0287L7.98657 13.9121L7.83738 14.0461C7.35018 14.4839 6.70645 14.75 6 14.75C4.48122 14.75 3.25 13.5188 3.25 12C3.25 10.4812 4.48122 9.25 6 9.25C6.72978 9.25 7.3927 9.53394 7.88519 9.99786L8.01993 10.1248L8.18064 10.0329L14.151 6.62129ZM15.75 18.0039L15.75 18.0039L15.75 18.0106C15.7558 18.6961 16.3131 19.25 17 19.25C17.6904 19.25 18.25 18.6904 18.25 18C18.25 17.3096 17.6904 16.75 17 16.75C16.3129 16.75 15.7551 17.3045 15.75 17.9905L15.7499 17.9905L15.75 17.9971L15.75 18.0039ZM17 7.25C17.6904 7.25 18.25 6.69035 18.25 6C18.25 5.30965 17.6904 4.75 17 4.75C16.3096 4.75 15.75 5.30965 15.75 6C15.75 6.69035 16.3096 7.25 17 7.25ZM6 13.25C6.69035 13.25 7.25 12.6904 7.25 12C7.25 11.3096 6.69035 10.75 6 10.75C5.30965 10.75 4.75 11.3096 4.75 12C4.75 12.6904 5.30965 13.25 6 13.25Z' fill='#218CC0' stroke='#218CC0' strokeWidth='0.5' />
              </svg>
            </Col>

            <Col xs={10} className='mx-auto'>
              <div>
                <p className='text'>
                  {doctor.subTitleArm}
                </p>
              </div>
            </Col>
            <Col xs={12} className='mt16 workplacesDiv'>
              <Row>
                <Col xs={10} className={`${workplacesHeightClass ? 'addressesDiv' : ''}`}>
                  {renderWorkplaces()}
                </Col>
                {
                  doctor.workplaces.length > 1 && <Col xs={2}>
                    <img onClick={() => setOrRemoveWorkplaceClass(!workplacesHeightClass)} src={arrow} className='arrow' alt='arrow' />
                  </Col>
                }
              </Row>
            </Col>
            <Col xs={12} className='doctorMenuDiv mt-4'>
              {
                filterTabs && filterTabs.length > 0 && <FilterTabs
                  tabs={filterTabs}
                  checkActive={handleSetActive}
                />
              }

              {
                (activeFilter === 'Ծառայություններ') &&
                <>
                  <div className={`${heightClass}`}>
                    <DoctorServicesList services={doctor.services} />
                  </div>
                  <Link className={`more text-decoration-underline mt-3 mb-4 d-block`} onClick={() => handleShowMoreClick()}>{heightBtn}</Link>
                </>
              }
              {(activeFilter === 'Բժշկի մասին') && <DoctorInfo doctor={doctor} />}
              {(activeFilter === 'Ֆոտոդարան') && <DoctorGallery doctor={doctor} />}
            </Col>
            <DoctorsSocialBlock doctor={doctor} />
            <Col xs={6} className='text-center'>
              <Link to='/' className='f-s-14 text-decoration-underline text-blue lh40'>Տնային այց</Link>
            </Col>
            <Col xs={6} className='text-center'>
              <button className='btn btn-blue' onClick={() => setShowTimeModal(true)}>Հերթագրվել</button>
            </Col>
          </Row>
        </>
      }

      <MyModal
        centered
        show={showTimeModal}
        body={timeModalBody}
        onHide={() => setShowTimeModal(false)}
      />
      <MyModal
        centered
        show={showFormModal}
        body={formModalBody}
        onHide={() => setShowFormModal(false)}
      />
      <MyModal
        centered
        body={<SuccessModalBody/>}
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
      />
    </>
  );
}

export default Doctor;
