import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: localStorage.getItem('headerTitle') || ''
};

const headerTitleSlice = createSlice({
  name: 'historiesTemplate',
  initialState,
  reducers: {
    setHeaderTitle(state, action) {
      state.title = action.payload;
      localStorage.setItem('headerTitle', action.payload)
    },
    resetHeaderTitle(state) {
      state.title = '';
    }
  }
});

export const { setHeaderTitle, resetHeaderTitle } = headerTitleSlice.actions;
export default headerTitleSlice.reducer;
