import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: []
};

const patientLabServicesSlice = createSlice({
  name: 'patientLabServices',
  initialState,
  reducers: {
    setLabServices(state, action) {
      state.items = action.payload
    },
    resetPatientLabServices(state) {
      state.items = []
    }
  }
});

export const { setLabServices, resetPatientLabServices } = patientLabServicesSlice.actions;
export default patientLabServicesSlice.reducer;

