import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Carousel from 'react-multi-carousel-18';

import DoctorsInfo from 'components/DoctorsInfo';

import 'react-multi-carousel-18/lib/styles.css';

const Doctors = ({ doctors }) => {

  const renderDoctors = () => {
    return doctors && doctors.map(doctor => <DoctorsInfo key={doctor.id} doctor={doctor.doctor ? doctor.doctor : doctor} withMr={false} />)
  }



  return (
    <div className='category-horizontal'>
    
      
          {renderDoctors()}
       
    
    </div>
  );
}

export default Doctors;
