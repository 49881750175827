import React, { useState } from 'react';
import { Row, Col, Form, InputGroup } from 'react-bootstrap';

import SortRow from './../SortRow';
import FilterRow from './FilterRow';

import search from 'assets/Header/Ellipse.png';
import remove from 'assets/SearchBar/Remove.png';
import ArrowTop from 'assets/Filter/arrowTopBlack.png';
import ArrowBottom from 'assets/Filter/arrowBottomBlack.png';

const FilterList = ({
  title,
  list,
  slug,
  onSearchClick,
  onFilterChange,
  type = 'dinamic',
  withoutSearch = false
}) => {
  const [ show, setShow ] = useState(false);
  const [ searchString, setSearchString ] = useState('');

  const renderList = () => {
    if (list.length > 0) {
      if (type === 'static') {
        return list.map(one => <SortRow key={one.id} title={one} />);
      } else {
        return list.map(one => <FilterRow key={one.id} onFilterChange={onFilterChange} title={one.titleArm} mainSlug={slug} slug={one.slug} />)
      }

    }
  }

  const handleSearchClick = (name) => {
    if (searchString !== '') {
      onSearchClick(name, searchString);
    }
  }

  const handleRemoveClick = () => {
    setSearchString('');
    onSearchClick();
  }

  const handleSearchTyping = (value) => {
    setSearchString(value);
  }

  return (
    <>
      <Col xs={12} className='oneFilterBlock'>
        <p className='text-black fw-bold f-s-16 ' onClick={() => setShow(!show)}>
          {title} <span className='float-right'>
            {show ? <img src={ArrowTop} className='filterArrow' alt='arrow' /> : <img className='filterArrow' src={ArrowBottom} alt='arrow' />}
          </span>
        </p>
        {show &&
          <>
            <Row className='mt36'>
              {!withoutSearch && <>
                <Col xs={10} className={`pr0`}>
                  <div className='detailsBlock'>
                    <InputGroup className='searchInputGroup'>
                      <Form.Control name={slug} value={searchString} placeholder='Որոնել' className='border-none pl0' onChange={(e) => handleSearchTyping(e.target.value)} />
                      {searchString !== '' && <img className='removeIcon' src={remove} alt='remove' onClick={() => handleRemoveClick()} />}
                    </InputGroup>
                  </div>
                </Col>
                <Col xs={2}>
                  <img className='searchIcon mt0' src={search} alt='search' onClick={() => handleSearchClick(slug)} />
                </Col>
              </>}

              {renderList()}
            </Row>
          </>
        }

      </Col>
    </>

  );
}

export default FilterList; 
