import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  savedItems: [],
};

const diagnosisSlice = createSlice({
  name: 'diagnosis',
  initialState,
  reducers: {
    setDiagnosis(state, action) {
      state.items = action.payload
    },
    setSavedDiagnosis(state, action) {
      state.savedItems = action.payload
    },
    resetDiagnosis(state) {
      state.items = [];
      state.savedItems = []
    },
  }
});

export const { setDiagnosis, setSavedDiagnosis, resetDiagnosis } = diagnosisSlice.actions;
export default diagnosisSlice.reducer;
