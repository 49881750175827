
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getLabsOne } from 'services/api';

import { setHeaderTitle } from 'features/headerTitle';
import { resetLabCart } from 'features/labCart';

import WorkplaceHeaderInfo from 'components/WorkplaceHeaderInfo';
import LabMaterialsAndTypes from 'components/LabMaterialsAndTypes';

const OneLab = () => {

  const dispatch = useDispatch();

  const { workplaceSlug } = useParams();
  const { services } = useSelector(state => state.labCartServices);
  const [ workplace, setWorkplace ] = useState(null);

  const getWorkplace = () => {
    getLabsOne(workplaceSlug)
    .then(res => {
      setWorkplace(res);
      // if (res.workplaces.length > 0) {
      //     setWorkplaceAddress(res.workplaces[0]);
      // }
    })
    .catch(e => {
      console.log(e);
    });
  }

  useEffect(() => {
    if (!workplace) {
      getWorkplace();
    }
    return () => {
      setWorkplace(null);
    }
  }, [])

  useEffect(() => {
    dispatch(resetLabCart());
    dispatch(setHeaderTitle('Լաբորատորիա'));
  }, [])

  return (
    <>
      {
        workplace && <>
         <WorkplaceHeaderInfo workplace={workplace}/>
         <LabMaterialsAndTypes lab={workplace} /> 
          {
            services.length > 0 && <Row className='buttonsDiv h40'>
              <Col xs={6} className='text-center'>
                <button className='btn btn-blue' onClick={() => alert()}>
                  Հերթագրվել
                </button>
              </Col>
            </Row>
          }
        </>
      }
    </>
  );
}

export default OneLab;
