import React, {useState} from 'react';

import { Col, Row } from 'react-bootstrap';

import plusIcon from '../../assets/Lab/plusIcon.png';
import minusIcon from '../../assets/Lab/minusIcon.png';
import TitleWithBorder from 'components/Global/titleWithBorder';

const LabOneService = ({
    addReq,
    service, 
    onClick,
    lab = null,
    selectedServices,
    onlyDelete = false,
    showDoctors = false,
    withoutAction = false,
    showDateAndTime = false, 
    }) =>
{
    const [selected, setSelected] = useState(false);
    let a = [{day: 'numeric'}, {month: 'numeric'}, {year: 'numeric'}];

    const addOrRemoveService = (service) => {
        setSelected(!selected);
        onClick && onClick(service);
    }

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
                className='onelabService'
            >
                {service.doctor && showDoctors && service.serviceType === 'type' &&
                    <p className='f-s-14 mb-3'>{service.doctor.titleArm}</p>
                }

                {showDateAndTime && 
                    <Row>
                        <TitleWithBorder title={service.date} colClass='col-6' classes="col-pd-r-7 mb16"/>
                        <TitleWithBorder title={service.time} colClass='col-6' classes="col-pd-r-7 mb16 "/>
                    </Row>
                }
                 
                <>
                        <p
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between'
                            }}
                            className='f-s-12'
                        >
                            <span className='serviceTitle float-left w60 d-block'>
                            {
                                addReq ? service.service.titleArm : service.titleArm
                            }
                            </span>
                            <span className='float-right labPrice d-block'>
                            {lab ? service.maxAndMin.min + 'դր․'
                                : !addReq ? `${service.maxAndMin.min}-${service.maxAndMin.max}դր․` : ''
                            }

                            {addReq ? `${service.price}դր.` : ''}

                            {!withoutAction ? selectedServices && selectedServices.length > 0 && selectedServices.includes(service.id) ?
                                    <img className='plusIcon' src={minusIcon} alt="minusIcon"
                                            onClick={() => addOrRemoveService(service)}/>
                                    : 
                                    <img className='plusIcon' src={plusIcon} alt="plusIcon"
                                            onClick={() => addOrRemoveService(service)}/>
                                    : onlyDelete &&  
                                        <img className='plusIcon' src={minusIcon} alt="minusIcon"
                                        onClick={() => addOrRemoveService(service)}/>
                                    }
                            </span>
                        </p>
                </>
            </div>
        </>
    );
}

export default LabOneService;
