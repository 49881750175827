import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { handleGetDate } from 'utils';
import { getOneMedCard, getDepartments } from 'services/api';

import Button from 'components/Button';
import DoctorServicesList from 'components/DoctorServicesList/component';

import LocationIcon from 'assets/DoctorPage/DoctorsSinglePage/locationIcon.png';


import './component.css';

const MedCard = ({ 
    item,
    withAction,
    withAddService = false,
    withWorkplaceDetails = false,
    inOnePage = false
  }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const slug = useSelector(state => state.oneMedCard?.item?.workplaceDetails?.slug);

  const handleGetOnMedCard = () => {
    getOneMedCard(dispatch, item.code)
      .then(() => {
        navigate('/oneMedCard');
      })
      .catch(e => {
        console.log(e);
      });
  };

  const handleAddService = () => {
    console.log(slug);
    getDepartments(dispatch, slug)
    .then(() => {
      navigate('/cardServices')
    })
    .catch(e => {
      console.log(e);
    });
  }

  return (
    <div className='medical-card-block'>
      <div className='medical-card-info-block'>

      {!inOnePage && 
        <div  onClick={withAction ? handleGetOnMedCard : _ => _} className='medical-card-info-title-block'>
            <span className='body2-300-title medical-card-info-title-code'>
              { item.code }
            </span>
        
            <span className='body2-300-title medical-card-info-title-text'>
              {/* service name */} Պետ․ պատվեր
            </span>
        </div>
        }

        <h5 className='body2-300-title medical-card-info-pacient-name'>
          { item?.patient?.titleArm }
        </h5>

        <DoctorServicesList services={item?.doctorServices}/>

        {withAddService && <Button 
              text='Ավելացնել ծառայություն'
              click={handleAddService}
              classUI='caption1-200 mb-3'
          />
        }
        {withWorkplaceDetails && 
          <p>
              <img className='locationIcon float-left' src={LocationIcon} alt='location' />
              <span className='caption2-300'>{item.workplaceDetails.titleArm}</span>
          </p>
        }

        <div className='medical-card-info-date-block'>
          <p className='caption2-200 medical-card-info-date-text'>
            Բացման-փակման ամսաթիվ
          </p>
        </div>

        <div className='medical-card-info-status-block'>
          <div className='medical-card-info-status-item'>
            <span className='caption2-200 medical-card-info-status-date'>
              { handleGetDate(item?.created) }
            </span>
          </div>

          <div className='medical-card-info-status-item'>
            <span className={`caption2-200 ${item?.closedDate ? 'medical-card-info-status-date' : 'medical-card-info-status'}`}>
              {item?.closedDate ? handleGetDate(item.closedDate) : 'Ընթացիկ'}
            </span>
          </div>
        </div>

      </div>
    </div>
  )
};

MedCard.propTypes = {
  item: PropTypes.object,
  withAction: PropTypes.bool,
  withWorkplaceDetails: PropTypes.bool,
  inOnePage: PropTypes.bool
};

export default MedCard
