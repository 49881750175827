import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  getTimes,
  setDoctorCall,
  getDoctorServices,
  getDoctorProfileCalls,
  getDoctorProfilePatients
} from 'services/api';

import { handleGetDate } from 'utils';

import { resetDoctorsTimes } from 'features/doctorsTimes';
import { resetDoctorServices } from 'features/doctorServices';
import { resetDoctorPatients } from 'features/doctorPatients';

import Input from 'components/Input';
import Button from 'components/Button';
import MyModal from 'components/Global/MyModal';
import CalendarForm from 'components/CalendarForm';
import FilterSearch from 'components/FilterSearch';
import LabOneService from 'components/LabOneService';

import arrow from 'assets/SearchBar/Vector.png';
import PatientExample from "assets/patientExample.png";

import './component.css';

const AddRequest = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { item } = useSelector(state => state.userDetails);
  const { items } = useSelector(state => state.doctorServices);
  const doctorTimes = useSelector(state => state.doctorsTimes.items);
  const myPatients = useSelector(state => state.doctorPatients.items);

  const [ time, setTime ] = useState('');
  const [ step, setStep ] = useState('first');
  const [ date, setDate ] = useState(new Date());
  const [ patients, setPatients ] = useState([]);
  const [ patientId, setPatientId ] = useState(0);
  const [ serviceName, setServiceName ] = useState('');
  const [ patientName, setPatientName ] = useState('');
  const [ labServiceId, setLabServiceId ] = useState([]);
  const [ workPlaceSlug, setWorkPlaceSlug ] = useState('');
  const [ showTimeModal, setShowTimeModal ] = useState(false);
  const [ addForm, setAddForm ] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: ''
  });
  const [ errorForm, setErrorForm ] = useState({
    firstName: {
      error: false
    },
    lastName: {
      error: false
    },
    phone: {
      error: false
    },
    email: {
      error: false
    }
  });

  const handleSearch = (filterText) => {
    if (step === 'second') {
      setPatientName(_ => filterText);
    } else {
      setServiceName(_ => filterText);
    }
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setAddForm((prev) => {
      return {
        ...prev,
        [name]: value
      }
    });
    setErrorForm(() => {
      return {
        firstName: {
          error: false,
        },
        lastName: {
          error: false,
        },
        phone: {
          error: false,
        },
        email: {
          error: false,
        }
      }
    });
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleSetServiceId = (service) => {
    setLabServiceId((prev) => {
      if (prev.includes(service.id)) {
        return []
      }
      return [service.id]
    });
  };

  const handlePatientClick = (patient) => {
    handleCallCalendar();
    setAddForm(() => {
      return {
        firstName: patient.titleArm.split(' ')[0],
        lastName: patient.titleArm.split(' ')[1],
        phone: patient.phone ? patient.phone : patient.phone2 ,
        email: patient.email
      }
    })
  };

  const handleSetDate = (newDate) => {
    setDate(newDate);
  };

  const handleSetTime = (newTime) => {
    setTime(newTime);
  };

  const handleCallCalendar = () => {
    setShowTimeModal(true);
  };

  const handleCancelCalendar = () => {
    setTime(() => '');
    setDate(() => new Date());
    setShowTimeModal(false);
  };

  const handleSubmitCalendar = () => {
    setShowTimeModal(false);
    setStep(() => 'last');
  };

  const handleNextStep = (check) => {
    if (step === 'first') {
      if (check === 'skip') {
        setStep(() => 'second');
        setAddForm({
          firstName: '',
          lastName: '',
          phone: '',
          email: ''
        });
      } else {
        if (Object.values(addForm).every(item => item !== '')) {
          handleCallCalendar();
        } else {
          Object.entries(addForm).map(item => {
            if (item[1] === '') {
              setErrorForm(prev => {
                return {
                  ...prev,
                  ...prev[item[0]].error = true
                }
              })
            }
          })
        }
      }
    }
  };

  const handleBackStep = () => {
    if (step === 'second') {
      setStep(_ => 'first');
    } else {
      if (patientId) {
        setStep(_ => 'second');
      } else {
        setStep(_ => 'first');
      }
    }

    setDate(_ => '');
    setTime(_ => '');
    setAddForm({
      firstName: '',
      lastName: '',
      phone: ''
    });
    setPatientId(_ => 0);
    setPatientName(_ => '');
  };

  const handleSubmit = () => {
    const formData = {
      firstName: addForm.firstName,
      lastName: addForm.lastName,
      phone: addForm.phone,
      email: addForm.email,
      date: handleGetDate(date, true),
      time: time,
      doctorSlug: item.roles.includes('ROLE_DOCTOR') || item.roles.includes('ROLE_USER') ? item.slug : '',
      workplaceSlug: workPlaceSlug,
      serviceId: labServiceId[0]
    }

    setDoctorCall(formData, true)
    .then(() => {
      getDoctorProfileCalls(dispatch)
      .then(() => {
        navigate('/applications');
      })
      .catch(e => {
        console.log(e);
      });
    })
    .catch(e => {
      console.log(e)
    })
  };

  const timeModalBody = <div className='myCalendar'>
    <CalendarForm
      time={time}
      date={date}
      // freeBusyMode
      times={doctorTimes}
      setDate={handleSetDate}
      setTime={handleSetTime}
      submitBtnTitle={`Հերթագրվել`}
      onCancel={handleCancelCalendar}
      onSubmit={handleSubmitCalendar}
      // busyDays={['13/11/2024', '20/11/2024', '22/11/2024', '27/11/2024', '05/12/2024']}
    />
  </div>;

  useEffect(() => {
    if (item && (item.roles.includes('ROLE_DOCTOR') || item.roles.includes('ROLE_USER'))) {
      getDoctorServices(dispatch, item.slug)
      .then(res => {
        setWorkPlaceSlug(_ => res[0].workplace.slug);
      })
      .catch(e => console.log(e))
      getTimes(dispatch, item.slug);

      if (!myPatients.length) {
        getDoctorProfilePatients(dispatch)
          .then((res) => {
            const newPatients = [];
            res.map(item => {
              item.patients.map(innerItem => {
                newPatients.push(innerItem);
              })
            })
            setPatients(() => newPatients)
          })
          .catch(e => {
            console.log(e);
          });
      }
    }

    return () => {
      dispatch(resetDoctorsTimes());
      dispatch(resetDoctorServices());
      dispatch(resetDoctorPatients());
      setAddForm({
        firstName: '',
        lastName: '',
        phone: '',
        email: ''
      });
    }
  }, []);

  return (
    <div className='add-request-block'>
      <div className='add-request-search-block' onClick={step === 'first' ? () => handleNextStep('skip') : _ => _}>
        {
          step !== 'first' && <div className='back-arrow-block' onClick={handleBackStep}>
            <img className='back-arrow' src={arrow} alt='back arrow' />
          </div>
        }
        <FilterSearch
          filterCall={handleSearch}
          placeholder={step === 'first' ? 'Որոնել հիվանդին' : 'Որոնել'}
        />
      </div>

      {
        step === 'first' && <>
          <div>
            <h2 className='body2-400-title add-request-title'>Ստեղծել նորը</h2>
          </div>

          <div className='add-request-form-block'>
            <div className='add-request-form-item'>
              <Input
                name="firstName"
                value={addForm.firstName}
                change={handleChange}
                placeholder='Անուն'
                error={errorForm.firstName.error}
                errorText='Դաշտը պարտադիր է'
              />
            </div>
            
            <div className='add-request-form-item'>
              <Input
                name="lastName"
                value={addForm.lastName}
                change={handleChange}
                placeholder='Ազգանուն'
                error={errorForm.lastName.error}
                errorText='Դաշտը պարտադիր է'
              />
            </div>

            <div className='add-request-form-item'>
              <Input
                name="phone"
                value={addForm.phone}
                change={handleChange}
                placeholder='Հեռախոսահամար (+374) 77000000'
                error={errorForm.phone.error}
                errorText='Դաշտը պարտադիր է'
              />
            </div>

            <div className='add-request-form-item'>
              <Input
                name="email"
                value={addForm.email}
                change={handleChange}
                placeholder='Էլ. հասցե'
                error={errorForm.email.error}
                errorText='Դաշտը պարտադիր է'
              />
            </div>
          </div>
        </>
      }

      {
        step === 'second' && <div className='add-req-patients-block'>
          {
            patients && patients.length > 0 ?
              <div className='row' style={{ rowGap: '20px' }}>
                {
                  patientName === '' ? 
                  patients.map((item, index) => {
                    return <div className="col-6" onClick={() => handlePatientClick(item)} key={`${item.titleArm}_${index}`}>
                      <div className="doctorPatient">
                        <img className="doctorPatientImg" src={PatientExample} alt={item.titleArm} />
                        <p className="caption1-400">{item.titleArm}</p>
                        {item.ssn && <p className="caption2-300 text-green">Հծհ {item.ssn}</p>}
                      </div>
                    </div>
                  }) : patients.filter(item => item.titleArm.includes(patientName)).map((item, index) => {
                    return <div className="col-6" onClick={() => handlePatientClick(item)} key={`${item.titleArm}_${index}`}>
                      <div className="doctorPatient">
                        <img className="doctorPatientImg" src={PatientExample} alt={item.titleArm} />
                        <p className="caption1-400">{item.titleArm}</p>
                        {item.ssn && <p className="caption2-300 text-green">Հծհ {item.ssn}</p>}
                      </div>
                    </div>
                  })
                }
              </div>
            : ''
          } 
        </div>
      }

      {
        step === 'last' && <div className='add-req-services-block'>
          {
            items && items.length ?
              serviceName !== '' ?
                items.filter(item => item.service.titleArm.includes(serviceName)).map((item, index) => {
                  return <LabOneService
                    key={`${item.id}_${index}`}
                    addReq
                    service={item}
                    selectedServices={labServiceId}
                    onClick={handleSetServiceId}
                  />
                })
              : items.map((item, index) => {
                  return <LabOneService
                    key={`${item.id}_${index}`}
                    addReq
                    service={item}
                    selectedServices={labServiceId}
                    onClick={handleSetServiceId}
                  />
                })
            : ''
          }
        </div>
      }

      <div className='add-request-btn-place'>
        <div className='add-request-btn-place-center'>
          {
            (step === 'first' || step === 'last') && <>
              {
                step === 'first' && <div className='add-request-btn-place-item'>
                  <Button click={goBack} text='Չեղարկել' />
                </div>
              }
            
              <div className='add-request-btn-place-item'>
                <Button
                  bgColor
                  text={step === 'first' ? 'Հաջորդը' : 'Պահպանել'}
                  click={step === 'first' ? handleNextStep : handleSubmit}
                  disabled={step === 'last' ? !labServiceId[0] ? true : false : false}
                />
              </div>
            </>
          }
        </div>
      </div>

      <MyModal
        centered
        show={showTimeModal}
        body={timeModalBody}
        onHide={handleCancelCalendar}
      />
    </div>
  )
}

export default AddRequest
