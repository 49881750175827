import { axiosInstance, axiosLogin } from 'services/axios';

import { setCookie, getHeaders } from 'utils';

import { setMedCards } from 'features/medCards';
import { setOneMedCard } from 'features/oneMedCard';
import { setDoctor, resetDoctor } from 'features/doctor';
import { setBanners, resetBanners } from 'features/banner';
import { setHistoriesTemplate } from 'features/historiesTemplate';
import { setDiagnosis, setSavedDiagnosis } from 'features/diagnosis';
import { setWorkPlaces, resetWorkPlaces } from 'features/workPlaces';
import { setDocGallery, resetDocGallery } from 'features/docGallery';
import { setObjectiveIndicators } from 'features/objectiveIndicators';
import { setUserDetails, resetUserDetails } from 'features/userDetails';
import { setFromPatient, resetFromPatient } from 'features/fromPatient';
import { setDoctorAdviceTemplates } from 'features/doctorAdviceTemplates';
import { setApplications, resetApplications } from 'features/applications';
import { setDoctorsTimes, resetDoctorsTimes } from 'features/doctorsTimes';
import { setDoctorServices, resetDoctorServices } from 'features/doctorServices';
import { resetDoctorPatients, setDoctorPatients } from 'features/doctorPatients';
import { setDepartments, setDepartmentsPart, resetDepartments } from 'features/departments';

import {
  banners,

  complaints,

  // complaintsUploadExcel,
  // addCollaborator,
  // departmentsUploadExcel,
  // doctorsUploadExcel,
  // doctorsUploadDoctorsLabs,
  // doctorServicesUploadExcel,
  // doctorServicesUploadExcelAddServices,
  // illnessesUploadExcel,
  // labsUploadLabs,
  // labsUploadLabsWorkplaces,
  // labsUploadLabsDepartments,
  labsSetLabBookmarked,
  // labMaterialsOneMaterial,
  // labMaterialsUploadMaterials,
  // labMaterialsUploadTypes,
  // labMaterialsUploadServicesMaterials,
  // labMaterialsUploadServicesTypes,
  // professionsUploadExcel,
  // workplacesUploadExcel,
  workplacesSetWorkplaceBookmarked,
  // docJson,

  departments,
  departmentsBySearch,

  doctorCallCreate,
  doctors,
  doctorsOne,
  doctorsOneGallery,
  doctorsTimes,
  doctorsSetDoctorBookmarked,
  doctorsGetDoctorsForLabService,

  doctorProfileCalls,
  doctorPatients,
  doctorProfileApproveOrDecline,
  doctorProfileSetCalendar,
  doctorProfileDiagnosis,
  doctorProfilemedicalHistoryTemplates,
  doctorProfileTemplates,
  doctorProfileAddHistoryTemplate,
  doctorProfileObjectiveIndicatorForms,
  doctorProfileGetAdviceTemplates,
  doctorProfileAddAdviceTemplates,
  doctorProfileEditAdviceTemplates,
  doctorProfileRemoveAdviceTemplates,
  doctorProfileAddAdviceToMedicalCard,

  drugsDrugCategories,
  drugsChildrenByCategory,
  drugsFilterConnections,
  pharmsPharmsWithDrugs,

  illnesses,

  labCallCreate,
  // labCallPrices,
  labsWithCategories,
  labsByCategory,
  labsCategory,
  labsOne,
  labsWithCategoriesByCart,
  labMaterialsMaterialOrType,
  labMaterialsMaterialChildren,

  patientCalls,
  patientDiagnosis,
  patientMedicalHistories,
  patientIndicatorData,
  patientAdvices,

  doctorProfileOneMedicalCard,
  doctorProfileCreateMedicalCard,
  doctorProfileAddDiagnosis,
  doctorProfileRemoveDiagnosis,
  doctorProfileAddMedicalHistory,
  doctorProfileMedicalCards,
  doctorProfileAddIndicatorFormData,
  doctorProfileAddDoctorServiceToCard,
  doctorProfileAddLabServiceToCard,
  doctorProfileAddLabServiceToDoctor,
  doctorProfileRemoveLabServiceToDoctor,
  doctorProfileAddLabSchemaToDoctor,
  doctorProfileRemoveLabSchemaFromDoctor,
  doctorProfileDeleteLabServiceFromSchema,
  patientProfileMedicalCards,

  pharmsWithCategories,
  pharmsByCategory,
  pharmsOne,
  pharmsCategory,

  workplacesWithCategories,
  workplacesByCategory,
  workplacesOne,
  workplacesByDepartment,
  workplacesCategory,
  workplacesBySearch,
  workplacesWithDoctorsBySearch,

  usersGetDetails,
  labMaterialsMaterialsAndTypes,
  // doctorProfileDiagnosisTemplates,
  doctorProfileAddDiagnosisTemplates,
  doctorProfileAddLabServiceToSchema,
  doctorProfileDeleteLabServiceFromGroup,
  patientLabServices
} from 'services/constants';
import { setDepartment } from 'features/department';
import { setLabMaterialsAndTypes } from 'features/labMaterialsAndTypes';
import { setPatientDiagnosis } from 'features/patientDiagnosis';
import { setPatientMedicalHistories } from 'features/patientMedicalHistories';
import { setPatientIndicatorData } from 'features/patientIndicatorData';
import { setDoctorTemplates } from 'features/doctorTemplates';
import { setPatientAdvices } from 'features/patientAdvices';
import { setLabServices } from 'features/patientLabServices';

const baseUrl = process.env.REACT_APP_BASE_URL;

// GET requests ------>
export const getBanners = dispatch => {
  axiosInstance.get(banners)
  .then(response => {
    let data = response.data.data;
    if (data.success) {
      dispatch(setBanners(data.banners));
    }
  }).catch(e => {
    dispatch(resetBanners());
    console.log({
      Request: baseUrl + banners,
      Error: e
    });
  })
};


export const getWorkplace = async (dispatch, workplaceSlug) => {
  return await axiosInstance.get(workplacesOne + '/' + workplaceSlug)
  .then(response => {
    let data = response.data.data;
    if (data.success) {
      return Promise.resolve(data.workplace);
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + workplacesOne + '/' + workplaceSlug,
      Error: e
    })
  });
};

export const getWorkplaces = async (dispatch, departmentSlug) => {
  return await axiosInstance.get(`${workplacesByDepartment}/${departmentSlug}`)
  .then(response => {
    let data = response.data.data;

    dispatch(setWorkPlaces({
      data: data.workplaces,
      title: data.departmentTitle
    }));
  })
  .catch(e => {
    dispatch(resetWorkPlaces());
    return Promise.reject({
      Request: baseUrl + `${workplacesByDepartment}/${departmentSlug}`,
      Error: e
    })
  });
};

export const getWorkplacesCategory = async (categorySlug) => {
  return await axiosInstance.get(workplacesCategory + '/' + categorySlug)
  .then(response => {
    let data = response.data.data;
    if (data.success) {
      return Promise.resolve(data.category);
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + workplacesCategory + '/' + categorySlug,
      Error: e
    })
  });
};

export const getWorkplacesByCategory = async (categorySlug) => {
  return await axiosInstance.get(workplacesByCategory, { params: { categorySlug } })
  .then(response => {
    let data = response.data.data;
    if (data.success) {
      return Promise.resolve(data.workplaces)
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + workplacesByCategory,
      Error: e
    })
  });
}

export const getWorkplacesBySearch = async (searchString) => {
  return await axiosInstance.get(workplacesBySearch, {
    params: { searchString }
  })
  .then(response => {
    let data = response.data.data;
    if (data.success) {
      return Promise.resolve(data.workplaces);
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + workplacesBySearch,
      Error: e
    })
  });
};

export const getWorkplacesWithCategories = async () => {
  return await axiosInstance.get(workplacesWithCategories)
  .then((response) => {
    let data = response.data.data;
    if (data.success) {
      return Promise.resolve(data.categories)
    }
  })
  .catch(e => {
    return Promise.reject({
      Requset: baseUrl + workplacesWithCategories,
      Error: e
    })
  });
};

export const getWorkplacesWithDoctorsBySearch = async (searchString) => {
  return await axiosInstance.get(workplacesWithDoctorsBySearch, {
    params: { searchString }
  })
  .then(response => {
    let data = response.data.data;
    if (data.success) {
      return Promise.resolve(data.workplaces);
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + workplacesWithDoctorsBySearch,
      Error: e
    })
  });
};


export const getDoctor = async (dispatch, doctorSlug) => {
  return await axiosInstance.get(doctorsOne, {params: {doctorSlug}})
  .then(response => {
    const data = response.data.data;
    if (data.success) {
      dispatch(setDoctor({
        doctor: data.doctor,
        bookmark: data.doctor.bookmarked,
        serviceId: data.doctor.services[0].id
      }));
      return Promise.resolve(data.doctor);
    }
  })
  .catch(e => {
    dispatch(resetDoctor({}));
    return Promise.reject({
      Request: baseUrl + doctorsOne,
      Error: e
    });
  });
};

export const getDoctorServices = async (dispatch, doctorSlug) => {
  return await axiosInstance.get(doctorsOne, {params: {doctorSlug}})
  .then(response => {
    const data = response.data.data;
    if (data.success) {
      dispatch(setDoctorServices(data.doctor.services));
      return Promise.resolve(data.doctor.workplaces);
    }
  })
  .catch(e => {
    dispatch(resetDoctorServices());
    return Promise.reject({
      Request: baseUrl + doctorsOne,
      Error: e
    });
  });
};

export const getDoctors = async (dispatch, departmentSlug, workplaceSlug) => {
  return await axiosInstance.get(doctors,
  {
    params: {
      departmentSlug,
      workplaceSlug
    }
  })
  .then(response => {
    let data = response.data.data;
    dispatch(setDepartment(data.department));
    return Promise.resolve(data.doctors);
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + doctors,
      Error: e
    })
  });
};

export const getDoctorsForLabService = async (labWorkplaceId, labServiceId) => {
  return await axiosInstance.get(doctorsGetDoctorsForLabService, {
    params: {
      labWorkplaceId,
      labServiceId
    }
  })
  .then(response => {
    let { data } = response.data;
    if (data.success) {
      return Promise.resolve(data.doctors)
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + doctorsGetDoctorsForLabService,
      Error: e
    })
  });
};


export const getDoctorProfileCalls = async (dispatch, fromPatient = false) => {

  let url = doctorProfileCalls;

  if (fromPatient) {
    url = patientCalls;
  }

  return await axiosInstance.get(url, {
    headers: getHeaders()
  })
  .then(response => {
    if (response.status === 200) {
      dispatch(resetApplications());
      let statuses;
      if (fromPatient) {
        statuses = response.data?.data?.categories;
        dispatch(setFromPatient());
      } else {
        statuses = response.data?.data?.statuses;
        dispatch(resetFromPatient());
      }
      dispatch(setApplications(statuses));
      return Promise.resolve();
    }
  })
  .catch(e => {
    dispatch(resetApplications());
    return Promise.reject({
      Request: baseUrl + doctorProfileCalls,
      Error: e
    })
  });
};

export const getDoctorProfilePatients = async (dispatch) => {
  return await axiosInstance.get(doctorPatients, {
    headers: getHeaders()
  })
  .then(response => {
    const data = response.data.data;
    if (data.success) {
      dispatch(setDoctorPatients(data.patients));
      return Promise.resolve(data.patients);
    }
  })
  .catch(e => {
    dispatch(resetDoctorPatients());
    return Promise.reject({
      Request: baseUrl + doctorsOne,
      Error: e
    });
  });
};

export const getDoctorProfileDiagnosis = async (dispatch) => {
  return await axiosInstance.get(doctorProfileDiagnosis, {
    headers: getHeaders()
  })
  .then(response => {
    if (response.status === 200) {
      const data = response.data.data;
      dispatch(setDiagnosis(data.diagnosis));
      dispatch(setSavedDiagnosis(data.savedDiagnosis));
      return Promise.resolve();
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + doctorProfileDiagnosis,
      Error: e
    })
  })
};

export const getObjectiveIndicatorForms = async (dispatch) => {
  return await axiosInstance.get(doctorProfileObjectiveIndicatorForms, {
    headers: getHeaders()
  })
  .then(response => {
    if (response.status === 200) {
      const { forms } = response.data.data;
      dispatch(setObjectiveIndicators(forms));
      return Promise.resolve()
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + doctorProfileObjectiveIndicatorForms,
      Error: e
    })
  });
};

export const getDoctorTemplates = async (dispatch) => {
  return await axiosInstance.get(doctorProfileTemplates, {
    headers: getHeaders()
  })
  .then(response => {
    if (response.status === 200) {
      const { data } = response.data;
      dispatch(setDoctorTemplates(data));
      data.advices && data.advices.length && dispatch(setDoctorAdviceTemplates(data.advices));
      return Promise.resolve();
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + doctorProfileTemplates,
      Error: e
    })
  });
};

export const getDoctorAdviceTemplates = async (dispatch) => {
  return await axiosInstance.get(doctorProfileGetAdviceTemplates, {
    headers: getHeaders()
  })
  .then(response => {
    if (response.status === 200) {
      const { advices } = response.data.data;
      dispatch(setDoctorAdviceTemplates(advices));
      return Promise.resolve();
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + doctorProfileTemplates,
      Error: e
    })
  });
}


export const getMedicalHistoryTemplates = async (dispatch) => {
  return await axiosInstance.get(doctorProfilemedicalHistoryTemplates, {
    headers: getHeaders()
  })
  .then(response => {
    if (response.status === 200) {
      const { templates } = response.data.data;
      dispatch(setHistoriesTemplate(templates));
      return Promise.resolve();
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + doctorProfilemedicalHistoryTemplates,
      Error: e
    })
  });
};

export const getMedicalCards = async (dispatch, patientSlug = null) => {
  return await axiosInstance.get(doctorProfileMedicalCards, {
    headers: getHeaders(),
    params: {
      patientSlug: patientSlug
    }
  })
  .then(response => {
    if (response.status === 200) {
      const { medicalCards } = response.data.data;
      dispatch(setMedCards(medicalCards));
      return Promise.resolve();
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + doctorProfileMedicalCards,
      Error: e
    })
  });
};


export const getGallery = async (dispatch, doctorSlug, gallerySlug) => {
  return await axiosInstance.get(doctorsOneGallery, {
    params: {
      doctorSlug,
      gallerySlug,
    }
  }).then(response => {
    let data = response.data.data;
    if (data.success) {
      dispatch(setDocGallery(data.gallery))
      return Promise.resolve();
    }
  })
  .catch(e => {
    dispatch(resetDocGallery());
    return Promise.reject({
      Request: baseUrl + doctorsOneGallery,
      Error: e
    })
  });
};


export const getTimes = async (dispatch, doctorSlug, date = new Date()) => {
  const offset = date.getTimezoneOffset()
  let finalDate = new Date(date.getTime() - (offset*60*1000))
  return await axiosInstance.get(doctorsTimes, {
    params: {
      doctorSlug,
      date: finalDate.toISOString().split('T')[0]
    }
  })
  .then(response => {
    let data = response.data.data;
    if (data.success) {
      dispatch(setDoctorsTimes(data.times));
      return Promise.resolve();
    }
  })
  .catch(e => {
    dispatch(resetDoctorsTimes());
    return Promise.reject({
      Request: baseUrl + doctorsTimes,
      Error: e
    })
  });
};


export const getDepartments = async (dispatch, workplaceSlug = null) => {
  return await axiosInstance.get(departments, { params: { workplaceSlug } })
  .then(response => {
    let data = response.data.data;
    if (data.success) {
      dispatch(setDepartments(data.departments));
      dispatch(setDepartmentsPart(data.departments.slice(0, 6)));
      return Promise.resolve();
    }
  })
  .catch(e => {
    dispatch(resetDepartments());
    return Promise.reject({
      Request: baseUrl + departments,
      Error: e
    })
  });
};

export const getDepartmentsBySearch = async (complaintIds, illnessesIds) => {
  return await axiosInstance.get(departmentsBySearch, {
    params: { complaints: complaintIds, illnesses: illnessesIds }
  })
  .then(response => {
    let data = response.data.data;
    if (data.success) {
      return Promise.resolve(data.departments)
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + departmentsBySearch,
      Error: e
    })
  });
};


export const getLabsOne = async (slug) => {
  return await axiosInstance.get(labsOne, { params: { slug } })
  .then(response => {
    let data = response.data.data;
    if (data.success) {
      return Promise.resolve(data.workplace);
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + labsOne,
      Error: e
    })
  });
};

export const getCategory = async (categorySlug) => {
  return await axiosInstance.get(labsCategory, { params: { categorySlug } })
  .then(response => {
    let data = response.data.data;
    if (data.success) {
      return Promise.resolve(data.category)
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + labsCategory,
      Error: e
    })
  })
};

export const getLabsByCategory = async (categorySlug) => {
  return await axiosInstance.get(labsByCategory, { params: { categorySlug } })
  .then(response => {
    let data = response.data.data;
    if (data.success) {
      return Promise.resolve(data.workplaces);
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + labsByCategory,
      Error: e
    })
  });
};

export const getCategories = async () => {
  return await axiosInstance.get(labsWithCategories)
  .then((response) => {
    let data = response.data.data;
    if (data.success) {
      return Promise.resolve(data.categories)
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + labsWithCategories,
      Error: e
    })
  });
};

export const getLabsWithCategoriesByCart = async (ids) => {
  return await axiosInstance.get(labsWithCategoriesByCart, { params: { services: ids } })
  .then((response) => {
    let data = response.data.data;
    if (data.success) {
      return Promise.resolve(data.categories)
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + labsWithCategoriesByCart,
      Error: e
    })
  });
};


export const getMaterialsOrTypes = async (params) => {
  return await axiosInstance.get(labMaterialsMaterialOrType, { params })
  .then(response => {
    return Promise.resolve(response.data.data.materials)
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + labMaterialsMaterialOrType,
      Error: e
    })
  })
};

export const getMaterialsAndTypes = async (params, dispatch) => {
  return await axiosInstance.get(labMaterialsMaterialsAndTypes, { params })
  .then(response => {
    let data = response.data.data;
   
    dispatch(setLabMaterialsAndTypes(data.categories))
    return Promise.resolve()
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + labMaterialsMaterialsAndTypes,
      Error: e
    })
  })
};


export const getServices = async (params) => {
  return await axiosInstance.get(labMaterialsMaterialChildren, { params })
  .then(response => {
    return Promise.resolve(response.data.data.materials)
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + labMaterialsMaterialChildren,
      Error: e
    });
  });
};


export const getOneMedCard = async (dispatch, param) => {
  return await axiosInstance.get(doctorProfileOneMedicalCard + `?code=${param}`, {
    headers: getHeaders()
  })
  .then(response => {
    if (response.status === 200) {
      const { medicalCard } = response.data.data;
      dispatch(setOneMedCard(medicalCard));
      return Promise.resolve();
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + doctorProfileOneMedicalCard,
      Error: e
    });
  });
};


export const getPharm = async (slug) => {
  return await axiosInstance.get(pharmsOne, { params: { slug } })
  .then(response => {
    let data = response.data.data;
    if (data.success) {
      return Promise.resolve(data.pharm)
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + pharmsOne,
      Error: e
    });
  });
};

export const getPharmsByCategories = async () => {
  return await axiosInstance.get(pharmsWithCategories)
  .then((response) => {
    let data = response.data.data;
    if (data.success) {
      return Promise.resolve(data.categories);
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + pharmsWithCategories,
      Error: e
    });
  });
};

export const getPharmsCategory = async (categorySlug) => {
  return await axiosInstance.get(pharmsCategory, { params: { categorySlug } })
  .then(response => {
    let data = response.data.data;
    if (data.success) {
      return Promise.resolve(data.category)
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + pharmsCategory,
      Error: e
    })
  });
};

export const getPharmsByCategory = async (categorySlug) => {
  return await axiosInstance.get(pharmsByCategory, { params: { categorySlug } })
  .then(response => {
    let data = response.data.data;
    if (data.success) {
      return Promise.resolve(data.pharms);
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + pharmsByCategory,
      Error: e
    })
  });
};

export const getPharmsWithDrugs = async (slug) => {
  return await axiosInstance.get(pharmsPharmsWithDrugs, { params: { slug } })
  .then(response => {
    let data = response.data.data;
    return Promise.resolve({
      pharms: data.pharms,
      category: data.category,
    })
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + pharmsPharmsWithDrugs,
      Error: e
    })
  });
};


export const getDrugsDrugCategories = async () => {
  return await axiosInstance.get(drugsDrugCategories)
  .then(response => {
    let data = response.data.data;
    if (data.success) {
      return Promise.resolve(data.categories)
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + drugsDrugCategories,
      Error: e
    });
  });
};

export const getDrugsChildrenByCategory = async (params) => {
  return await axiosInstance.get(drugsChildrenByCategory, { params })
  .then(response => {
    if (response.data.data.children.length > 0) {
      return Promise.resolve({
        children: response.data.data.children
      });
    } else {
      return Promise.resolve({
        history: `/pharmsWithDrugs/category/${params.slug}`
      });
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + drugsChildrenByCategory,
      Error: e
    })
  })
};

export const getDrugsFilterConnetions = async (params = {}) => {
  return await axiosInstance.get(drugsFilterConnections, {
    params
  }).then(response => {
    let data = response.data.data;

    if (data.success) {
      return Promise.resolve(data.connections);
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + drugsFilterConnections,
      Error: e
    });
  });
};


export const getComplaintsReq = async (searchString) => {
  return await axiosInstance.get(complaints, {
    params: { searchString }
  })
  .then(response => {
    let data = response.data.data;
    if (data.success) {
      return Promise.resolve(data.complaints);
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + complaints,
      Error: e
    })
  });
};

export const getIllnessesReq = async (searchString) => {
  return await axiosInstance.get(illnesses, {
    params: { searchString }
  }).then(response => {
    let data = response.data.data;
    if (data.success) {
      return Promise.resolve(data.illnesses);
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + illnesses,
      Error: e
    })
  });
};

export const getPatientMedCards = async (dispatch, param) => {
  return await axiosInstance.get(patientProfileMedicalCards, {
    headers: getHeaders()
  })
  .then(response => {
    if (response.status === 200) {
      const { medicalCards } = response.data.data;
      dispatch(setMedCards(medicalCards));
      return Promise.resolve();
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + patientDiagnosis,
      Error: e
    });
  });
};

export const getPatientDiagnosis = async (dispatch, param) => {
  return await axiosInstance.get(patientDiagnosis + `?patientSlug=${param}`, {
    headers: getHeaders()
  })
  .then(response => {
    if (response.status === 200) {
      const { diagnosis } = response.data.data;
      dispatch(setPatientDiagnosis(diagnosis));
      return Promise.resolve();
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + patientDiagnosis,
      Error: e
    });
  });
};

export const getPatientMedicalHistories = async (dispatch, param) => {
  return await axiosInstance.get(patientMedicalHistories + `?patientSlug=${param}`, {
    headers: getHeaders()
  })
  .then(response => {
    if (response.status === 200) {
      const { histories } = response.data.data;
      dispatch(setPatientMedicalHistories(histories));
      return Promise.resolve();
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + patientDiagnosis,
      Error: e
    });
  });
};

export const getPatientIndicatorData = async (dispatch, param) => {
  return await axiosInstance.get(patientIndicatorData + `?patientSlug=${param}`, {
    headers: getHeaders()
  })
  .then(response => {
    if (response.status === 200) {
      const { indicators } = response.data.data;
      dispatch(setPatientIndicatorData(indicators));
      return Promise.resolve();
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + patientDiagnosis,
      Error: e
    });
  });
};

export const getPatientAdvices = async (dispatch, param) => {
  return await axiosInstance.get(patientAdvices + `?patientSlug=${param}`, {
    headers: getHeaders()
  })
  .then(response => {
    if (response.status === 200) {
      const { advices } = response.data.data;
      dispatch(setPatientAdvices(advices));
      return Promise.resolve();
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + patientDiagnosis,
      Error: e
    });
  });
};

export const getPatientLabServices = async (dispatch, param) => {
  return await axiosInstance.get(patientLabServices + `?patientSlug=${param}`, {
    headers: getHeaders()
  })
  .then(response => {
    if (response.status === 200) {
      const { labServiceGroups } = response.data.data;
      dispatch(setLabServices(labServiceGroups));
      return Promise.resolve();
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + patientLabServices,
      Error: e
    });
  });
};


export const getUsersDetails = async (dispatch) => {
  return await axiosInstance.get(usersGetDetails, {
    headers: getHeaders()
  })
  .then(response => {
    if (response.status === 200) {
      if (response.data) {
        localStorage.setItem('userDetails', JSON.stringify(response.data.data));
        dispatch(setUserDetails(JSON.parse(localStorage.getItem('userDetails'))));
        return Promise.resolve(response.data.data.roles);
      }
    }
  })
  .catch(e => {
    dispatch(resetUserDetails())
    return Promise.reject({
      Request: usersGetDetails,
      Error: e
    })
  });
};
// GET requests ------>


// POST requests ------>
export const login = async (data) => {
  const sendData = {
    ...data,
    'client_id': process.env.REACT_APP_CLIENT_ID,
    'grant_type': process.env.REACT_APP_GRANT_TYPE,
    'client_secret': process.env.REACT_APP_CLIENT_SECRET
  }
  return await axiosLogin.post('token', sendData)
    .then(response => {
      if (response.status === 200) {
        const {
          expires_in,
          token_type,
          access_token,
          refresh_token
        } = response.data;
        setCookie('token_type', token_type, expires_in);
        setCookie('access_token', access_token, expires_in);
        setCookie('refresh_token', refresh_token, expires_in);
        return Promise.resolve();
      }
    })
    .catch(e => {
      return Promise.reject({
        Request: process.env.REACT_APP_BASE_DOMAIN + 'token',
        Error: e
      })
    });
};


export const setDoctorBookmarked = (slug, bookmark) => {
  axiosInstance.post(doctorsSetDoctorBookmarked, { doctorSlug: slug, bookmarked: bookmark })
  .then(response => {
    // TODO: if needed, then must implement response and add catch block
  });
};


export const setDoctorCall = async (formData, fromDoctor = false) => {
  let headers = fromDoctor ? {headers: getHeaders()} : {};
  return await axiosInstance.post(doctorCallCreate, formData, headers)
    .then(response => {
      if (response.data.data.success) {
        return Promise.resolve();
      }
    }).catch(e => {
      let data = e.response.data;
      return Promise.reject({
        Request: baseUrl + doctorCallCreate,
        Error: e,
        ShowErrors: data.data.errors
      })
    });
};

export const approveOrDecline = async (params) => {
  return await axiosInstance.post(doctorProfileApproveOrDecline, params, {
    headers: getHeaders()
  })
  .then(response => {
    if (response.status === 200) {
      return Promise.resolve();
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + doctorProfileApproveOrDecline,
      Error: e,
    })
  });
};

export const setCalendar = async (params) => {
  return await axiosInstance.post(doctorProfileSetCalendar, params, {
    headers: getHeaders()
  })
  .then(response => {
    if (response.status === 200) {
      return Promise.resolve();
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + doctorProfileSetCalendar,
      Error: e,
    })
  })
};

export const createMedicalCard = async (params) => {
  return await axiosInstance.post(doctorProfileCreateMedicalCard, params, {
    headers: getHeaders()
  })
  .then(response => {
    if (response.status === 200) {
      const { medicalCard } = response.data.data;
      if (medicalCard && Object.keys(medicalCard).length > 0) {
        return Promise.resolve(medicalCard);
      }
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + doctorProfileCreateMedicalCard,
      Error: e,
    })
  })
};

export const addDiagnosis = async (dispatch, params) => {
  return await axiosInstance.post(doctorProfileAddDiagnosis, params, {
    headers: getHeaders()
  })
  .then(response => {
    if (response.status === 200) {
      const { medicalCard } = response.data.data;
      dispatch(setOneMedCard(medicalCard));
      return Promise.resolve();
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + doctorProfileAddDiagnosis,
      Error: e,
    })
  })
}

export const addMedicalHistory = async (dispatch, params) => {
  return await axiosInstance.post(doctorProfileAddMedicalHistory, params, {
    headers: getHeaders()
  })
  .then(response => {
    if (response.status === 200) {
      const { medicalCard } = response.data.data;
      dispatch(setOneMedCard(medicalCard));
      return Promise.resolve();
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + doctorProfileAddMedicalHistory,
      Error: e,
    })
  })
};

export const addHistoryTemplate = async (dispatch, params) => {
  return await axiosInstance.post(doctorProfileAddHistoryTemplate, params, {
    headers: getHeaders()
  })
  .then(response => {
    if (response.status === 200) {
      return Promise.resolve();
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + doctorProfileAddHistoryTemplate,
      Error: e,
    })
  });
}

export const addAdviceTemplate = async (dispatch, params) => {
  return await axiosInstance.post(doctorProfileAddAdviceTemplates, params, {
    headers: getHeaders()
  })
    .then(response => {
      if (response.status === 200) {
        return Promise.resolve();
      }
    })
    .catch(e => {
      return Promise.reject({
        Request: baseUrl + doctorProfileAddAdviceTemplates,
        Error: e,
      })
    });
}

export const editAdviceTemplate = async (dispatch, params) => {
  return await axiosInstance.put(doctorProfileEditAdviceTemplates, params, {
    headers: getHeaders()
  })
    .then(response => {
      if (response.status === 200) {
        return Promise.resolve();
      }
    })
    .catch(e => {
      return Promise.reject({
        Request: baseUrl + doctorProfileEditAdviceTemplates,
        Error: e,
      })
    });
}

export const removeAdviceTemplate = async (params) => {
  return await axiosInstance.delete(doctorProfileRemoveAdviceTemplates, { data: params, headers: getHeaders() })
    .then(response => {
      if (response.status === 200) {
        return Promise.resolve();
      }
    })
    .catch(e => {
      return Promise.reject({
        Request: baseUrl + doctorProfileRemoveAdviceTemplates,
        Error: e,
      })
    });
}

export const addAdviceToMedicalCard = async (params) => {
  return await axiosInstance.post(doctorProfileAddAdviceToMedicalCard, params, {
    headers: getHeaders()
  })
  .then(response => {
    if (response.status === 200) {
      return Promise.resolve();
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + doctorProfileAddAdviceToMedicalCard,
      Error: e,
    })
  })
}

export const addDiagnosisTemplates = async (dispatch, params) => {
  return await axiosInstance.post(doctorProfileAddDiagnosisTemplates, params, {
    headers: getHeaders()
  })
  .then(response => {
    if (response.status === 200) {
      return Promise.resolve();
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + doctorProfileAddHistoryTemplate,
      Error: e,
    })
  });
}



export const addIndicatorFormData = async (params) => {
  return await axiosInstance.post(doctorProfileAddIndicatorFormData, params, {
    headers: getHeaders()
  })
  .then(_ => {
    // console.log(response, 'response - - >')
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + doctorProfileAddIndicatorFormData,
      Error: e,
    })
  });
};

export const addDoctorServiceToCard = async (params) => {
  return await axiosInstance.post(doctorProfileAddDoctorServiceToCard, params, {
    headers: getHeaders()
  }).then(response => {
    if (response.status === 200) {
      return Promise.resolve();
    }
  }).catch(e => {
    return Promise.reject({
      Request: baseUrl + doctorProfileAddDoctorServiceToCard,
      Error: e,
    })
  });
};

export const addLabServiceToCard = async (params) => {
  return await axiosInstance.post(doctorProfileAddLabServiceToCard, params, {
    headers: getHeaders()
  }).then(response => {
    if (response.status === 200) {
      return Promise.resolve();
    }
  }).catch(e => {
    return Promise.reject({
      Request: baseUrl + doctorProfileAddLabServiceToCard,
      Error: e,
    })
  });
};

export const deleteLabServiceFromGroup = async (params) => {
  return await axiosInstance.post(doctorProfileDeleteLabServiceFromGroup, params, {
    headers: getHeaders()
  }).then(response => {
    if (response.status === 200) {
      return Promise.resolve();
    }
  }).catch(e => {
    return Promise.reject({
      Request: baseUrl + doctorProfileDeleteLabServiceFromGroup,
      Error: e,
    })
  });
};

export const addLabServiceToDoctorTemplate = async (params) => {
  return await axiosInstance.post(doctorProfileAddLabServiceToDoctor, params, {
    headers: getHeaders()
  }).then(response => {
    if (response.status === 200) {
      return Promise.resolve();
    }
  }).catch(e => {
    return Promise.reject({
      Request: baseUrl + doctorProfileAddLabServiceToDoctor,
      Error: e,
    })
  });
};

export const removeLabServiceFromDoctorTemplate = async (params) => {
  return await axiosInstance.post(doctorProfileRemoveLabServiceToDoctor, params, {
    headers: getHeaders()
  }).then(response => {
    if (response.status === 200) {
      return Promise.resolve();
    }
  }).catch(e => {
    return Promise.reject({
      Request: baseUrl + doctorProfileRemoveLabServiceToDoctor,
      Error: e,
    })
  });
};

export const addLabSchemaToDoctorTemplate = async (params) => {
  return await axiosInstance.post(doctorProfileAddLabSchemaToDoctor, params, {
    headers: getHeaders()
  }).then(response => {
    if (response.status === 200) {
      return Promise.resolve();
    }
  }).catch(e => {
    return Promise.reject({
      Request: baseUrl + doctorProfileAddLabSchemaToDoctor,
      Error: e,
    })
  });
};

export const addLabServiceToSchema = async (params) => {
  return await axiosInstance.post(doctorProfileAddLabServiceToSchema, params, {
    headers: getHeaders()
  }).then(response => {
    if (response.status === 200) {
      return Promise.resolve();
    }
  }).catch(e => {
    return Promise.reject({
      Request: baseUrl + doctorProfileAddLabServiceToSchema,
      Error: e,
    })
  });
};

export const removeLabSchemaFromDoctorTemplate = async (params) => {
  return await axiosInstance.post(doctorProfileRemoveLabSchemaFromDoctor, params, {
    headers: getHeaders()
  }).then(response => {
    if (response.status === 200) {
      return Promise.resolve();
    }
  }).catch(e => {
    return Promise.reject({
      Request: baseUrl + doctorProfileRemoveLabSchemaFromDoctor,
      Error: e,
    })
  });
};

export const removeLabServiceFromSchema = async (params) => {
  return await axiosInstance.post(doctorProfileDeleteLabServiceFromSchema, params, {
    headers: getHeaders()
  }).then(response => {
    if (response.status === 200) {
      return Promise.resolve();
    }
  }).catch(e => {
    return Promise.reject({
      Request: baseUrl + doctorProfileDeleteLabServiceFromSchema,
      Error: e,
    })
  });
}


export const setLabCallCreate = async (params) => {
  return await axiosInstance.post(labCallCreate, params)
  .then(response => {
    if (response.status === 200) {
      const { success } = response.data.data;
      if (success) {
        return Promise.resolve();
      }
    }
  })
  .catch(e => {
    let data = e.response.data;
    return Promise.reject({
      Request: baseUrl + labCallCreate,
      Error: e,
      ShowErrors: data.data.errors
    })
  });
};

export const setLabBookmarked = async (params) => {
  return await axiosInstance.post(labsSetLabBookmarked, params)
  .then(() => {
    return Promise.resolve();
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + labsSetLabBookmarked,
      Error: e
    })
  });
};

export const setWorkplaceBookmarked = async (params) => {
  return await axiosInstance.post(workplacesSetWorkplaceBookmarked, params)
  .then(response => {
    return Promise.resolve();
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + workplacesSetWorkplaceBookmarked,
      Error: e
    })
  });
}
// POST requests ------>


// DELETE requests ------>
export const removeDiagnose = async (params) => {
  return await axiosInstance.delete(doctorProfileRemoveDiagnosis, { data: params, headers: getHeaders() })
  .then(response => {
    if (response.status === 200) {
      return Promise.resolve()
    }
  })
  .catch(e => {
    return Promise.reject({
      Request: baseUrl + doctorProfileRemoveDiagnosis,
      Error: e
    })
  })
};
// DELETE requests ------>
