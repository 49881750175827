import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setHeaderTitle } from "features/headerTitle";

import FilterTabs from "components/FilterTabs";
import MedCardDiagnos from "components/MedCardDiagnos";
import FilterSearch from "components/FilterSearch";

const PatientDiagnosis = () => {
    const dispatch = useDispatch();
    const diagnosis = useSelector(state => state.patientDiagnosis.items);
    const [ innerDiagnosis, setInnerDiagnosis ] = useState([]);
    const [ filterDiagnosisTabs, setFilterDiagnosisTabs ] = useState([]);
    const [ filterItem, setFilterItem ] = useState('');

    const [ activeDiagnosisFilter, setActiveDiagnosisFilter ] = useState(
        diagnosis && diagnosis[0] ? diagnosis[0].typeTitle : ''
      );
     
    const handleSetDiagnosisActive = (active) => {
        setActiveDiagnosisFilter(_ => active);
    };  

    const handleFilter = (searchText) => {
      setFilterItem(_ => searchText);
    };

    const renderDiagnosis = () => {
      let finalDiagnosis = [...innerDiagnosis];

      if (filterItem !== '') {
        finalDiagnosis =  finalDiagnosis.filter(item => item.diagnose?.titleArm?.includes(filterItem) || item.diagnose?.code?.includes(filterItem))
      }

      return (
        finalDiagnosis.length > 0 ? <div className='pl-10'>
          {
            finalDiagnosis.map((item, index) => {
              return <MedCardDiagnos
                item={item}
                code={`code`}
                fullWidth
                key={`${item.id}_${index}`}
                type={diagnosis.filter(item => item.typeTitle === activeDiagnosisFilter)[0].type}
                withRemove={false}
                containerClasses="mt-16"
                withWorkplaceDetails={true}
              />
            })
          }
        </div> : filterItem !== ''  ? 'Արդյունքներ չեն գտնվել' : ''
      )
    }

    useEffect(() => {
        const tabs = diagnosis.map(item => item.typeTitle);
        setFilterDiagnosisTabs(_ => {
            return [
            ...tabs
            ]
        });
    }, [diagnosis])

    useEffect(() => {
        const newDiagnosis = diagnosis && diagnosis.length && diagnosis.filter(item => item.typeTitle === activeDiagnosisFilter);
        if (newDiagnosis && newDiagnosis[0] && newDiagnosis[0].diagnosis && newDiagnosis[0].diagnosis.length) {
          setInnerDiagnosis(_ => {
            return newDiagnosis[0].diagnosis
          });
        } else {
          setInnerDiagnosis(_ => {
            return []
          });
        }
      }, [diagnosis, activeDiagnosisFilter]);

    useEffect(() => {
        dispatch(setHeaderTitle('Ախտորոշում'));
    }, []);

    return (
        <div className="mt-100">
            <FilterSearch filterCall={handleFilter}/>

            {
                filterDiagnosisTabs && filterDiagnosisTabs.length && 
                <FilterTabs
                    tabs={filterDiagnosisTabs}
                    checkActive={handleSetDiagnosisActive}
                    active={activeDiagnosisFilter}
                />
            }

            {renderDiagnosis()}
        </div>
    );
}

export default PatientDiagnosis;
