import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { getTimes, setCalendar } from 'services/api';

import CalendarForm from 'components/CalendarForm';
import CreateService from 'components/CreateService';

import calendar from 'assets/DoctorPage/calendar.svg';

import './component.css';

const MyCalendar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector(state => state.userDetails.item);
  const doctorTimes = useSelector(state => state.doctorsTimes.items);

  const [time, setTime] = useState('');
  const [date, setDate] = useState(new Date());

  const handleSetDate = (newDate) => {
    setDate(newDate);
    getTimes(dispatch, userDetails.slug, new Date(newDate));
  };

  const handleSetTime = (newTime) => {
    setTime(newTime);
  };

  const handleCancelCalendar = () => {
    setTime(() => '');
    setDate(() => new Date());
  };

  const handleSubmitCalendar = (dateAndTimes) => {
    setCalendar(dateAndTimes)
    .then(() => {
      getTimes(dispatch, userDetails.slug, date);
    })
    .catch(e => {
      console.log(e)
    })
  };

  useEffect(() => {
    if (!userDetails.roles.includes('ROLE_USER') && !userDetails.roles.includes('ROLE_DOCTOR')) {
      navigate(-1);
    } else {
      getTimes(dispatch, userDetails.slug);
    }
  }, []);

  return (
    <div className='my-calendar-block'>
      <CreateService
        withoutNew
        icon={calendar}
        name='Իմ Օրացույցը'
      />

      <CalendarForm
        time={time}
        date={date}
        freeBusyMode
        times={doctorTimes}
        setDate={handleSetDate}
        setTime={handleSetTime}
        submitBtnTitle={`Հերթագրվել`}
        onCancel={handleCancelCalendar}
        onSubmit={handleSubmitCalendar}
        // busyDays={['13/11/2024', '20/11/2024', '22/11/2024', '27/11/2024', '05/12/2024']}
      />
    </div>
  )
}

export default MyCalendar
