import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { getWorkplace, getDepartments } from "services/api";

import WorkplaceHeaderInfo from "components/WorkplaceHeaderInfo";
import FilterTabs from "components/FilterTabs";
import NewDepartments from "components/NewDepartments";
import { setHeaderTitle } from "features/headerTitle";
import LabMaterialsAndTypes from "components/LabMaterialsAndTypes";


const OneWorkplace = () => {
    const dispatch = useDispatch();
    const { workplaceSlug } = useParams();
    const [ workplace, setWorkplace ] = useState(null);
    const [ filter, setFilter ] = useState(['Բաժանմունքներ', 'Տնային այց']);
    const [ activeFilter, setActiveFilter ] = useState('Բաժանմունքներ');

    const handleSetActive = (active) => {
      setActiveFilter(_ => active);
    };

    useEffect(() => {
      if (workplace) {
        dispatch(setHeaderTitle(workplace.category?.titleArm));

        if (workplace.lab) {
          setFilter(prev => [...prev, 'Լաբարատորիա']);
        }
      }
    }, [workplace])

    useEffect(() => {
        if (! workplace) {
          getWorkplace('', workplaceSlug)
          .then(res => {
            setWorkplace(res);
          })
          .catch(e => {
            console.log(e);
          });
        }

        getDepartments(dispatch, workplaceSlug)
        .catch(e => {
          console.log(e);
        });
      }, []);

    return (
      <>
      {workplace &&
        <>
          <WorkplaceHeaderInfo workplace={workplace}/>
          <FilterTabs 
            tabs={filter}
            active={activeFilter}
            checkActive={handleSetActive}
          />

          {
            activeFilter === 'Լաբարատորիա' && 
            <LabMaterialsAndTypes lab={workplace.lab} />
          }

          {
            activeFilter === 'Բաժանմունքներ' && 
            <NewDepartments 
              useAsLink 
              withDoctorsView
              link='/department'
              workplace={workplace}
             />
          }
         
        </>
      }
        
      </>
        
    );
}

export default OneWorkplace;
