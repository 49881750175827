import React from 'react';
import { Form } from 'react-bootstrap';

const FilterRow = ({
  slug,
  title,
  mainSlug,
  onFilterChange
}) => {
  return (
    <div className='filterRow mt16 col-12'>
      <Form.Check
        slug={slug}
        name={mainSlug}
        type={`checkbox`}
        id={`check-${slug}`}
        className='filterCheck'
        onChange={onFilterChange}
        label={
          <>
            <span htmlFor={`check-${slug}`} className='f-s-12'>{title}</span>
          </>
        }
      />
    </div>
  );
}

export default FilterRow;
