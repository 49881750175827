import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { addHistoryTemplate } from 'services/api';

import Button from 'components/Button';
import Textarea from 'components/Textarea';

import './component.css';

const AddHistoryTemplate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { item } = useSelector(state => state.historiesTemplateType);

  const [ text, setText] = useState('');

  const handleAddHistoryTemplate = () => {
    const params = {
      type: item,
      description: text
    };
    addHistoryTemplate(dispatch, params)
    .then(() => {
     navigate('/profile/MyTemplates');
    })
    .catch(e => {
      console.log(e);
    });
  };

  const handleAddClick = () => {
    handleAddHistoryTemplate();
  }

  const handleSetTextArea = (value) => {
    setText(_ => value);
  };

  return (
    <div className='mt-100 add-history-template-block'>
      <div className='histories-template-cards-parent-block'>
        <Textarea
          value={text}
          change={handleSetTextArea}
          placeholder='Մուտքագրեք տեքստը'
        />
      </div>
    

      <div className='advice-btn-block'>
        <Button
          click={handleAddClick}
          text='Ավելացնել'
          disabled={!text.length}
          bgColor
          className="histories-template-add-btn-block"
        />
      </div>
    </div>
  )
};

export default AddHistoryTemplate
