import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { removeDiagnose, getOneMedCard } from 'services/api';
import { handleGetDate } from 'utils';

import './component.css';

import LocationIcon from 'assets/DoctorPage/DoctorsSinglePage/locationIcon.png';

const MedCardDiagnos = ({ 
    item, 
    code, 
    type,
    fullWidth,
    withRemove = true,
    containerClasses = '',
    withWorkplaceDetails =  false
  }) => {
  const dispatch = useDispatch();

  const [ active, setActive ] = useState(false);

  const handleActive = () => {
    if (withRemove) {
       setActive(_ => !active);
    }
  };

  const handleRemoveDiagnose = (e) => {
    e.stopPropagation();
    const params = {
      medicalCardCode: code,
      type: type,
      diagnose: item.diagnose.id
    };
    removeDiagnose(params)
    .then(() => {
      getOneMedCard(dispatch, code)
    })
    .catch(e => {
      console.log(e);
    })
  };

  useEffect(() => {
    setActive(_ => false);
  }, [item]);

  return (
    <div
      onClick={handleActive}
      className={`med-card-diagnos-block ${containerClasses}`}
      style={{
        width: fullWidth ? '100%' : 'auto',
        backgroundColor: active ? '#218CC0' : '#fff'
      }}
    >
      {withRemove && 
        <div
          onClick={handleRemoveDiagnose}
          className='med-card-diagnos-close-block'
          style={{
            display: active ? 'flex' : 'none'
          }}
        >
          <span className='med-card-diagnos-close'>X</span>
        </div>
      }

      <p className='text-green caption2-300'>
        {handleGetDate(item.created)}
      </p>

      {withWorkplaceDetails && 
        <p>
            <img className='locationIcon float-left' src={LocationIcon} alt='location' />
            <span className='caption2-300'>{item.workplaceDetails.titleArm}</span>
        </p>
      }
     
     
      <p className='caption2-300 fw-bold'>
        {item.doctorDetails.titleArm}
      </p>
      <p
        className='caption2-200 med-card-diagnos-block-title'
        style={{
          color: active ? '#fff' : '#218CC0',
        }}
      >
         {item.doctorDetails.profession.titleArm}
      </p>
    
      <p
        className='caption2-300 fw-bold'
        style={{
          color: active ? '#fff' : '#152534',
        }}
      >
        { item.diagnose.code }
      </p>
      <p className='caption2-200'>
        { item.diagnose.titleArm }
      </p>
    </div>
  )
};

MedCardDiagnos.propTypes = {
  item: PropTypes.object,
  code: PropTypes.string,
  type: PropTypes.string,
  fullWidth: PropTypes.bool,
  withRemove: PropTypes.bool,
  containerClasses: PropTypes.string,
  withWorkplaceDetails: PropTypes.bool,
};

export default MedCardDiagnos
