import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import Carousel from 'react-multi-carousel-18';
import { useDispatch, useSelector } from 'react-redux';

import Item from './item';
import { getBanners } from 'services/api';

import 'react-multi-carousel-18/lib/styles.css';
import './component.css';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const Banner = ({containerClasses = ''}) => {
  const dispatch = useDispatch();
  const { items } = useSelector(state => state.banner);

  const renderBanners = () => {
    return items.map(banner => <Item key={banner.id} banner={banner} />);
  }

  useEffect(() => {
    if (!items.length) {
      getBanners(dispatch);
    }
  }, []);

  return (
    <Row>
      <Col className={`${containerClasses} mb-4`}>
        <Carousel
          arrows={false}
          showDots={true}
          responsive={responsive}
          renderDotsOutside={true}
          dotListClass={`bannerDots`}
        >
          {renderBanners()}
        </Carousel>
      </Col>
    </Row>
  );
}

Banner.propTypes = {
  containerClasses: PropTypes.string
};

export default Banner;
