import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getDoctorTemplates } from 'services/api'

import { setTempTemplate, resetTempTemplate } from 'features/tempTemplate';

import Button from 'components/Button';
import HistoriesTemplateCard from 'components/HistoriesTemplateCard';

import backArrow from 'assets/Diagnos/back-arrow.svg';

import './component.css';

const AdvicesTemplate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { items } = useSelector(state => state.doctorAdviceTemplates);

  const [template, setTemplate] = useState('');
  const [templates, setTemplates] = useState([]);
  const [activeCard, setActiveCard] = useState('');

  const handleSetNewTamplate = (temp) => {
    setTemplate(_ => temp);
    setActiveCard(_ => temp);
    dispatch(setTempTemplate(temp));
  };

  const handleBack = () => {
    dispatch(resetTempTemplate());
    navigate('/oneMedCard');
  }

  const handleGetTemplate = () => {
    navigate('/oneMedCard');
  };

  useEffect(() => {
    if (!items.length) {
      getDoctorTemplates(dispatch)
      .then()
      .catch(() => {
        navigate('/oneMedCard');
      })
    } else {
      if (items && items.length) {
        setTemplates(_ => {
          return [
            ...items
          ]
        });
      }
    }
  }, [items]);

  return (
    <div className='histories-template-block'>
      <div className='histories-template-cards-parent-block'>
        {
          templates && templates.length ? templates.map((item, index) => {
            return <HistoriesTemplateCard
              item={item}
              activeCard={activeCard}
              key={`${item.type}_${index}`}
              getTemplate={handleSetNewTamplate}
            />
          }) : <div className='histories-template-no-result-block'>
            <div
              onClick={handleBack}
              className='histories-template-no-result-back-block'
            >
              <img src={backArrow} alt='back arrow' />
            </div>
            <p className='histories-template-no-result'>Շաբլոններ չկան</p>
          </div>
        }
      </div>

      {
        templates && templates.length ? <div className='histories-template-add-btn-block'>
          <Button
            bgColor
            text='Ավելացնել'
            click={handleGetTemplate}
            disabled={template === '' ? true : false}
          />
        </div>
          : ''
      }
    </div>
  )
};

export default AdvicesTemplate
