import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import arrowUp from 'assets/Select/arrow-up.svg';
import arrowDown from 'assets/Select/arrow-down.svg';

import './component.css';

const Select = ({
  error,
  change,
  options,
  placeholder
}) => {
  const [ open, setOpen ] = useState(false);
  const [ innerError, setInnerError ] = useState('');
  const [ innerValue, setInnerValue ] = useState('');
  const [ innerOptions, setInnerOptions ] = useState([]);

  const handleChange = (val) => {
    setInnerValue(_ => val);
    change({
      target: {
        value: val
      }
    });
  };

  const handleOpen = () => {
    setOpen(_ => !open);
  };

  useEffect(() => {
    setInnerError(_ => error);
  }, [error]);

  useEffect(() => {
    setInnerOptions(_ => options);
  }, [options]);

  return (
    <div
      className='select-block'
      style={{
        paddingBottom: open ? '16px' : '0px',
        borderBottom: `0.5px solid ${innerError ? 'var(--colors-3-2)' : 'var(--colors-4-2)'}`
      }}
    >
      <div
        onClick={handleOpen}
        className='select-selected-block'
        style={{
          marginBottom: open ? '8px' : '0px'
        }}
      >
        <p className='select-selected-text'>
          { innerValue ? innerValue : placeholder }
        </p>

        <span className='select-selected-arrow'>
          <img src={ open ? arrowUp : arrowDown } alt='arrow' />
        </span>
      </div>

      <div
        className='select-options-block'
        style={{
          display: open ? 'flex' : 'none'
        }}
      >
        {
          innerOptions && innerOptions.length ? innerOptions.map((item, index) => {
            return <div
              key={`${item}_${index}`}
              className='select-options-item'
              onClick={_ => handleChange(item)}
            >
              <div
                className='select-options-radio'
                style={{
                  borderColor: innerValue === item ? '#152534' : '#888888'
                }}
              >
                <div
                  className='select-options-radio-center'
                  style={{
                    backgroundColor: innerValue === item ? '#152534' : 'transparent'
                  }}
                ></div>
              </div>
              {item}
            </div>
          }) : ''
        }
      </div>
    </div>
  )
};

Select.propTypes = {
  change: PropTypes.func,
  error: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string
};

export default Select
