import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  services: [],
  ids: []
};

const labCartServicesSlice = createSlice({
  name: 'labCartServices',
  initialState,
  reducers: {
    setLabCartServices(state, action) {
      state.services = [
        ...state.services,
        action.payload
      ];
      state.ids = [
        ...state.ids,
        action.payload.id
      ]
    },
    setLabCartOneService(state, action) {
      // TODO: remove loop
      const services = state.services;
      const changedServices = services.map((item) => {
        if (item.id === action.payload.id) {
          item = action.payload
        }
        return item
      });
      state.services = changedServices
    },
    resetLabCartServicesDateTime(state) {
      const services = state.services;
      const changedServices = services.map((item) => {
        if (item.date) {
          delete item.date
        }
        if (item.time) {
          delete item.time
        }
        if (item.doctors && item.doctors.length > 0) {
          item.doctors = [];
        }
        return item
      });
      state.services = changedServices
    },
    resetLabCartOneService(state, action) {
      state.services = action.payload;
    },
    resetLabCartIds(state, action) {
      state.ids = action.payload
    },
    resetLabCartServices(state) {
      state.services = [];
      state.ids = []
    }
  }
});

export const {
  setLabCartServices,
  setLabCartOneService,
  resetLabCartServices,
  resetLabCartOneService,
  resetLabCartIds,
  resetLabCartServicesDateTime
} = labCartServicesSlice.actions;
export default labCartServicesSlice.reducer;
