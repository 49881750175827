import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Dna } from "react-loader-spinner";

import { getWorkplaces } from "services/api";

import WorkplaceWithDoctors from "components/WorkplaceWithDoctors";
import { setHeaderTitle } from "features/headerTitle";


const OneDepartment = () => {
    const dispatch = useDispatch();
    const { departmentSlug } = useParams();
    const { items } = useSelector(state => state.workPlaces);
    const { title } = useSelector(state => state.workPlaces);
    const [ loader, setLoader ] = useState(false);
  
    const renderWorkplaces = () => {
      return items && items.length > 0 && items.map(workplace =>
        <WorkplaceWithDoctors
          key={workplace.id}
          workplace={workplace}
          departmentSlug={departmentSlug}
        />
      );
    }
  
    useEffect(() => {
      setLoader(true);
      getWorkplaces(dispatch, departmentSlug).then(() => {
        setLoader(false);
      })
      .catch(e => {
        setLoader(false); 
        console.log(e);
      });
    }, [departmentSlug ]);

    useEffect(() => {
      dispatch(setHeaderTitle(title))
    }, [title])
    

    return (
      <div className="mt-100">
        {
          loader && <Dna
            visible={true}
            height='80'
            width='80'
            ariaLabel='dna-loading'
            wrapperStyle={{}}
            wrapperClass='dna-wrapper'
          />
        }
        {renderWorkplaces()}
      </div>
    );
}

export default OneDepartment;