import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { handleGetDate } from 'utils';

import './component.css';

const Indicator = ({ item }) => {
  const [ fields, setFields ] = useState([]);
  const [ innerItem, setInnerItem ] = useState({});

  useEffect(() => {
    setInnerItem(_ => item);
    if (item && item.fieldsWithValues && item.fieldsWithValues.length) {
      setFields(_ => item.fieldsWithValues);
    }
  }, [item]);

  return (
    innerItem.id && innerItem.created && <div className='indicator-block'>
      <div className='indicator-desc-block'>
      <p className='caption2-200 indicator-date'>{ handleGetDate(item.created) }</p>

        {fields && fields.length && fields.map((fieldWithValue, index) => {
          let item = fieldWithValue.field;


          return (
            <p key={`${item.titleArm}_${index}_${item.slug}`} className='indicator-desc caption2-200'>
              <span className='indicator-desc-key'>{item.titleArm}</span>
              <span className='indicator-desc-value'>
                {fieldWithValue.value ? fieldWithValue.value : '-'}
                {' ' + item.measurementUnit ? item.measurementUnit : ''}
              </span>
            </p>
          )
        })}
          <p className='indicator-desc caption2-200'>
              <span className='indicator-desc-key'>{`Գանգատ`}</span>
              <span className='indicator-desc-value'>{innerItem.description ? innerItem.description : '-'}</span>
            </p>
      </div>
    </div>
  )
};

Indicator.propTypes = {
  item: PropTypes.object
};

export default Indicator
