import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getGallery } from 'services/api';

import GalleryItem from 'components/DoctorGallery/galleryItem';
import { setHeaderTitle } from 'features/headerTitle';

const DoctorOneGallery = () => {
  const dispatch = useDispatch();
  const gallery = useSelector(state => state.docGallery.item);
  const { doctorSlug, gallerySlug } = useParams();

  const renderItems = () => {
    return gallery && gallery.galleryItems && gallery.galleryItems.length > 0 && gallery.galleryItems.map((item) =>
      <GalleryItem
        item={item}
        key={item.id}
        gallery={gallery}
        withoutLink={true}
        doctorSlug={doctorSlug}
      />
    );
  };

  useEffect(() => {
    getGallery(dispatch, doctorSlug, gallerySlug)
      .then(() => { })
      .catch(e => console.log(e));
  }, []);

  useEffect(() => {
    dispatch(setHeaderTitle('Ֆոտոդարան'))
  }, [])

  return (
    <div className='mt-100'>
      <h2 className="title mb24">{gallery.titleArm}</h2>
      {renderItems()}
    </div>
  );
}

export default DoctorOneGallery;