import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import DiagnosListCard from 'components/DiagnosListCard';

import './component.css';

const NestedList = ({
  ids,
  data,
  handleGetDiagnosId
}) => {
  const [ innerData, setInnerData ] = useState([]);

  const handleNumberToRoman = (num) => {
    if (typeof num !== 'number') {
      return false;
    }
    let i = 3;
    let roman_num = '';
    let digits = String(+num).split('');
    let key = [
      '', 'C', 'CC', 'CCC', 'CD', 'D', 'DC', 'DCC', 'DCCC', 'CM',
      '', 'X', 'XX', 'XXX', 'XL', 'L', 'LX', 'LXX', 'LXXX', 'XC',
      '', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX'
    ];

    while (i--)
      roman_num = (key[+digits.pop() + (i * 10)] || '') + roman_num;

    return Array(+digits.join('') + 1).join('M') + roman_num;
  };

  useEffect(() => {
    setInnerData(_ => {
      return [
        ...data
      ]
    });
  }, [data]);

  return (
    <>
      {
        innerData.length && innerData.map((item, index) => {
          return <DiagnosListCard
            ids={ids}
            item={item}
            key={`${item.slug}_${index}`}
            checkCall={handleGetDiagnosId}
            number={handleNumberToRoman(index + 1)}
          />
        })
      }
    </>
  )
};

NestedList.propTypes = {
  ids: PropTypes.array,
  data: PropTypes.array,
  handleGetDiagnosId: PropTypes.func
};

export default NestedList
