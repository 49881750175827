import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  item: {}
};

const docGallerySlice = createSlice({
  name: 'docgallery',
  initialState,
  reducers: {
    setDocGallery(state, action) {
      state.item = action.payload;
    },
    resetDocGallery(state) {
      state.item = {};
    },
  }
});

export const { setDocGallery, resetDocGallery } = docGallerySlice.actions;
export default docGallerySlice.reducer;
