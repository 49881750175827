import React from 'react';

import GalleryItem from './galleryItem';

const DoctorGallery = ({ doctor }) => {
  const renderGallery = () => {
    if (doctor && doctor.galleries.length > 0) {
      return doctor.galleries.map(gallery =>
        <div key={gallery.id}>
          <h2 className="title mb24">{gallery.titleArm}</h2>
          {
            gallery.galleryItems.map((item, index) =>
              <div key={item.id}>
                {index === 0 ?
                  <GalleryItem doctorSlug={doctor.slug} gallery={gallery} item={item} /> : null
                }
              </div>
            )
          }
        </div>
      )
    }
  };

  return (
    <div>
      {renderGallery()}
    </div>
  );
};

export default DoctorGallery;
