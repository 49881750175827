
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import filterClear from 'assets/FilterSearch/filter-clear.svg';
import filterSearch from 'assets/FilterSearch/filter-search.svg';

import './component.css';

const FilterSearch = ({
  value,
  filterCall,
  placeholder = 'Որոնում'
}) => {
  const [ inpVal, setInpval ] = useState('');

  const handleChange = (e) => {
    const { value } = e.target;
    setInpval(_ => value);
    if (value === '') {
      filterCall('');
    }
  };

  const handleAction = (type) => {
    if (type === 'clear') {
      filterCall('');
      setInpval(_ => '');
    } else {
      filterCall(inpVal);
    }
  };

  const handleEnter = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13 || e.which === 13) {
      filterCall(inpVal);
    }
  };

  useEffect(() => {
    if (value !== '') {
      setInpval(_ => value);
    }
  }, [value]);

  return (
    <div className='filter-search-block'>
      <div
        className='filter-search-block-item'
        style={{
          width: `calc(100% - ${inpVal !== undefined && inpVal !== '' ? 80 : 40}px)`
        }}  
      >
        <input
          type='text'
          value={inpVal}
          placeholder={placeholder}
          onChange={handleChange}
          onKeyDown={handleEnter}
          className='caption1-200 filter-search-input'
        />
      </div>
      {(inpVal !== undefined && inpVal !== '') && <div
        className='filter-search-block-item'
        onClick={_ => handleAction('clear')}
      >
        <img src={filterClear} alt='clear' />
      </div>}
      <div
        className='filter-search-block-item'
        onClick={_ => handleAction('search')}
      >
        <img src={filterSearch} alt='search' />
      </div>
    </div>
  )
};

FilterSearch.propTypes = {
  filterCall: PropTypes.func.isRequired
};

export default FilterSearch
