import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types'
import Carousel from 'react-multi-carousel-18';
import {Col, Row } from 'react-bootstrap';

import { getLabsWithCategoriesByCart, getMaterialsAndTypes, getServices } from 'services/api';
import { getCarouselResponsive, handleGetDate } from 'utils';

import { setLabMaterial, setLabMaterialType, setFromMainPage } from 'features/labMaterial';
import { setLabCartServices, resetLabCartOneService, resetLabCartIds, resetLabCartServicesDateTime, resetLabCartServices, setLabCartOneService } from 'features/labCartServices';

import { resetDoctorsTimes } from 'features/doctorsTimes';
import { resetLabCart } from 'features/labCart';

import {
  getTimes,
  setLabCallCreate,
  getDoctorsForLabService
} from 'services/api';

import Button from 'components/Button';
import FilterTabs from 'components/FilterTabs';
import FilterCard from 'components/FilterCard';
import MyModal from 'components/Global/MyModal';
import DoctorsInfo from 'components/DoctorsInfo';
import CalendarForm from 'components/CalendarForm';
import LabOneService from 'components/LabOneService';
import CreateCallForm from 'components/CreateCallForm';
import LabCartServices from 'components/LabCartServices';
import AddressRadios from 'components/Global/AddressRadios';
import WorkplaceCategory from 'components/WorkplaceCategory';
import TitleWithBorder from 'components/Global/titleWithBorder';
import SuccessModalBody from 'components/Global/SuccessModalBody';

import PhoneIcon from '../../assets/Doctors_Social_Block/phone.png';
import LocationIcon from '../../assets/DoctorPage/DoctorsSinglePage/locationIcon.png';

import './component.css';

const responsive = getCarouselResponsive();
const BaseDomain = `${process.env.REACT_APP_BASE_DOMAIN}`;

const LabMaterialsAndTypes = ({
    link = null,
    lab = null,
    withSaveButton = false,
    onClickBtn
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const activeMaterial = useSelector(state => state.labMaterial.item);
    const activeMaterialType = useSelector(state => state.labMaterial.type);
    const fromMainPage = useSelector(state => state.labMaterial.fromMainPage);
    const { categories } = useSelector(state => state.labMaterialsAndTypes);
    const { services, ids } = useSelector(state => state.labCartServices);
    const { items } = useSelector(state => state.doctorsTimes);

    const [ type, setType ] = useState('material');
    const [ filterTabs, setFilterTabs ] = useState([]);
    const [ activeFilter, setActiveFilter ] = useState(activeMaterialType ? activeMaterialType : categories && categories.length && categories[0]['title']);
    
    const [ material, setMaterial ] = useState(activeMaterial);
    const [ materials2, setMaterials2 ] = useState([]);
    const [ material2, setMaterial2 ] = useState(null);
    const [ showSuccessModal, setShowSuccessModal ] = useState(false);
    const [ showDoctorsModal, setShowDoctorsModal ] = useState(false);
    const [ showAddressesModal, setShowAddressesModal ] = useState(false);
    const [ showDoctorTimeModal, setShowDoctorTimeModal ] = useState(false);
    const [ showFormModal, setShowFormModal ] = useState(false);
    const [ showChooseLab, setShowChooseLab ] = useState(false);
    const [ inProcessService, setInProcessService ] = useState({});
    const [ workplaceAddress, setWorkplaceAddress ] = useState({});
    const [ doctorTime, setDoctorTime ] = useState(null);
    const [ serviceIndex, setServiceIndex ] = useState(0);
    const [ doctorDate, setDoctorDate ] = useState(new Date());
    const [ errors, setErrors ] = useState({});
    const [ labCategories, setLabCategories ] = useState([]);
    const [ workplace, setWorkplace ] = useState(null);
    const [ formData, setFormData ] = useState({
      email: '',
      phone: '',
      lastName: '',
      firstName: '',
      labWorkplaceId: '',
    });

    const setServices = (material, type) => {
      if (material) {
          setMaterials2([]);
          let params = {materialSlug: material.slug,  type};
          if (lab) {
            params = {materialSlug: material.slug, labSlug: lab.slug, type}
          }
      
      getServices(params)
      .then(res => {
        setMaterials2(res);
      })
      .catch(e => {
        console.log(e);
      })
      setMaterial2(material);
      }
    }

    const onClickService = (service) => {
      if (ids && ids.includes(service.id)) {
          let finalServices = services.filter(item => item.id !== service.id);
          let finalIds = finalServices.map(item => item.id)

          dispatch(resetLabCartOneService(finalServices));
          dispatch(resetLabCartIds(finalIds));
      } else {
        dispatch(setLabCartServices(service))
      }
    };
  
    const renderChildren = () => {
      return material && material.children && material.children.length > 0 && material.children.map((one, index) => {
        let activeClass = (material2 && one.slug === material2.slug) ? 'activeTitle' : '';

        return <TitleWithBorder
            key={one.titleArm + '-' + index}
            colClass='col-6'
            title={one.titleArm}
            onclick={() => setServices(one, type)}
            classes={index % 2 === 0 ? 'col-pd-r-7 mb16 ' + activeClass : 'col-pd-l-7 mb16 ' + activeClass}
          />
      })
    };
  
    const renderMaterials = () => {
      return categories.length > 0 && categories.filter(category => category.title === activeFilter).map((category, index) => {
        const materials = category.materials.length > 0 && category.materials.map((oneMaterial, materialIndex) => {
          return  <FilterCard
              item={oneMaterial}
              key={`${oneMaterial.title}-${materialIndex}`}
              checkActive={handleMaterialClick}
              itemHasImage
              active={material && !link && oneMaterial.slug === material.slug}
            />
          });
        return materials;
      });
    };

    const handleMaterialClick = (oneMaterial) => {
      dispatch(setLabMaterial(oneMaterial));
      dispatch(setLabMaterialType(activeFilter))
      if (link) {
        dispatch(setFromMainPage(true))
        navigate(link);
      } else {
        dispatch(setFromMainPage(false))
        setMaterial(oneMaterial);
      }
    }
  
    const renderServices = () => {
      return materials2 && materials2.length > 0 && materials2.map((service, index) =>
        <Col sm={12} key={`${service.id}_${index}`} className='oneService'>
          <h3 className='f-s-14 fw-bold'>{service.titleArm}</h3>
          {
            service.services.map((child, innerIndex) => {
              return <LabOneService
                lab={lab}
                key={`${child.id}_${innerIndex}`}
                service={child}
                onClick={onClickService}
                selectedServices={ids}
              />
            }
          )}
        </Col>
      );
    }      
    
    const handleActiveFilter = (active) => {
        setActiveFilter(active);
    }

    const getCategories = () => {
      getLabsWithCategoriesByCart(ids)
      .then(res => {
        setLabCategories(res);
      })
      .catch(e => {
        console.log(e);
      });
    };

    const submit = async() => {
      setShowFormModal(false);
  
      const correctData = {
        ...formData,
        services: services.map((item) => {
          return {
            ...item,
            doctors: []
          }
        })
      };
      
      setLabCallCreate(correctData)
      .then(() => {
        dispatch(resetLabCartServices());
        dispatch(resetLabCartIds());
        setShowFormModal(false);
        setShowSuccessModal(true);
        navigate('/lab');
      })
      .catch(e => {
        setShowFormModal(true);
        // setErrors(e.ShowErrors)
        // console.log(e);
      });
    };
  
    const onSelectDoctorForService = (doctor) => {
        const newServices = {
          ...inProcessService,
          doctor: doctor,
          doctorSlug: doctor.slug
        };
  
        dispatch(setLabCartOneService(newServices));
        getTimes(dispatch, doctor.slug);
        setShowDoctorsModal(false);
        setShowDoctorTimeModal(true);
        setDoctorDate(new Date());
    };
  
    const renderServiceDoctors = () => {
      return  inProcessService && inProcessService.doctors && inProcessService.doctors.map((doctor, index) => {
          return <Col key={doctor.id} xs={6} className={index % 2 === 0 ? 'col-pd-r-7' : 'col-pd-l-7'}>
              <DoctorsInfo 
                doctor={doctor} 
                useAsLink={false}
                onClick={onSelectDoctorForService}
              />
          </Col>});
    };
  
    const onCancelForm = () => {
      dispatch(resetLabCartServicesDateTime());
      setShowFormModal(false);
      setWorkplaceAddress(null);
      setErrors([]);
    };
  
    const setServiceDateAndTime = (index) => {
      const newService = {
        ...inProcessService,
        date: handleGetDate(doctorDate),
        time: doctorTime
      };
  
      dispatch(setLabCartOneService(newService));
  
      if (services[index + 1] !== undefined) {
        setServiceIndex(() => index + 1)
        setDoctorTime(() => null);
        setDoctorDate(() => new Date());
        setInProcessService(() => {
          return {
            ...services[index + 1]
          }
        })
      } else {
        setShowDoctorTimeModal(false);
        setShowFormModal(true);
      }
    };
  
    const onCancelAddressModal = () => {
      setWorkplaceAddress(null);
      setShowAddressesModal(false);
      dispatch(resetLabCartServicesDateTime());
    };
  
    const onAddressConfirm = () => {
        setShowAddressesModal(false)
  
        let mustDo = false;
        let mustDoType = '';
  
        services.forEach(service => {
          if (service.serviceType === 'type' && !service.doctor) {
            mustDo = true;
            mustDoType = 'chooseDoctor';
            return;
          } else if (service.serviceType === 'material' && !service.date) {
            mustDo = true;
            mustDoType = 'chooseDate';
            return;
          }
        })
  
        if (!mustDo) {
            setShowFormModal(true);
        } else {
           if (mustDoType === 'chooseDoctor') {
            setShowDoctorsModal(true);
           } else if (mustDoType === 'chooseDate') {
            setShowDoctorTimeModal(true);
           }
        } 
    };
  
    const getDoctorsForService = (service) => {
      getDoctorsForLabService(workplaceAddress.id, service.id)
      .then(res => {
        const newServices = {
          ...service,
          doctors: res
        };
  
        dispatch(setLabCartOneService(newServices));
      })
      .catch(e => {
        console.log(e);
      });
    };

    const renderLabCategories = () => {
      return labCategories.length > 0 && labCategories.map((category, index) =>
          <div className='mt24' key={category.id}>
              <WorkplaceCategory
                category={category}
                link='labs'
                key={`${category.id}_${index}`}
                containerClasses='mb-3'
              />
              {category.workplaces.map(lab =>
                  <div className='chooselabDiv' key={lab.id}>
                      <div className='row' onClick={() => {
                          setWorkplace(lab)
                          setShowAddressesModal(true)
                      }}>
                          <Col xs={4} className='text-center'>
                              <img src={BaseDomain + '/' + lab.imagePath} alt={lab.titleArm}
                                    className='img-fluid'/>
                          </Col>
                          <Col xs={8}>
                              <h3 className='f-s-14 fw-bold'>{lab.titleArm}</h3>
                              <div className='mb-4'>
                                  <div className={``}>
                                      <p className='f-s-12 fw-500'>
                                          <img src={LocationIcon} className='locationIcon' alt='location'/>
                                          {lab.addressArm}
                                      </p>
                                      <p className='f-s-12 mt8'>
                                          <img src={PhoneIcon} className='addressPhoneIcon' alt='phone'/>
                                          {lab.phone}
                                      </p>
                                      <p className='text-green mt-3'>{lab.cartPrice} դր․</p>
                                  </div>
                              </div>
                          </Col>
                      </div>
                  </div>
              )}
          </div>
      )
  };

  const renderServicesTotal = () => {
    let totalMin = 0;

    if (services && services.length > 0) {
        services.forEach(service => {
            totalMin += service.maxAndMin.min;
        })
    }

    return totalMin;
  }

  
    const doctorsModalBody = <div className='row'>
      <p className='f-s-16 fw-bold mb22'>Ընտրել Բժիշկ 777</p>
      <p className='f-s-14 mb22 text-green fw-bold'>{inProcessService && inProcessService.titleArm}</p>
      {renderServiceDoctors()}
    </div>;
  
    const handleChangeForm = (_, __, e) => {
        setFormData(prev => {
          return {
            ...prev,
            [e.target.name]: e.target.value
          }
        });
    };
  
    const formModalBody = <>
    <div>
      <p className='caption1-400 mb-3'>{lab ? lab.titleArm : workplace && workplace.titleArm}</p>
      {
        services.length > 0 && services.map(service =>
          <LabOneService
            service={service}
            key={service.id}
            onClick={_ => _}
            lab={lab ? lab : workplace}
            showDateAndTime={true}
            selectedServices={ids}
            showDoctors
            withoutAction
          />
        )
      }
    </div>
    <div className="selectedServicesDiv">
        <p className='f-s-14 fw-bold'>
            <span>Ընդամենը</span>
            <span
                className="float-right labPrice d-block text-green">{renderServicesTotal()} դր․</span>
        </p>
    </div>
    <CreateCallForm
      errors={errors}
      onSubmit={submit}
      formData={formData}
      onCancel={onCancelForm}
      handleChangeForm={handleChangeForm}
    />
  </>;
  
    const successFormBody = <SuccessModalBody/>
  
    const doctorTimeModalBody = <>
      <p className='f-s-14 mb22 text-center text-green fw-bold'>{inProcessService && inProcessService.titleArm}</p>
      <CalendarForm
        onCancel={() => {
          setWorkplaceAddress(null);
          setShowDoctorTimeModal(false);
        }}
        times={items}
        time={doctorTime}
        date={doctorDate}
        setDate={setDoctorDate}
        setTime={setDoctorTime}
        serviceIndex={serviceIndex}
        onSubmit={setServiceDateAndTime}
      />
    </>;
  
    const addressesModalBody = <AddressRadios
      workplace={workplace ? workplace : lab}
      onSubmit={onAddressConfirm}
      onChange={setWorkplaceAddress}
      onCancel={onCancelAddressModal}
      workplaceAddress={workplaceAddress}
    />;
  
    useEffect(() => {
      if (workplaceAddress) {
        services.forEach(service => {
          if (service.serviceType === 'type' && (service.doctors === undefined || service.doctors.length === 0)) {
            getDoctorsForService(service);
          }
        })
      }
  
      setFormData(prev => {
        return {
          ...prev,
          labWorkplaceId: workplaceAddress?.id
        }
      });
    }, [workplaceAddress]);
  
    useEffect(() => {
      if (inProcessService?.type === 'material') {
        setShowDoctorTimeModal(true);
      } else if (inProcessService?.type === 'type') {
          setShowDoctorsModal(true);
      }
    }, [inProcessService]);
  
    useEffect(() => {
      if (services[0] !== undefined && serviceIndex === 0) {
        setInProcessService(() => {
          return {
            ...services[0]
          }
        })
      }
    }, [services, serviceIndex]);
  

    useEffect(() => {
      if (categories && categories.length > 0) {
        const tabs = categories.map(category => category.title);
        setFilterTabs(_ => tabs);
      }
    }, [categories]);

    useEffect(() => {
      if (!activeFilter) {
        setActiveFilter(activeMaterialType ? activeMaterialType : categories && categories.length && categories[0]['title'])
        dispatch(setLabMaterial(categories && categories.length && categories[0]['materials'][0]));
      }
    }, [categories, activeFilter]);

    useEffect(() => {
        if(material) {
          setMaterial2(material.children[0]);
        }
    }, [material])

    useEffect(() => {
      dispatch(setFromMainPage(false));
      if (activeFilter === 'Հետազոտվող նյութ') {
        setType('material')
      } else {
        setType('type');
      }
      
      if(!fromMainPage && categories && categories.length > 0) {
        categories.filter(category => category.title === activeFilter).map((category, index) => {
          setMaterial(category.materials[0]);
          setMaterial2(category.materials[0]['children'][0]);
        })
      }
    }, [activeFilter])

    useEffect(() => {
      setServices(material2, type)
    }, [material2, type]);

    useEffect(() => {
      if (showChooseLab) {
        getCategories();
        dispatch(resetLabCart());
      }
    }, [showChooseLab]);

    useEffect(() => {
      dispatch(resetLabCart());
      if (!categories.length) {
        let params = {};

        if (lab) {
          params = {labSlug: lab.slug}
        }
        
        getMaterialsAndTypes(params, dispatch)
        .catch(e => {
          console.log(e);
        });
      }
      return () => {
        dispatch(resetDoctorsTimes());
      }
    }, [])
     
    return (
        <>
            <LabCartServices onSubmit={() => {!lab ? setShowChooseLab(true) : setShowAddressesModal(true)}}/>

            {
              showChooseLab ? <>{renderLabCategories()}</> : 
              <Row className='mb32'>
                {filterTabs && filterTabs.length > 0 && 
                  <FilterTabs
                      tabs={filterTabs}
                      checkActive={handleActiveFilter}
                      active={activeFilter}
                  />
                } 
                <Col>
                    <Carousel
                        responsive={responsive}
                        showDots={false}
                        arrows={false}
                        className='searchCarousel'
                    >
                      {categories && categories.length > 0 && renderMaterials()}
                    </Carousel> 

                    {!link && 
                      <Row>
                        <Col xs={12}>
                          <h2 className='body1-300-title my-4'>
                              {activeFilter}
                          </h2>
                        </Col>
                        {renderChildren()}
                        {renderServices()}
                      </Row>
                    }

                    {withSaveButton && services.length > 0 && 
                      <Row>
                          <Col xs={10} className=''>
                           <Button
                              text='Ավելացնել'
                              bgColor
                              click={onClickBtn}
                              classUI='addMaterialBtn'
                            />
                        </Col>
                      </Row>
                      }
                </Col>
              </Row>
            }
            <MyModal
              centered
              show={showAddressesModal}
              body={addressesModalBody}
              onHide={() => setShowAddressesModal(false)}
            />
            <MyModal
              centered
              show={showFormModal}
              body={formModalBody}
              onHide={() => setShowFormModal(false)}
            />
            <MyModal
              centered
              show={showDoctorTimeModal}
              body={doctorTimeModalBody}
              onHide={() => setShowDoctorTimeModal(false)}
            />
            <MyModal
              centered
              show={showDoctorsModal}
              body={doctorsModalBody}
              onHide={() => setShowDoctorsModal(false)}
            />
            <MyModal
              centered
              show={showSuccessModal}
              body={successFormBody}
              onHide={() => setShowSuccessModal(false)}
            />
        </>
    );
}

LabMaterialsAndTypes.propTypes = {
  link: PropTypes.string,
  lab: PropTypes.object,
  withSaveButton: PropTypes.bool
};

export default LabMaterialsAndTypes;
